import React, {FC, useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {useHistory, useParams} from "react-router-dom";
import confirmEmail from "../../services/confirmEmail";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import {useAppDispatch, useAppSelector} from "../../hooks/redux-hooks";
import {setUserEmailStatus} from "../../store/auth-slice";
import ButtonPrimary from "../../shared/Button/ButtonPrimary";


export interface ConfirmEmailProps {
    className?: string;
}

const ConfirmEmail: FC<ConfirmEmailProps> = ({ className = "" }) => {
    const nav = useHistory();
    const userId:number = useAppSelector((state)=>state.auth.user.id);
    const authToken:string = useAppSelector((state)=>state.auth.token);
    const dispatch = useAppDispatch();
    const params:any = useParams();
    const token = params.token;

    const [reqLoading, setReqLoading] = useState(false);
    const [tokenExpired, setTokenExpired] = useState(false);
    const [tokenUsed, setTokenUsed] = useState(false);
    const [reqError, setReqError] = useState(false);
    const [confirmed, setConfirmed] = useState(false);

    useEffect(() => {
        (async () => {
            setReqLoading(true)
            let req = await confirmEmail.confirm(token, authToken);
            if (req.response === "success"){
                await setConfirmed(true);
                if (userId){
                    await dispatch(setUserEmailStatus("confirmed"))
                }
            }else if(req.response === "tokenExpired"){
                await setTokenExpired(true);
            }else if(req.response === "tokenUsed"){
                await setTokenUsed(true);
            }else {
                setReqError(true)
            }
            setReqLoading(false)
        })()

    }, []);

    const responseStyle = "my-10 text-3xl font-semibold text-neutral-900 dark:text-neutral-100"
    return (
        <div>
            <Helmet>
                <title>Login || Tattoohub</title>
            </Helmet>
            <div className={"flex justify-center text-center my-32 ml-10 mr-10"}>
                {confirmed ? <div className={responseStyle}>
                    <div>
                        Your email has been confirmed successfully
                    </div>
                    <div className={"mt-5"}>
                        <ButtonPrimary onClick={() => {nav.push("/dashboard")}}>Go to dashboard</ButtonPrimary>
                    </div>
                </div> : null}
                {tokenExpired ? <div className={responseStyle}>It seems that this email confirmation link has been expired. Log into your profile and request new confirm link.</div> : null}
                {tokenUsed ? <div className={responseStyle}>It seems that the email has already been used or email link is wrong.</div> : null}
                {reqError ? <div className={responseStyle}>An error occurred while processing your request, Please refresh and try again</div> : null}

            </div>

            {reqLoading ? <LoadingSpinner /> : null}

        </div>
    );
};

export default ConfirmEmail;
