import {User} from "../models/auth";
import {axiosReq} from "../store";

export async function updatePassword(oldPassword: string, newPassword: string, userId: number,  token:string) {
    try {
        //TODO: encrypt password
        await axiosReq.post('/api/updatePassword',{
            oldPassword: oldPassword,
            newPassword: newPassword,
            userId: userId
          }, {params: {token: token}});
          return true;
    }catch (e){
        console.log(e)
        return false;
    }
}

export async function updateUserProfile(name: string, email: string, userId: number, token:string) {
  let user: User = {
     emailAddress: "",
     id: -1,
     fullName: "Error",
     username: "error",
     emailStatus: "unconfirmed"
};
    try {
        let req = await axiosReq.post('/api/edit-user-profile',{
            email: email,
            name: name,
            userId: userId
          }, {params: {token: token}});

        if (req.data.response === "success"){
            user = req.data.user;
        }

        return {response: req.data.response, user: user};
    }catch (e){
        console.log(e)
        return {response: "error", user: user};
    }
}
