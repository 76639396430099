import React, {useEffect} from "react";
import {  useHistory  } from "react-router-dom";
import CommonLayout from "../PageAddListing1/CommonLayout";
import Input from "../../shared/Input/Input";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Textarea from "../../shared/Textarea/Textarea";
import Navigation from "../../components/Listing/NavBtn";
import { useAppDispatch,useAppSelector } from "../../hooks/redux-hooks";
import {setUsername, setBio, setStepCompleted} from '../../store/become-artist-slice'
import {becomeArtistStepsLength} from "../../contains/contants";
import {checkIfUsernameExist} from "../../services/becomeArtist";
import {toast} from "react-toastify";

const BecomeArtistListing1 = () => {
    const navigate = useHistory();
    const dispatch = useAppDispatch();
    const currentStep = 1;
    const isTattooArtist = useAppSelector((state)=>state.auth.isTattooArtist);
    const stepCompleted = useAppSelector((state)=>state.becomeArtist.stepCompleted);
    const token = useAppSelector((state)=>state.auth.token);

    useEffect(() => {
        if (isTattooArtist){
            navigate.push('/dashboard')
        }
    }, [])

    const initialValues = {
        username: useAppSelector((state)=>state.becomeArtist.username),
        bio: useAppSelector((state)=>state.becomeArtist.bio)
    }

    const validationSchema = Yup.object().shape({
        username: Yup.string().min(3, "Username must be at least 3 characters").required("Username is required!").test('isExist', '', async (val:any, testContext:any) => {

            if  (val && val.length > 2){
                let req:any = await checkIfUsernameExist(val, token);

                if (req.response === 'success'){
                    if (req.isExist){
                        return testContext.createError({message: 'Username is already in use'})
                    }else{
                        return true
                    }
                }else{
                    toast.error("Error. Please refresh and try again", {
                        position: toast.POSITION.TOP_CENTER
                    });
                    return false
                }
            }

        }),
        bio: Yup.string(),
    });

    const handleLogin = async (formValue: { username: string; bio: string;}) : Promise<void> => {
            await dispatch(setUsername(formValue.username));
            await dispatch(setBio(formValue.bio));

            if (stepCompleted < currentStep){
                await dispatch(setStepCompleted(currentStep))
            }
            navigate.push("/become-artist-listing-2")

    };

    return (
        <CommonLayout
            index={"0" + currentStep}
            steps={'0' + becomeArtistStepsLength}
        >
            <>
                <h2 className="text-2xl font-semibold">Artist Information</h2>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

                {/* FORM */}
                <div className="space-y-8">
                    <Formik
                        validateOnChange={false}
                        validateOnBlur={false}
                        validateOnMount={false}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleLogin}
                    >
                        {({ values, setFieldValue, handleChange, errors, touched }) => (
                            <Form className="grid grid-cols-1 gap-6">
                                <label className="block">
                                  <span className="text-neutral-800 dark:text-neutral-200">Username</span>
                                    <Input
                                        type="text"
                                        name="username"
                                        value={values.username}
                                        onChange={handleChange}
                                        placeholder="Username"
                                        className="mt-1"
                                    />
                                    <ErrorMessage name="username" component="div" className="text-red-500 text-xs italic"/>
                                </label>
                                <label className="block">
                                  <span className="text-neutral-800 dark:text-neutral-200">Bio</span>
                                    <Textarea
                                        rows={8}
                                        name="bio"
                                        value={values.bio}
                                        onChange={handleChange}
                                        placeholder="Bio"
                                        className="mt-1"
                                    />
                                    <ErrorMessage name="Bio" component="div" className="text-red-500 text-xs italic"/>
                                </label>
                                <Navigation  prev={'/become-artist-listing-1'} />

                            </Form>

                        )}
                    </Formik>

                </div>
            </>
        </CommonLayout>
    );
};

export default BecomeArtistListing1;
