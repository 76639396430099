import ButtonSecondary from "../../shared/Button/ButtonSecondary";
import ButtonPrimary from "../../shared/Button/ButtonPrimary";
import React, {FC} from "react";

export interface NavBtn {
    nextBtnText?: string,
    prev: string,
}

const Navigation: FC<NavBtn> = ({ prev, nextBtnText}) => {
    return (
        <div className="flex justify-end space-x-5">
            <ButtonSecondary href={prev}>Go back</ButtonSecondary>
            <ButtonPrimary type="submit" >
                {nextBtnText || "Continue"}
            </ButtonPrimary>

        </div>
    );
};

export default Navigation;
