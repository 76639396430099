import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import "../../styles/inviteScreens.css"
import Logo from "shared/Logo/Logo";
import ButtonPrimary from "../../shared/Button/ButtonPrimary";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import {useAppDispatch, useAppSelector} from "../../hooks/redux-hooks";
import {acceptInvitation, getInviteStudioData} from "../../store/invite-actions";
const AcceptInvite = () => {
    const navigate = useHistory();
    const params:any = useParams();
    const username = params.username;
    const token = params.token;
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch();
    const userId = useAppSelector((state)=>state.auth.user.id);
    const studioName = useAppSelector((state)=>state.invite.studioName) || "Studio";
    const studioStaffExist = useAppSelector((state)=>state.invite.studioStaffExist);
    const anotherStudioProfile = useAppSelector((state)=>state.invite.anotherStudioProfile);

    if (!userId){
        navigate.push("/invites-login")
    }

    useEffect(() => {
        (async function(){
            await setLoading(true);
            await dispatch(getInviteStudioData(username, token, userId))
            await setLoading(false);
        })()

    }, []);

    const invitation = useAppSelector((state)=>state.invite.invitation);


    const containerContent = (invitationUserId: number, studioStaffExist: boolean, anotherStudioProfile: boolean) => {
        if(anotherStudioProfile){
            return (
                <div>
                    <h1 className={"titleStyle"}>You have already been added to another studio, so you can`t accept this invitation</h1>
                </div>
            )
        }else if (studioStaffExist){
            return (
                <div>
                    <h1 className={"titleStyle"}>You have already been added to this studio</h1>
                </div>
            )
        }else{
            if (!invitationUserId){
                return (
                    <div>
                        <div className={"alignContentMiddleHorizontal"}>
                            <h1 className={"titleStyle"}>{studioName} invites you to join</h1>
                        </div>
                        <div className={"alignContentMiddleHorizontal"}>
                            <ButtonPrimary onClick={async () => {
                                await setLoading(true);
                                await dispatch(acceptInvitation(userId, token, navigate));
                                await setLoading(false)
                            }} type="submit">Accept Invite</ButtonPrimary>
                        </div>
                    </div>
                )
            }else{
                return (
                    <div className={"alignContentMiddleHorizontal"}>
                        <h2 className={"titleStyle"}>This link has already been used!</h2>
                    </div>
                )
            }
        }
    }

    return (
        <div className={"alignContentMiddleHorizontal"}>
           {loading ? <LoadingSpinner /> : null }
            <div className={"half-width containerInvites"}>
                <div className={"alignContentMiddleHorizontal"}>
                    <Logo maxSize={"max-h-16"} />
                 </div>
                <div className={"alignContentMiddleHorizontal"}>
                    {containerContent(invitation.userId!, studioStaffExist, anotherStudioProfile)}
                </div>
            </div>
        </div>

    );
};

export default AcceptInvite;
