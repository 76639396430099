import {AuthModel, User, Artist} from "../models/auth";
import { createSlice,PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    user: {},
    artist: {},
    token: "",
    isLoggedIn: false,
    isTattooArtist: false
  } as AuthModel

export const authSlice=createSlice({
    name:'auth',
    initialState,
    reducers:{
        setUser: (state,action:PayloadAction<User>) =>{
            state.user=action.payload;
        },
        setToken: (state,action:PayloadAction<string>) =>{
            state.token=action.payload;
        },
        setIsLoggedIn: (state,action:PayloadAction<boolean>) =>{
            state.isLoggedIn=action.payload;
        },
        setIsTattooArtist: (state,action:PayloadAction<boolean>) =>{
            state.isTattooArtist=action.payload;
        },
        updateUser: (state,action:PayloadAction<User>) =>{
            state.user=action.payload;
        },
        setArtist: (state,action:PayloadAction<Artist>) =>{
            state.artist=action.payload;
        },
        setArtistAvatar: (state,action:PayloadAction<string>) =>{
            state.artist.avatarUrl = action.payload;
        },
        setArtistBio: (state,action:PayloadAction<string>) =>{
            state.artist.bio = action.payload;
        },
        setArtistUsername: (state,action:PayloadAction<string>) =>{
            state.artist.username = action.payload;
        },
        setArtistPortfolio: (state,action:PayloadAction<string[]>) =>{
            state.artist.portfolio = [];
            state.artist.portfolio = action.payload;
        },
        setUserEmailStatus: (state,action:PayloadAction<string>) =>{
            state.user.emailStatus = action.payload;
        },
        logoutUser: (state,action:PayloadAction<boolean>) =>{
            //state.isLoggedIn=action.payload;
            state.isLoggedIn = initialState.isLoggedIn
            state.user = initialState.user
            state.artist = initialState.artist
            state.token = initialState.token;
            state.isTattooArtist = initialState.isTattooArtist
        }
    }
})
export const { setUser, setToken, setIsLoggedIn, logoutUser, setIsTattooArtist, setArtist, setArtistAvatar, setArtistPortfolio, setUserEmailStatus, setArtistUsername, setArtistBio} = authSlice.actions
export default authSlice.reducer;
