import {axiosReq} from "../store";

export async function fetchStudioPageDetails(username:string, nav:any) {

    let images;
    let studioRating;
    let reviews;
    let businessInfo;
    let owner;
    let services;
    let studioStaff;
    let customersLength;
    let styles;
    let bookingHours;

    try {
     let req = await axiosReq.get('/api/get-studio-profile-page?username=' + username);

     if (req.data.response === 'success'){
         images = req.data.images
         studioRating = req.data.studioRating;
         reviews = req.data.reviews;
         businessInfo = req.data.businessInfo;
         owner = req.data.owner;
         services = req.data.services;
         studioStaff = req.data.studioStaff;
         customersLength = req.data.customersLength;
         styles = req.data.styles;
         bookingHours = req.data.bookingHours
     }else if(req.data.response === 'noStudio'){
         nav.push('/page404')
     }


    }catch (e){
        console.log(e)
    }

    return {images, studioRating, reviews, businessInfo, owner, services, studioStaff, customersLength, styles, bookingHours};
}
