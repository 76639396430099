import React, { useState, useEffect } from "react";
import CommonLayout from "./CommonLayout";
import AccountStudiosCard from "../../components/Studios/AccountStudiosCard";
import {  useHistory  } from "react-router-dom";
import { useAppSelector } from "../../hooks/redux-hooks";

const AccountStudios = () => {
  const [studios] = useState([])
  const navigate = useHistory();
  const isLoggedIn = useAppSelector((state)=>state.auth.isLoggedIn);

  useEffect(() => {
    if(isLoggedIn === false){
      navigate.push("/login")
    }
    // setStudios(getCurrentUserStudios)
   }, [])

  const renderSection1 = () => {
    return (
      <div className="space-y-6 sm:space-y-8">
        <div>
          <h2 className="text-3xl font-semibold">Studios</h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div>
        <AccountStudiosCard studios={studios} />
        </div>
      </div>
    );
  };

  return (
    <div>
      <CommonLayout>{renderSection1()}</CommonLayout>
    </div>
  );
};

export default AccountStudios;
