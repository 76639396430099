import {Invitation, Invite} from "../models/invite";
import { createSlice,PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    invitation: {},
    studioName: "",
    studioUsername: "",
    studioStaffExist: false,
    anotherStudioProfile: false,
} as Invite

export const inviteSlice=createSlice({
    name:'invite',
    initialState,
    reducers:{
        setInvitation: (state,action:PayloadAction<Invitation>) =>{
            state.invitation=action.payload;
        },
        setStudioName: (state,action:PayloadAction<string>) =>{
            state.studioName=action.payload;
        },
        setStudioUsername: (state,action:PayloadAction<string>) =>{
            state.studioUsername=action.payload;
        },
        setStudioStaffExist: (state,action:PayloadAction<boolean>) =>{
            state.studioStaffExist=action.payload;
        },
        setAnotherStudioProfile: (state,action:PayloadAction<boolean>) =>{
            state.anotherStudioProfile=action.payload;
        },
        clearInviteState: (state,action:PayloadAction<boolean>) =>{
            state.invitation=initialState.invitation;
            state.studioName=initialState.studioName;
            state.studioUsername=initialState.studioUsername;
            state.studioStaffExist=initialState.studioStaffExist;
            state.anotherStudioProfile = initialState.anotherStudioProfile
        },

    }
})
export const {setInvitation, setStudioName, setStudioUsername, setAnotherStudioProfile, setStudioStaffExist, clearInviteState} = inviteSlice.actions
export default inviteSlice.reducer;
