import React, { FC } from "react";
import GallerySlider from "../GallerySlider/GallerySlider";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import {acceptOrRejectStudioInvite} from "../../services/studioServoce";
import { toast } from 'react-toastify';
import {StudiosList} from "../../models/studio";
import { useAppSelector } from "../../hooks/redux-hooks";

// interface Studio {
//     id: string | number,
//     businessName: string,
//     businessAddress: string,
//     intranet: string | number,
//     redirectUrl: string,
//     inviteId: number,
// }

// export interface StudiosList {
//     studios: Studio[];
// }

function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

async function AcceptStudioInvite(inviteId: number) {
    const token = useAppSelector((state)=>state.auth.token);
    let response = await acceptOrRejectStudioInvite(inviteId, true, false, token);

    if(response === true){
        toast.success("Studio invitation was successfully accepted", {
            position: toast.POSITION.TOP_CENTER
          });
          await sleep(2000);
          window.location.reload();

    }else{
        toast.error("Studio invitation was not updated", {
            position: toast.POSITION.TOP_CENTER
          });
    }

  }

  async function RejectStudioInvite(inviteId: number) {
    const token = useAppSelector((state)=>state.auth.token);
    let response = await acceptOrRejectStudioInvite(inviteId, false, true, token);

    if(response === true){
        toast.success("Studio invitation was rejected", {
            position: toast.POSITION.TOP_CENTER
          });
          await sleep(3000);
          window.location.reload();

    }else{
        toast.error("Studio invitation was not updated", {
            position: toast.POSITION.TOP_CENTER
          });
    }

  }


const renderSliderGallery = (item: any) => {
    return (
        <div className="relative w-full">
            <GallerySlider
                uniqueID={`StayCard_${item.id}`}
                ratioClass="aspect-w-4 aspect-h-3 "
                galleryImgs={item.images}
            />
        </div>
    );
};

const renderContent = (item: any) => {

    return (
        <div  className={"p-4 space-y-4"}>
            <div className="space-y-2">
                <div className="flex items-center space-x-2">
                    <h2 className={"text-lg" }>
                        <span className="line-clamp-1">{item.studioName}</span>
                    </h2>
                </div>
                <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={1.5}
                                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                            />
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={1.5}
                                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                        </svg>
                    <span className="">{item.businessAddress}</span>
                </div>
            </div>
                <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
                <div className="flex justify-between items-center">
                <ButtonPrimary onClick={() => AcceptStudioInvite(item.inviteId)}>Accept</ButtonPrimary>
                <ButtonSecondary onClick={() => RejectStudioInvite(item.inviteId)}>Reject</ButtonSecondary>
            </div>
        </div>
    );
};

const StudiosInvitesCard : FC<StudiosList> = ({studios}) => {

    return (
        <div  className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-4 md:gap-8 ">
            {studios.map((item: any) => {
                return(
                    <div
                        key={item.id}
                        className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow`}
                        data-nc-id="StayCard"
                    >
                        <div>
                            {renderSliderGallery(item)}
                        </div>
                        <div style={{cursor: 'pointer'}} >
                            {renderContent(item)}
                        </div>


                    </div>
                )
            })}
        </div>

    );
};

export default StudiosInvitesCard ;


