import React, {FC, useState} from "react";
import {Helmet} from "react-helmet";
import {ErrorMessage, Form, Formik} from "formik";
import Input from "../../shared/Input/Input";
import ButtonPrimary from "../../shared/Button/ButtonPrimary";
import * as Yup from "yup";
import forgotPass from "../../services/forgotPass";
import {useLocation} from "react-router-dom";
import CustomError from "../../components/Error/CustomError";
import {toast} from "react-toastify";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";

export interface ForgotPassProps {
    className?: string;
}

const ForgotPass: FC<ForgotPassProps> = ({ className = "" }) => {

    const state:any = useLocation().state;

    const [emailSent, setEmailSent] = useState(false);
    const [noUser, setNoUser] = useState(false);
    const [reqError, setReqError] = useState(false);
    const [requestLoading, setRequestLoading] = useState(false)

    const validationSchema = Yup.object().shape({
        email: Yup.string().email().required("Email is required!"),
    });
    const initialValues = {
        email: state.email,
    };

    const handleSendEmail = async (formValue: { email: string; }) : Promise<void> => {
        await setRequestLoading(true);
        await setNoUser(false);
        await setReqError(false);
        let req = await forgotPass.sendEmail(formValue.email);

        if (req.response === "success"){
            await setEmailSent(true);
            toast.success("Reset email sent successfully", {
                position: toast.POSITION.TOP_CENTER,
            });
        }else if (req.response === "noUser"){
            await setNoUser(true);
        }else{
            await setReqError(true);
        }
        await setRequestLoading(false)
    };
        return (
        <div>
            <Helmet>
                <title>Login || Tattoohub</title>
            </Helmet>
            <div className="container mb-24 lg:mb-32">
                <h2 className="my-10 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                    Forgot password
                </h2>
                <h2 className="my-10 flex items-center text-2xl  font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                    Insert email you registered with
                </h2>
                <div className="max-w-md mx-auto space-y-6">

                    {/* FORM */}
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSendEmail}
                    >
                        {({ values, setFieldValue, handleChange, errors, touched }) => (
                            <Form className="grid grid-cols-1 gap-6">
                                <label className="block">
                                  <span className="text-neutral-800 dark:text-neutral-200">
                                    Email address
                                  </span>
                                    <Input
                                        type="email"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        placeholder="example@example.com"
                                        className="mt-1"
                                    />
                                    <ErrorMessage name="email" component="div" className="text-red-500 text-xs italic"/>
                                </label>
                                <ButtonPrimary type="submit" >Continue</ButtonPrimary>
                                {emailSent ? <div onClick={() => {handleSendEmail({email: values.email})}}  className="text-sm hover:cursor-pointer flex justify-end">
                                    Email not received?
                                </div> : null}
                                {noUser ? <CustomError text={"No user found with this email"} /> : null}
                                {reqError ? <CustomError text={"An error occurred while processing your request, Please refresh and try again"} /> : null}
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
            {requestLoading ? <LoadingSpinner /> : null}
        </div>
    );
};

export default ForgotPass;
