import React, { useState, useEffect } from "react";
import contests from "../../services/contests";
import { useHistory} from "react-router-dom";
import {api_url} from "../../contains/contants";
import NcImage from "../../shared/NcImage/NcImage";
import ModalPhotos from "../StudioProfilePage/ModalPhotos";
import ButtonPrimary from "../../shared/Button/ButtonPrimary";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
const { ClientJS } = require('clientjs');

const StatusActive = (props: {id: number}) => {
    let nav = useHistory();
    const [images, setImages] = useState([])
    const [requiredLoading, setRequestLoading] = useState(false)
    const client = new ClientJS();
    const fingerprint = client.getFingerprint().toString();

    useEffect(() => {
        (async () => {
            await setRequestLoading(true);
            let req:any = await contests.getDataActiveContest(props.id, nav);
            await setImages(req.images);
            await setRequestLoading(false);
        })()
    }, [])

    const [isOpen, setIsOpen] = useState(false);
    const [openFocusIndex, setOpenFocusIndex] = useState(0);

    const handleOpenModal = (index: number) => {
        setIsOpen(true);
        setOpenFocusIndex(index);
    };

    const handleCloseModal = () => setIsOpen(false);


    return (
        <main className="container relative z-10 mt-11 flex flex-col lg:flex-row ">
            {requiredLoading ? <LoadingSpinner /> : null}
            {/* CONTENT */}
            {images ?  <div className="w-full space-y-8 lg:space-y-10">
                <div className={"flex justify-center flex-wrap"}>
                    {images.map((item:any, index: number) => {
                        return (
                            <div style={{width: "200px"}} className="relative gap-1 sm:gap-2 m-3" key={item.id}>
                                <>
                                    <header className="rounded-md sm:rounded-xl" >
                                        <div>
                                            <div
                                                className="col-span-2 row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
                                                onClick={() => handleOpenModal(index)}
                                            >
                                                <NcImage
                                                    style={{
                                                        width: "100%",
                                                        height: "300px"
                                                    }}
                                                    className="object-cover rounded-md sm:rounded-xl "
                                                    src={api_url + "/contestTattoos/" + props.id + "/thumbs/" + item.imageUrl}
                                                />
                                                <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
                                            </div>
                                        </div>
                                        <div className={"flex justify-center mt-3 mb-3"}>
                                            <i className="las la-user text-2xl"></i>
                                            <span>Votes: {item.votes}</span>
                                        </div>
                                        <div className={"flex justify-center"}>
                                            {!item.fingerprints.includes(fingerprint) ?  <ButtonPrimary onClick={async () => {


                                                await setRequestLoading(true);
                                                let req = await contests.voteContest(item.id, fingerprint);

                                                if (req.response === "success"){
                                                    let updateVotes:any = images;
                                                    let index = images.findIndex((i:any) => i.id === item.id);
                                                    updateVotes[index].votes += 1;
                                                    updateVotes[index].fingerprints = [...updateVotes[index].fingerprints, fingerprint];
                                                    await setImages(updateVotes)
                                                }
                                                await setRequestLoading(false)

                                            }}>Vote</ButtonPrimary> :  <ButtonPrimary className={"bg-red-500"} onClick={async () => {
                                                await setRequestLoading(true);
                                                let req = await contests.unVoteContest(item.id, fingerprint);
                                                if (req.response === "success"){
                                                    let updateVotes:any = images;
                                                    let index = images.findIndex((i:any) => i.id === item.id);
                                                    updateVotes[index].votes -= 1;
                                                    updateVotes[index].fingerprints = updateVotes[index].fingerprints.filter((item:any) => {return item !== fingerprint})
                                                    await setImages(updateVotes)
                                                }
                                                await setRequestLoading(false)

                                            }}>Unvote</ButtonPrimary>}

                                        </div>

                                    </header>
                                    {/* MODAL PHOTOS */}


                                </>
                            </div>


                        )
                    })}
                    <ModalPhotos
                        imgs={images.map((item: any) => {return "/contestTattoos/" + props.id + "/" + item.imageUrl})}
                        isOpen={isOpen}
                        onClose={handleCloseModal}
                        initFocus={openFocusIndex}
                        uniqueClassName="nc-ListingCarDetailPage__modalPhotos"
                    />
                </div>
            </div> : null}

        </main>
    );
};

export default StatusActive;
