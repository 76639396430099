import {axiosReq} from "../store";
import {toast} from "react-toastify";

export async function getBookingDataForStudio (username: string, artistUsername: string) {
    try {

        let services = [];
        let serviceStaff = [];
        let studioStaff = [];
        let bookingPageSettings = {};
        let req = await axiosReq.get('/api/get-booking-data-for-studio?username=' + username + "&artistUsername=" + artistUsername);

        if (req.data.response === "success"){
            services = req.data.data.services;
            serviceStaff = req.data.data.serviceStaff;
            studioStaff = req.data.data.studioStaff;
            bookingPageSettings = req.data.data.bookingPageSettings;
        }

        return {response: 'success', services: services, serviceStaff: serviceStaff, studioStaff: studioStaff, bookingPageSettings: bookingPageSettings }

    }catch (e){
        return {response: 'error'}
    }
}

export async function generateTimeSlots (studioId:number, serviceId:number, studioStaffId:number, individualBookingHours: boolean) {
    let slots = []

    try {

        let req = await axiosReq.post('/api/generate-booking-page-time-slots', {
            studioId: studioId,
            serviceId: serviceId,
            studioStaffId: studioStaffId,
            individualBookingHours: individualBookingHours
        });

        if (req.data.response === "success"){
            slots = req.data.timeSlots
        }

        return {response: 'success',  slots: slots}

    }catch (e){
        toast.error("An error occurred. Please refresh and try again", {
            position: toast.POSITION.TOP_CENTER
        });
        return {response: 'error', slots: slots}
    }
}

export async function createBooking (bookingData:any) {

    try {

        let formData = new FormData();
        formData.append('serviceId', bookingData.serviceId);
        formData.append('serviceDuration', bookingData.serviceDuration);
        formData.append('studioStaffId', bookingData.studioStaffId);
        formData.append('date',bookingData.date);
        formData.append('hour', bookingData.hour)
        formData.append('viewFields', JSON.stringify(bookingData.viewFields))
        formData.append('description', bookingData.description)
        formData.append('firstName', bookingData.firstName)
        formData.append('lastName', bookingData.lastName)
        formData.append('email', bookingData.email)
        formData.append('phone', bookingData.phone)
        formData.append('telCode', JSON.stringify(bookingData.telCode))

        for (let i = 0; i < bookingData.images.length; i++){
            formData.append('images', bookingData.images[i])
        }

        let req = await axiosReq.post('/api/create-new-booking', formData);

        return {response: req.data.response, guid: req.data.bookingGuid}

    }catch (e){
        toast.error("An error occurred. Please refresh and try again", {
            position: toast.POSITION.TOP_CENTER
        });
        return {response: 'error',}
    }
}

export async function verifyBooking (otp:string, guidString: string) {

    try {

        let req = await axiosReq.post('/api/verify-booking', {
            otp: otp,
            guidString: guidString
        });

        return {response: req.data.response,}

    }catch (e){
        toast.error("An error occurred. Please refresh and try again", {
            position: toast.POSITION.TOP_CENTER
        });
        return {response: 'error',}
    }
}

export async function fetchBookingDetails (guidString: string) {

    try {

        let req = await axiosReq.get('/api/fetch-booking-details?guidString=' + guidString);

        return {
            response: req.data.response,
            artist: req.data.artist,
            businessInfo: req.data.businessInfo,
            bookingData: req.data.bookingData,
            service: req.data.service,
            customer: req.data.customer,
        }

    }catch (e){
        toast.error("An error occurred. Please refresh and try again", {
            position: toast.POSITION.TOP_CENTER
        });
        return {response: 'error',}
    }
}
export async function generateCancelCode (guidString: string) {

    try {

        await axiosReq.post('/api/generate-cancel-code', {
            guidString: guidString
        });

        return {response: "success"}
    }catch (e){
        return {response: 'error',}
    }
}
export async function generateConfirmCode (guidString: string) {
    try {

        await axiosReq.post('/api/generate-confirm-code', {
            guidString: guidString
        });

        return {response: "success"}
    }catch (e){
        return {response: 'error'}
    }
}
export async function cancelBooking (guidString: string, cancelCode: string) {

    try {

        let req = await axiosReq.post('/api/cancel-booking', {
            guidString: guidString,
            cancelCode: cancelCode
        });

        return {response: req.data.response}
    }catch (e){
        toast.error("An error occurred. Please refresh and try again", {
            position: toast.POSITION.TOP_CENTER
        });
        return {response: 'error',}
    }
}

export function priceConvert (priceType: string, priceValue: number) {
    let response = "";
    let currencySign = "$";
    //TODO: currency sign
    switch (priceType){
        case "priceNotSet":
            response = "Price not set";
            break;
        case "fixed":
            response = priceValue + " " + currencySign;
            break;
        case "startingAt":
            response = "Starting at " + priceValue + " " + currencySign;
            break;
        case "hourly":
            response = priceValue + " " + currencySign + " per hour";
            break;
        case "free":
            response = "Free";
            break;
        case "priceVaries":
            response = "Price varies";
            break;
        case "callUs":
            response = "Call for price";
            break;
    }
    return response;
}
