import React from "react";
import "../../styles/loader.css";

export default function LoadingSpinner() {
    return (
        <div className="spinner-container" style={{
            position: 'fixed'
        }}>
            <div className="loading-spinner">
            </div>
        </div>
    );
}

