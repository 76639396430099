import {axiosReq} from "../store";
import {domain} from "../contains/contants";
import {CreateStudio} from "../models/createStudio";

export async function createStudioRequest(images:any[], createStudio: CreateStudio, userId:number, token: string) {

    let responseObj = {
        success: false,
        images: []
    }
    try {

        let formData = new FormData();
        formData.append('userId', userId.toString());
        formData.append('plan', createStudio.plan);
        formData.append('username', createStudio.username + domain);
        formData.append('name', createStudio.studioName);
        formData.append('address', createStudio.location.address);
        formData.append('lat', createStudio.location.lat);
        formData.append('lon', createStudio.location.lon);
        formData.append('city', createStudio.location.city);
        formData.append('country', createStudio.location.country);
        formData.append('phone', createStudio.phone);
        formData.append('telCode', JSON.stringify(createStudio.telCode));
        formData.append('email',createStudio.email);
        formData.append('websiteURL', createStudio.webSiteURL);
        formData.append('facebook', createStudio.facebook);
        formData.append('instagram', createStudio.instagram);
        formData.append('timeFormat', createStudio.timeFormat.value);
        formData.append('language', createStudio.language.value);
        formData.append('timeZone', createStudio.timeZone.value);
        formData.append('currency', createStudio.currency.value);
        formData.append('bookingHours', JSON.stringify(createStudio.bookingHours));

        for (let i = 0; i < images.length; i++){
            formData.append('bookingPageImages', images[i])
        }

        let req = await axiosReq.post('/api/create-studio',formData,{params: {token: token}});

        if(req.data.response === 'success'){
            responseObj.success = true
            responseObj.images = req.data.images
        }
    }catch (e){
        return responseObj;
    }

    return responseObj;
}

export async function checkIfUsernameExist (username: any, token: string) {
    try {

        let req = await axiosReq.post('/api/is-studio-username-exist', {
            username: username
        }, {params: {token: token}});

        return {response: 'success', isExist: req.data.isExist}

    }catch (e){
        return {response: 'error'}
    }
}
export async function checkIfEmailExist (email: string, token: string) {
    try {

        let req = await axiosReq.post('/api/is-studio-email-exist', {
            email: email
        }, {params: {token: token}});

        return {response: 'success', isExist: req.data.isExist}

    }catch (e){
        return {response: 'error'}
    }
}
