import React, { FC } from "react";
import { PostDataType } from "data/types";
import ForTattooersCard from "./ForTattooersCard";
import BookingAppForTattooers from "images/BookingAppForTattooers.png";
import TattooContestsForTattooers from "images/TattooContestsForTattooers.png";

export interface ForTattoersLayoutProps {
  posts: PostDataType[];
}

const ForTattoersLayout: FC<ForTattoersLayoutProps> = ({ posts }) => {
  return (
    <div className="nc-SectionMagazine5">
      <div className="grid lg:grid-cols-2 gap-6 md:gap-8">
        <ForTattooersCard title="Booking App" desc="Streamline your appointments and manage clients effortlessly" href="/bookingapp" featuredImage={BookingAppForTattooers} post={posts[0]} />
        <ForTattooersCard title="Tattoo Contest" desc="Showcase your art, compete with peers, and win exciting prizes" href="/tattoocontest" featuredImage={TattooContestsForTattooers} post={posts[0]} />
      </div>
    </div>
  );
};

export default ForTattoersLayout;
