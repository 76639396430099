import React, {FC, useState} from "react";
import imagePng from "images/back-right.png";
import ClearDataButton from "../HeroSearchForm/ClearDataButton";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import {googleMapsAPI} from "../../contains/contants";
export interface LocationInputProps {
    className?: string;
    children?: React.ReactNode;
    onSelectFN: any
    clearFunction: any
}

const LocationInput: FC<LocationInputProps> = ({ className = "", onSelectFN = () => {}, clearFunction = () => {}}) => {
    const [value, setValue] = useState('');

    return (
        <div
            className={`nc-SectionHeroArchivePage flex flex-col relative ${className}`}
            data-nc-id="SectionHeroArchivePage"
        >
            <div className="flex flex-col lg:flex-row lg:items-center">
                <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-6 lg:space-y-10 pb-14 lg:pb-64 xl:pb-80 xl:pr-14 lg:mr-10 xl:mr-0">
                    <h2 className="font-medium text-4xl md:text-5xl xl:text-7xl leading-[110%]">
                            Pick your preferred tattoo artist
                    </h2>
                </div>
                <div className="flex-grow">
                    <img className="w-full" src={imagePng} alt="hero" />
                </div>
            </div>
            <form style={{position: 'relative', bottom: '17rem'}} className="w-full relative mt-8 flex flex-col md:flex-row  rounded-3xl lg:rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800 divide-y divide-neutral-200 dark:divide-neutral-700 md:divide-y-0">
                <div
                    className={`flex flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left nc-hero-field-focused`}
                >
                    <div className="text-neutral-300 dark:text-neutral-400">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="nc-icon-field"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={1.5}
                                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                            />
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={1.5}
                                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                        </svg>
                    </div>
                    <div className="flex-grow " style={{
                        height: 50,
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <ReactGoogleAutocomplete
                            options={{
                                types: ['locality', 'country'],
                            }}
                            style = {{outlineStyle: 'none' }}
                            language={'en'}
                            apiKey={googleMapsAPI}
                            onPlaceSelected={(place:any) => {
                                setValue(place.formatted_address)

                                let city = place.address_components.filter(function (obj: any){return obj.types[0] === 'locality'})[0]
                                let country = place.address_components.filter(function (obj: any){return obj.types[0] === 'country'})[0]

                                onSelectFN(city ? city.long_name : '', country ? country.long_name : '')
                            }}


                        />
                        <span className="block mt-0.5 text-sm text-neutral-400 font-light"></span>
                        {value && (
                            <ClearDataButton
                                onClick={() =>{
                                    setValue('')
                                    clearFunction()
                                }}
                            />
                        )}
                    </div>
                </div>
            </form>
        </div>
    );
};

export default LocationInput;
