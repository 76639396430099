import {toast} from "react-toastify";
import {axiosReq} from "../store";

export default{
    async fetchTattooContests() {

        try {
            let req = await axiosReq.get('/api/fetch-tattoo-contests');

            return {response: req.data.response, contests: req.data.contests};

        }catch (e){
            return {response: "error"};
        }
    },
    async getDataUpcomingContest(contestId: number, artistId: number | string, nav: any) {
        try {
            let req = await axiosReq.post('/api/get-data-upcoming-contest?contestId=' + contestId + "&artistId=" + artistId);

            return {image: req.data.image};
        }catch (e){
            return {response: "error"};
        }
    },
    async getDataFinishedContest(contestId: number) {
        try {
            let req = await axiosReq.get('/api/get-data-finished-contest?contestId=' + contestId);

            return {winnerImages: req.data.winnerImages, rankings: req.data.rankings};
        }catch (e){
            return {response: "error"};
        }
    },
    async getDataActiveContest(contestId: number, nav: any) {
        try {
            let req = await axiosReq.get('/api/get-data-active-contest?contestId=' + contestId);

            return {images: req.data.images};



        }catch (e){
            return {response: "error"};
        }
    },

    async getContestMainData(contestId: number, nav: any) {
        try {
            let req = await axiosReq.get('/api/get-contest-main-data?contestId=' + contestId);

            if (req.data.response === "success"){
                return {contest: req.data.contest};
            }else if(req.data.response === "contestNotFound"){
                nav.push('/page404')
            }


        }catch (e){
            return {response: "error"};
        }
    },

    async voteContest(contestTattooId: number, fingerprint: string) {

        try {
            let req = await axiosReq.post('/api/vote-contest',{
                contestTattooId: contestTattooId,
                fingerprintId: fingerprint
            });

            if (req.data.response === "success"){
                toast.success("You voted successfully.", {
                    position: toast.POSITION.TOP_RIGHT
                });

            }else if(req.data.response === "alreadyVoted"){
                toast.warn("You have already voted for this contest.", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            return {response: req.data.response};


        }catch (e){
            return {response: "error"};
        }
    },
    async unVoteContest(contestTattooId: number, fingerprint: string) {

        try {
            let req = await axiosReq.post('/api/unvote-contest',{
                contestTattooId: contestTattooId,
                fingerprintId: fingerprint
            });

            if (req.data.response === "success"){
                toast.success("You unvoted successfully.", {
                    position: toast.POSITION.TOP_RIGHT
                });

            }
            return {response: req.data.response};


        }catch (e){
            return {response: "error"};
        }
    },
    async uploadContestImage(contestId: number, artistId: number | string, image: any[], token: string) {

        try {
            let formData = new FormData();
            formData.append('contestId', contestId.toString());
            formData.append('artistId', artistId.toString());
            formData.append('image', image[0]);


            let req = await axiosReq.post('/api/upload-contest-image', formData, {params: {token: token}});

            if (req.data.response === "success"){
                toast.success("Image uploaded successfully.", {
                    position: toast.POSITION.TOP_RIGHT
                });
                return {response: req.data.response, image: req.data.image};
            }else if(req.data.response === "imagesLimit"){
                toast.error("You can upload one image.", {
                    position: toast.POSITION.TOP_RIGHT
                });
                return {response: "error"};
            }


        }catch (e){
            return {response: "error"};
        }
    },
    async deleteContestImage(imageId: number, token: string) {

        try {
            let req = await axiosReq.post('/api/delete-contest-image', {
                imageId: imageId
            }, {params: {token: token}});

            toast.success("Image deleted successfully.", {
                position: toast.POSITION.TOP_RIGHT
            });

            return {response: req.data.response};

        }catch (e){
            return {response: "error"};
        }
    },
    async getPublicPageContests() {

        try {
            let req = await axiosReq.get('/api/get-public-page-contests');

            return {contests: req.data.contests};

        }catch (e){
            return {response: "error"};
        }
    },

}
