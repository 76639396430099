import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import React, {FC, useEffect, useState} from "react";
import { Helmet } from "react-helmet";
import LocationInput from "../../components/Location/LocationInput";
import StylesSlider from "../../components/BrowseByStyle/StylesSlider";
import {artistsSearch} from "../../services/artistsSearch";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import SectionGridArtistBox from "../../components/Artists/SectionGridArtistBox";

export interface ListingRealEstatePageProps {
    className?: string;
}

const ListingRealEstatePage: FC<ListingRealEstatePageProps> = ({
                                                                   className = "",
                                                               }) => {
    useEffect(() => {
        const $body = document.querySelector("body");
        if ($body) {
            $body.className = "theme-cyan-blueGrey";
        }
        return () => {
            if ($body) {
                $body.className = "";
            }
        };
    }, []);

    useEffect(() => {
        (async () => {
            await artistsDataFN('', '', JSON.stringify(styles));
        })()
    }, [])


    const artistsDataFN = async (city: string, country: string, styles: string) => {
        await setArtistsLoading(true)

        try {
            const artistsData = await artistsSearch(city, country, styles);

            setArtists(artistsData.artists)
        } catch (err) {
            console.log(err);
        }

        await setArtistsLoading(false)


        return [];
    }

    const [artists, setArtists] = useState([]);
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [styles, setStyles] = useState<any>([]);
    const [artistsLoading, setArtistsLoading] = useState(false)

    return (
        <div
            className={`nc-ListingRealEstatePage relative overflow-hidden ${className}`}
            data-nc-id="ListingRealEstatePage"
        >
            {artistsLoading ? <LoadingSpinner /> : null}
            <Helmet>
                <title>Tattoohub || Search Tattoo Artists</title>
            </Helmet>

            <div className="container relative overflow-hidden">
                <LocationInput
                    onSelectFN={async (city: string, country: string) => {
                        setCity(city);
                        setCountry(country)
                        await artistsDataFN(city, country, JSON.stringify(styles))
                    }}
                    clearFunction={async () => {
                        await artistsDataFN('', '', JSON.stringify(styles))
                    }}
                />
                <div className="relative py-16 mb-24">
                    <BackgroundSection />
                    <StylesSlider
                        selectedIndex={styles}
                        onClickFunction={(st: any) => {
                            setStyles(st)
                            artistsDataFN(city, country, JSON.stringify(st))
                        }}
                        heading="Browse by Style"
                        subHeading="Find artists by your preferred style"
                        categoryCardType="card5"
                        itemPerRow={5}
                        uniqueClassName="LadingPage_s3"
                    />
                </div>



            <div className="relative py-16 mb-24 lg:mb-32">
                <BackgroundSection  />
                <SectionGridArtistBox artists={artists} showMeMore={false}/>
                {/* {artistsLoading ? <LoadingSpinner /> : null} */}
                {/* <ListArtistBox artists={artists} />
                {artistsLoading ? <LoadingSpinner /> : null} */}
            </div>
        </div>
        </div>
    );
};

export default ListingRealEstatePage;
