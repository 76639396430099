import React, {useEffect} from "react";
import {  useHistory  } from "react-router-dom";
import CommonLayout from "../PageAddListing1/CommonLayout";
import Input from "../../shared/Input/Input";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import {crateStudioStepsLength, googleMapsAPI} from "../../contains/contants";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import Navigation from "../../components/Listing/NavBtn";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useAppDispatch,useAppSelector } from "../../hooks/redux-hooks";
import {
    setLocation,
    setPhone,
    setTelCode,
    setEmail,
    setStepCompleted
} from "../../store/create-studio-slice";
import {TelCode, Location} from "../../models/createStudio";
import {checkIfEmailExist} from "../../services/createStudio";
import {toast} from "react-toastify";


const CreateStudioListing2 = () => {
    const navigate = useHistory();
    const dispatch = useAppDispatch();
    const currentStep = 3;
    const stepCompleted = useAppSelector((state)=>state.createStudio.stepCompleted);
    const token = useAppSelector((state)=>state.auth.token);

    useEffect(() => {
        if (stepCompleted < currentStep - 1){
            navigate.push('/dashboard')
        }
    }, []);

    let studioEmail = useAppSelector((state)=>state.createStudio.email);
    let userEmail = useAppSelector((state)=>state.auth.user.emailAddress);

    const initialValues = {
        location: useAppSelector((state)=>state.createStudio.location),
        phone: useAppSelector((state)=>state.createStudio.phone),
        telCode: useAppSelector((state)=>state.createStudio.telCode),
        email: studioEmail ? studioEmail : userEmail,
        countryInitials: ""
    }

    const validationSchema = Yup.object().shape({
        location: Yup.object().test('check-location', 'Location is required', (val) => {
            if (val){
                if (!val.country || !val.lat || !val.lon || !val.address) return false;

                return true;
            }else{
                return false
            }

        }),
        phone: Yup.string(),
        email: Yup.string().email('Business Email must be valid email').required("Business Email is required!").test('isExist', '', async (val:any, testContext:any) => {

            if (val){
                let req:any = await checkIfEmailExist(val, token);

                if (req.response === 'success'){
                    if (req.isExist){
                        return testContext.createError({message: 'Email is already in use'})
                    }else{
                        return true
                    }
                }else{
                    toast.error("Error. Please refresh and try again", {
                        position: toast.POSITION.TOP_CENTER
                    });
                    return false
                }
            }

        }),
    });

    const handleLogin = async (formValue: { location: Location, phone: string, telCode: TelCode, email: string, countryInitials: string}) : Promise<void> => {

        await dispatch(setLocation(formValue.location));
        await dispatch(setPhone(formValue.phone));
        await dispatch(setTelCode(formValue.telCode));
        await dispatch(setEmail(formValue.email));
        if (stepCompleted < currentStep){
            await dispatch(setStepCompleted(currentStep))
        }
        navigate.push("/create-studio-listing-3")

    };

    return (
        <CommonLayout
            index={"0" + currentStep}
            steps={'0' + crateStudioStepsLength}
        >
            <>
                <h2 className="text-2xl font-semibold">Studio Contacts</h2>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

                {/* FORM */}
                <div className="space-y-8">
                    <Formik
                        validateOnChange={false}
                        validateOnBlur={false}
                        validateOnMount={false}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleLogin}
                    >
                        {({ values, setFieldValue, handleChange, errors, touched }) => (
                            <Form className="grid grid-cols-1 gap-6">
                                <label className="block">
                                    <span className="text-neutral-800 dark:text-neutral-200">Address</span>
                                    <div style={{height: 44, borderRadius:10, padding: 10, borderWidth: 1, borderColor: 'black'}}>
                                        <ReactGoogleAutocomplete
                                            options={{
                                                types: ["geocode", "establishment"],
                                                // value: 'Sofia, Bulgaria'
                                            }}
                                            language={'en'}
                                            apiKey={googleMapsAPI}
                                            onPlaceSelected={(place :any) => {
                                                let location = {
                                                    city: place.address_components.filter(function (obj: any){return obj.types[0] === 'locality'})[0] ? place.address_components.filter(function (obj: any){return obj.types[0] === 'locality'})[0].long_name : "",
                                                    country: place.address_components.filter(function (obj: any){return obj.types[0] === 'country'})[0].long_name,
                                                    lat: place.geometry.location.lat(),
                                                    lon: place.geometry.location.lng(),
                                                    address: place.formatted_address,
                                                }

                                                setFieldValue('location', location);
                                                setFieldValue("countryInitials", place.address_components.filter(function (obj: any){return obj.types[0] === 'country'})[0].short_name.toLowerCase())
                                                //TODO: set initial value

                                            }}
                                        />
                                        <span className="block mt-0.5 text-sm text-neutral-400 font-light "></span>

                                    </div>
                                    <ErrorMessage name="location" component="div" className="text-red-500 text-xs italic"/>
                                </label>
                                <label className="block">
                                    <span className="text-neutral-800 dark:text-neutral-200">Business Phone</span>
                                    <PhoneInput
                                        country={values.telCode.countryCode ? values.telCode.countryCode : values.countryInitials}
                                        placeholder="Enter phone number"
                                        value={values.phone ? '+' + values.telCode.dialCode + values.phone : ''}
                                        onChange={(value, data:any, e, formattedValue) => {

                                            let telCode = {};
                                            let phone = '';

                                            if (data && data.dialCode){
                                                telCode = {
                                                    dialCode: data.dialCode,
                                                    countryCode: data.countryCode
                                                }

                                                phone = value.slice(data.dialCode.length)
                                            }

                                            setFieldValue('telCode', telCode);
                                            setFieldValue('phone', phone);
                                        }}
                                    />
                                    <ErrorMessage name="phone" component="div" className="text-red-500 text-xs italic"/>
                                </label>
                                <label className="block">
                                    <span className="text-neutral-800 dark:text-neutral-200">Business Email</span>
                                    <Input
                                        type="email"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        placeholder="Business Email"
                                        className="mt-1"
                                    />
                                    <ErrorMessage name="email" component="div" className="text-red-500 text-xs italic"/>
                                </label>
                                <Navigation prev={"/create-studio-listing-1"} />

                            </Form>

                        )}
                    </Formik>

                </div>
            </>
        </CommonLayout>
    );
};

export default CreateStudioListing2;
