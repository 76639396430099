import {axiosReq} from "../store";

export async function studiosFilter (city: string, country: string, styles: string) {

    let studios = [];

    try {
        let req = await axiosReq.get('/api/studiosFilter?city=' + city + '&country=' + country + '&styles=' + styles);

        studios = req.data.studios;


    }catch (e){
        console.log(e)
    }

    return {studios};
}

