import React, {useEffect} from "react";
import {  useHistory  } from "react-router-dom";
import CommonLayout from "../PageAddListing1/CommonLayout";
import {Formik, Form, ErrorMessage} from "formik";
import * as Yup from "yup";
import {becomeArtistStepsLength, stylesList} from "../../contains/contants";
import Checkbox from "../../shared/Checkbox/Checkbox";
import Navigation from "../../components/Listing/NavBtn";
import { useAppDispatch,useAppSelector } from "../../hooks/redux-hooks";
import {setStepCompleted, setStyles} from '../../store/become-artist-slice'
const BecomeArtistListing2 = () => {
    const navigate = useHistory();
    const dispatch = useAppDispatch();
    const currentStep = 2
    const isTattooArtist = useAppSelector((state)=>state.auth.isTattooArtist);
    const stepCompleted = useAppSelector((state)=>state.becomeArtist.stepCompleted);

    useEffect(() => {
        if (isTattooArtist || stepCompleted < currentStep - 1){
            navigate.push('/dashboard')
        }
    }, [])


    const initialValues = {
        styles: useAppSelector((state)=>state.becomeArtist.styles),
    }

    const validationSchema = Yup.object().shape({
        styles: Yup.array().test('check-styles', 'Styles are required', (val:any) => {

            if (val.length === 0){
                return false
            }
            return true;

        })
    });

    const handleLogin = async (formValue: { styles: string[]}) : Promise<void> => {
        await dispatch(setStyles(formValue.styles));

        if (stepCompleted < currentStep){
            await dispatch(setStepCompleted(currentStep))
        }

        navigate.push('become-artist-listing-3')
    };
    const stylesArray:{value: string, label: string}[] = stylesList.map(function (obj){return {value: obj.id.toString(), label: obj.name}})


    return (
        <CommonLayout
            index={"0" + currentStep}
            steps={'0' + becomeArtistStepsLength}
        >
            <>
                <h2 className="text-2xl font-semibold">Styles</h2>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

                {/* FORM */}
                <div className="space-y-8">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleLogin}
                    >
                        {({ values, setFieldValue, handleChange, errors, touched }) => (
                            <Form className="grid grid-cols-1 gap-6">
                                <div>
                                    <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                                        {stylesArray.map(function (obj){
                                            return (<Checkbox
                                                key={obj.value}
                                                label={obj.label}
                                                defaultChecked={values.styles.indexOf(obj.value) > -1}
                                                name={obj.value}
                                                onChange={(checked) => {
                                                    let array:string[] = values.styles;

                                                    if (!checked){
                                                        array = [...array];
                                                        array.splice(array.indexOf(obj.value), 1)
                                                    }else{
                                                        array = [...array, obj.value]
                                                    }

                                                    setFieldValue("styles", array)
                                                }}
                                            />)
                                        })}


                                    </div>
                                    <ErrorMessage name="styles" component="div" className="text-red-500 text-xs italic"/>

                                </div>
                                <Navigation prev={"/become-artist-listing-1"} />

                            </Form>

                        )}
                    </Formik>

                </div>
            </>
        </CommonLayout>
    );
};

export default BecomeArtistListing2;
