import React, { FC } from "react";
import rightImgPng from "images/our-features.png";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";

export interface SectionOurFeaturesProps {
  className?: string;
  rightImg?: string;
  type?: "type1" | "type2";
}

const SectionOurFeatures: FC<SectionOurFeaturesProps> = ({
  className = "lg:py-14",
  rightImg = rightImgPng,
  type = "type1",
}) => {
  return (
    <div
      className={`nc-SectionOurFeatures relative flex flex-col items-center ${
        type === "type1" ? "lg:flex-row" : "lg:flex-row-reverse"
      } ${className}`}
      data-nc-id="SectionOurFeatures"
    >
      <div className="flex-grow">
        <NcImage src={rightImg} />
      </div>
      <div
        className={`max-w-2xl flex-shrink-0 mt-10 lg:mt-0 lg:w-2/5 ${
          type === "type1" ? "lg:pl-16" : "lg:pr-16"
        }`}
      >
        <span className="uppercase text-sm text-gray-400 tracking-widest">
          Benefits
        </span>
        <h2 className="font-semibold text-4xl mt-5">Tailored Solutions for Every Tattoo Studio </h2>

        <ul className="space-y-10 mt-16">
          <li className="space-y-4">
            <Badge name="Advertising" />
            <span className="block text-xl font-semibold">
            Customize appointments to your business needs
            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
             With Tattoohub, empower your clients with a range of flexible booking options. From self-rescheduling to receiving timely reminders, we ensure a seamless experience.
            </span>
          </li>
          <li className="space-y-4">
            <Badge color="green" name="Exposure " />
            <span className="block text-xl font-semibold">
            Provide your clients flexibility, convenience, and control
            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              Tattoohub  offers more options for your customers when they visit your booking page, book an appointment, or get a confirmation email and calendar invitation. Customers who book an appointment online can easily reschedule or cancel it themselves to keep everyone in sync. If you prefer to book appointments for your customers, enter the appointment details in Bookings so they receive confirmations and reminders. With Bookings, it’s also easy to track all changes made to appointments.
            </span>
          </li>
          <li className="space-y-4">
            <Badge color="red" name="Secure" />
            <span className="block text-xl font-semibold">
            Simplify scheduling to save time
            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
            Streamline Your Scheduling with Tattoohub Bookings. Say goodbye to double-bookings and hello to efficient appointment management.
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SectionOurFeatures;
