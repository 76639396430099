import React, {useEffect} from "react";
import {  useHistory  } from "react-router-dom";
import CommonLayout from "../PageAddListing1/CommonLayout";
import {ErrorMessage, Form, Formik} from "formik";
import Navigation from "../../components/Listing/NavBtn";
import * as Yup from "yup";
import {becomeArtistStepsLength, facebookURL, instagramURL} from "../../contains/contants";
import Input from "../../shared/Input/Input";
import { useAppDispatch,useAppSelector } from "../../hooks/redux-hooks";
import {setFacebook, setInstagram, setStepCompleted} from '../../store/become-artist-slice';

const BecomeArtistListing1 = () => {
    const navigate = useHistory();
    const dispatch = useAppDispatch();

    const currentStep = 3;
    const isTattooArtist = useAppSelector((state)=>state.auth.isTattooArtist);
    const stepCompleted = useAppSelector((state)=>state.becomeArtist.stepCompleted);


    useEffect(() => {
        if (isTattooArtist || stepCompleted < currentStep - 1){
            navigate.push('/dashboard')
        }
    }, [])



    const initialValues = {
        facebook: useAppSelector((state)=>state.becomeArtist.facebook),
        instagram: useAppSelector((state)=>state.becomeArtist.instagram),
    }

    const validationSchema = Yup.object().shape({
        facebook: Yup.string(),
        instagram: Yup.string()
    });

    const handleLogin = async (formValue: { facebook: string, instagram: string}) : Promise<void> => {

        await dispatch(setFacebook(formValue.facebook))
        await dispatch(setInstagram(formValue.instagram))

        if (stepCompleted < currentStep){
            await dispatch(setStepCompleted(currentStep))
        }

        navigate.push('become-artist-listing-4')

    };


    return (
        <CommonLayout
            index={"0" + currentStep}
            steps={'0' + becomeArtistStepsLength}
        >
            <h2 className="text-2xl font-semibold">Artist Social Medias</h2>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleLogin}
            >
                {({ values, setFieldValue, handleChange, errors, touched }) => (
                    <Form className="grid grid-cols-1 gap-6">
                        <label className="block">
                            <span className="text-neutral-800 dark:text-neutral-200">Facebook</span>
                            <Input
                                type="text"
                                name="facebook"
                                value={facebookURL + values.facebook}
                                onChange={(val) => {
                                    if  (val.target.value.indexOf(facebookURL) === 0){
                                        setFieldValue('facebook', val.target.value.replace(facebookURL, ''))
                                    }
                                }}
                                placeholder="Facebook"
                                className="mt-1"
                            />
                            <ErrorMessage name="facebook" component="div" className="text-red-500 text-xs italic"/>
                        </label>
                        <label className="block">
                            <span className="text-neutral-800 dark:text-neutral-200">Instagram</span>
                            <Input
                                type="text"
                                name="instagram"
                                value={instagramURL + values.instagram}
                                onChange={(val) => {
                                    if  (val.target.value.indexOf(instagramURL) === 0){
                                        setFieldValue('instagram', val.target.value.replace(instagramURL, ''))
                                    }
                                }}
                                placeholder="Instagram"
                                className="mt-1"
                            />
                            <ErrorMessage name="instagram" component="div" className="text-red-500 text-xs italic"/>
                        </label>
                        <Navigation prev={"/become-artist-listing-2"} />

                    </Form>

                )}
            </Formik>

               {/*Hello*/}

        </CommonLayout>
    );
};

export default BecomeArtistListing1;
