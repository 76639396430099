import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import LandingPage from "containers/PageHome/LandingPage";
import Page404 from "containers/Page404/Page404";
import ListingStayMapPage from "containers/ListingStayPage/ListingStayMapPage";
import ListingExperiencesPage from "containers/ListingExperiencesPage/ListingExperiencesPage";
import ListingExperiencesMapPage from "containers/ListingExperiencesPage/ListingExperiencesMapPage";
import StudioProfilePage from "containers/StudioProfilePage/StudioProfilePage";
import ListingExperiencesDetailPage from "containers/StudioProfilePage/ListingExperiencesDetailPage";
import ListingCarPage from "containers/ListingCarPage/ListingCarPage";
import ListingCarMapPage from "containers/ListingCarPage/ListingCarMapPage";
import ListingCarDetailPage from "containers/StudioProfilePage/ListingCarDetailPage";
import CheckOutPage from "containers/CheckOutPage/CheckOutPage";
import BookingVerify from "containers/BookingPage/VerifyBooking";
import PayPage from "containers/PayPage/PayPage";
import ArtistProfilePage from "containers/ArtistProfilePage/ArtistProfilePage";
import AccountPage from "containers/AccountPage/AccountPage";
import AccountPass from "containers/AccountPage/AccountPass";
import AccountStudios from "containers/AccountPage/AccountStudios";
import AccountArtistPortfolio from "containers/AccountPage/ArtistPortfolio";
import AccountArtistProfile from "containers/AccountPage/ArtistPage";
import AccountArtistInvites from "containers/AccountPage/ArtistInvites";
import Dashboard from "containers/AccountPage/Dashboard";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import PageLogout from "containers/PageLogout/PageLogout";
import PageSubcription from "containers/PageSubcription/PageSubcription";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";
import PageAddListing1 from "containers/PageAddListing1/PageAddListing1";
import PageAddListing2 from "containers/PageAddListing1/PageAddListing2";
import PageAddListing3 from "containers/PageAddListing1/PageAddListing3";
import PageAddListing4 from "containers/PageAddListing1/PageAddListing4";
import PageAddListing5 from "containers/PageAddListing1/PageAddListing5";
import PageAddListing6 from "containers/PageAddListing1/PageAddListing6";
import PageAddListing7 from "containers/PageAddListing1/PageAddListing7";
import PageAddListing8 from "containers/PageAddListing1/PageAddListing8";
import PageAddListing9 from "containers/PageAddListing1/PageAddListing9";
import PageAddListing10 from "containers/PageAddListing1/PageAddListing10";
import PageHome2 from "containers/PageHome/PageHome2";
// import Sales from "containers/PageHome/Sales";
import ForTattoersCommonPage from "components/ForTattooers/ForTattoersCommonPage";
import ListingRealEstateMapPage from "containers/ListingRealEstatePage/ListingRealEstateMapPage";
import SiteHeader from "containers/SiteHeader";
import ListingFlightsPage from "containers/ListingFlightsPage/ListingFlightsPage";
import StudiosSearch from "../containers/StudiosSearchPage/StudiosSearch";
import ArtistsSearch from "../containers/ArtistsSearch/ArtistsSearch";
import BecomeArtistListing1 from "../containers/BecomeArtist/BecomeArtistListing1";
import BecomeArtistListing2 from "../containers/BecomeArtist/BecomeArtistListing2";
import CreateStudioListing1 from "../containers/CreateStudio/CreateStudioListing1";
import CreateStudioListing2 from "../containers/CreateStudio/CreateStudioListing2";
import CreateStudioListing3 from "../containers/CreateStudio/CreateStudioListing3";
import CreateStudioListing4 from "../containers/CreateStudio/CreateStudioListing-4";
import CreateStudioListing5 from "../containers/CreateStudio/CreateStudioListing5";
import CreateStudioSelectPlan from "../containers/CreateStudio/CreateStudioSelectPlan";
import BecomeArtistListing3 from "../containers/BecomeArtist/BecomeArtistListing3";
import BecomeArtistPreview from "../containers/BecomeArtist/BecomeArtistPreview";
import CreateStudioListing6 from "../containers/CreateStudio/CreateStudioListing6";
import CreateStudioPreview from "../containers/CreateStudio/CreateStudioPreview";
import BecomeArtistListing4 from "../containers/BecomeArtist/BecomeArtistListing4";
import AcceptInvite from "../containers/Invites/AcceptInvite";
import InvitesLogin from "../containers/Invites/InvitesLogin/InvitesLogin";
import InvitesSignUp from "../containers/Invites/InvitesSgnUp/InvitesSignUp";
import ForgotPass from "../containers/ForgotPass/ForgotPass";
import ChangeForgotPass from "../containers/ForgotPass/ChangeForgotPass";
import ConfirmEmail from "../containers/ConfirmEmail/ConfirmEmail";
import BookingSummary from "containers/BookingPage/BookingSummary";
import MultiStepForm from "containers/BookingPage/MultiStepForm";
import CancelBooking from "../containers/BookingPage/CancelBooking";
import ListTattooContests from "../containers/Contests/ListTattooContests";
import ContestDetails from "../components/Contests/ContestDetails";
import ListContestsPublicPage from "../containers/Contests/TattooContestsPublicPage";
import BookingApp from "../components/ForTattooers/BookingApp";
import TattooContest from "../components/ForTattooers/TattooContest";

export const pages: Page[] = [
  { path: "/", exact: true, component: LandingPage },
  { path: "/#", exact: true, component: LandingPage },
  { path: "/home-1-header-2", exact: true, component: LandingPage },
  { path: "/home-2", component: PageHome2 },
  { path: "/sales", component: ForTattoersCommonPage },
  { path: "/bookingapp", component: BookingApp },
  { path: "/tattoocontest", component: TattooContest },
  //
  { path: "/search-studios", component: StudiosSearch },
  { path: "/listing-stay-map", component: ListingStayMapPage },
  { path: "/studio/:username", component: StudioProfilePage },
  //
  {
    path: "/listing-experiences",
    component: ListingExperiencesPage,
  },
  {
    path: "/listing-experiences-map",
    component: ListingExperiencesMapPage,
  },
  {
    path: "/listing-experiences-detail",
    component: ListingExperiencesDetailPage,
  },
  //
  { path: "/listing-car", component: ListingCarPage },
  { path: "/listing-car-map", component: ListingCarMapPage },
  { path: "/listing-car-detail", component: ListingCarDetailPage },
  //
  { path: "/listing-real-estate-map", component: ListingRealEstateMapPage },
  { path: "/artists-search", component: ArtistsSearch },
  //
  { path: "/listing-flights", component: ListingFlightsPage },
  //
  { path: "/checkout", component: CheckOutPage },
  { path: "/pay-done", component: PayPage },
  //
  { path: "/artists/:username", component: ArtistProfilePage },
  { path: "/account", component: AccountPage },
  { path: "/account-password", component: AccountPass },
  { path: "/account-studios", component: AccountStudios },
  { path: "/account-artist-portfolio", component: AccountArtistPortfolio },
  { path: "/account-artist-profile", component: AccountArtistProfile },
  { path: "/account-artist-invites", component: AccountArtistInvites },
  { path: "/dashboard", component: Dashboard },
  //
  { path: "/blog", component: BlogPage },
  { path: "/blog-single", component: BlogSingle },
  //
  { path: "/add-listing-1", component: PageAddListing1 },
  { path: "/add-listing-2", component: PageAddListing2 },
  { path: "/add-listing-3", component: PageAddListing3 },
  { path: "/add-listing-4", component: PageAddListing4 },
  { path: "/add-listing-5", component: PageAddListing5 },
  { path: "/add-listing-6", component: PageAddListing6 },
  { path: "/add-listing-7", component: PageAddListing7 },
  { path: "/add-listing-8", component: PageAddListing8 },
  { path: "/add-listing-9", component: PageAddListing9 },
  { path: "/add-listing-10", component: PageAddListing10 },
  //
  { path: "/contact", component: PageContact },
  { path: "/about", component: PageAbout },
  { path: "/signup", component: PageSignUp },
  { path: "/login", component: PageLogin },
  { path: "/forgot-pass", component: ForgotPass },
  { path: "/change-forgot-pass/:token", component: ChangeForgotPass },
  { path: "/confirm-email/:token", component: ConfirmEmail },
  { path: "/logout", component: PageLogout },
  { path: "/subscription", component: PageSubcription },
  { path: "/become-artist-listing-1", component: BecomeArtistListing1},
  { path: "/become-artist-listing-2", component: BecomeArtistListing2},
  { path: "/become-artist-listing-3", component: BecomeArtistListing3},
  { path: "/become-artist-listing-4", component: BecomeArtistListing4},
  { path: "/become-artist-preview", component: BecomeArtistPreview},
  { path: "/create-studio-select-plan", component: CreateStudioSelectPlan},
  { path: "/create-studio-listing-1", component: CreateStudioListing1},
  { path: "/create-studio-listing-2", component: CreateStudioListing2},
  { path: "/create-studio-listing-3", component: CreateStudioListing3},
  { path: "/create-studio-listing-4", component: CreateStudioListing4},
  { path: "/create-studio-listing-5", component: CreateStudioListing5},
  { path: "/create-studio-listing-6", component: CreateStudioListing6},
  { path: "/create-studio-preview", component: CreateStudioPreview},
  { path: "/invites/:username/:token", component: AcceptInvite},
  { path: "/invites-login", component: InvitesLogin},
  { path: "/invites-signup", component: InvitesSignUp},
  //
  //booking
  { path: "/studios/:username/book", component: MultiStepForm },
  { path: "/artist/:username/book", component: MultiStepForm },
  { path: "/booking/:guidString/verify", component: BookingVerify },
  { path: "/booking/:guidString/details", component: BookingSummary },
  { path: "/booking/:guidString/cancel", component: CancelBooking },
  //contests
  { path: "/tattoo-contests", component: ListContestsPublicPage },
  { path: "/list-tattoo-contests", component: ListTattooContests },
  { path: "/contest-details/:id", component: ContestDetails },

];

const Routes = () => {
  return (
    <BrowserRouter basename="/">
      <ScrollToTop />
      <SiteHeader />

      <Switch>
        {pages.map(({ component, path, exact }) => {
          return (
            <Route
              key={path}
              component={component}
              exact={!!exact}
              path={path}
            />
          );
        })}
        <Route component={Page404} />
      </Switch>
      <Footer />
    </BrowserRouter>
  );
};

export default Routes;
