import {axiosReq} from "../store";
import {api_url} from "../contains/contants";

export default{
    async confirm(token: string, authToken: string) {

        try {
            let req = await axiosReq.post('/api/confirm-email',{
                token: token,
            }, {params: {token: authToken}});

            return {response: req.data.response};

        }catch (e){
            return {response: "error"};
        }
    },
    async sendEmail(userId: number, authToken: string) {

        try {
            let req = await axiosReq.post(api_url + '/api/send-confirm-email',{
                userId: userId,
            }, {params: {token: authToken}});

            return {response: req.data.response};

        }catch (e){
            return {response: "error"};
        }
    },


}
