import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";

export interface StudioPreviewProps {
    className?: string;
    studioName: string;
    location: string;
    gallery: string[];
    size?: "default" | "small";
}

const StudioPreview: FC<StudioPreviewProps> = ({
                                              studioName,
                                              location,
                                              gallery,
                                              size = "default",
                                              className = "",
                                          }) => {


    const renderSliderGallery = () => {
        return (
            <div className="relative w-full">
                <GallerySlider
                    uniqueID={`StayCard_${gallery.length}`}
                    ratioClass="aspect-w-4 aspect-h-3 "
                    galleryImgs={gallery}
                />
            </div>
        );
    };

    const renderContent = () => {
        return (
            <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
                <div className="space-y-2">
                    <div className="flex items-center space-x-2">
                        <h2
                            className={` font-medium capitalize ${
                                size === "default" ? "text-lg" : "text-base"
                            }`}
                        >
                            <span className="line-clamp-1">{studioName}</span>
                        </h2>
                    </div>
                    <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
                        {size === "default" && (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={1.5}
                                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                />
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={1.5}
                                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                            </svg>
                        )}
                        <span className="">{location}</span>
                    </div>

                </div>
                <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
            </div>
        );
    };

    return (
        <div
            className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
            data-nc-id="StayCard"
        >
            <div>
                {renderSliderGallery()}
                {renderContent()}
            </div>
        </div>
    );
};

export default StudioPreview;
