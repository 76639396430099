import {axiosReq} from "../store";
import {AuthModel} from "../models/auth";

export default{
 async login(emailAddress: string, password: string) {
  const authModel: AuthModel = {
    user: {emailAddress: "", id: -1, fullName: "Error", username: "error", emailStatus: "unconfirmed"},
    artist: {id: -1, username: "", styles:[], avatarUrl: "", bio: "", portfolio: []},
    token: "",
    isLoggedIn: false,
    isTattooArtist: false
};
    try {
        let req = await axiosReq.post('/api/login-web',{
            emailAddress: emailAddress,
            password: password
          });

            authModel.user = req.data.user;
            authModel.artist = req.data.artist;
            authModel.token =req.data.token;
            authModel.isLoggedIn = true;
            authModel.isTattooArtist = req.data.isTattooArtist;
            return {data: authModel, response: req.data.response, numberOfFails: parseInt(req.data.numberOfFails) || null};

    }catch (e){
        return {data: authModel, response: "error", numberOfFails: null};
    }
},
 async register(fullName: string, emailAddress: string, password: string, confirmPassword: string) {
   const authModel: AuthModel = {
      user: {emailAddress: "", id: -1, fullName: "Error", username: "Error", emailStatus: "unconfirmed"},
      artist: {id: -1, username: "", styles: [], avatarUrl: "", bio: "", portfolio: []},
      token: "",
      isLoggedIn: false,
      isTattooArtist: false
  };
    try {
        let req = await axiosReq.post('/api/register',{
            emailAddress: emailAddress,
            password: password,
            confirmPassword: confirmPassword,
            fullName: fullName
          });

          authModel.user = req.data.user;
          authModel.token =req.data.token;
          authModel.isLoggedIn = true;
          authModel.isTattooArtist = false;
          return {data: authModel, response: req.data.response};

    }catch (e){
        return {data: authModel, response: "error"};
    }

},

    async authWithGoogle(credential: string) {
        const authModel: AuthModel = {
            user: {emailAddress: "", id: -1, fullName: "Error", username: "Error", emailStatus: "unconfirmed"},
            artist: {id: -1, username: "", styles: [], avatarUrl: "", bio: "", portfolio: []},
            token: "",
            isLoggedIn: false,
            isTattooArtist: false
        };
        try {
            let req = await axiosReq.post('/api/auth-with-google',{
                credential: credential
            });

            authModel.user = req.data.user;
            authModel.token =req.data.token;
            authModel.isLoggedIn = true;
            authModel.isTattooArtist = req.data.isTattooArtist;

            if (req.data.artist){
                authModel.artist = req.data.artist;
            }

            return {data: authModel, response: req.data.response};

        }catch (e){
            return {data: authModel, response: "error"};
        }

    },

  // getUserToken() {
  //   const tokenStr = localStorage.getItem("token");
  //   if (tokenStr) return JSON.parse(tokenStr);
  //   return null;
  // },

  // isUserLoggedIn() {
  //   const userStr = localStorage.getItem("isLoggedIn");
  //   if (userStr) return true;
  //   return false;
  // },

  // isArtist() {
  //   const userStr = localStorage.getItem("user"); //TODO: check if the user is artist
  //   if (userStr) return true;
  //   return false;
  // },

  logout() {
    localStorage.removeItem("isLoggedIn");
  }
}
