import { Tab } from "@headlessui/react";
import React, {FC, Fragment, useEffect, useState} from "react";
import Avatar from "shared/Avatar/Avatar";
import SocialsList from "shared/SocialsList/SocialsList";
import { Helmet } from "react-helmet";
import {fetchArtistProfilePageDetails} from "../../services/artistProfilePage";
import {api_url} from "../../contains/contants";
import NcImage from "../../shared/NcImage/NcImage";
import ModalPhotos from "../StudioProfilePage/ModalPhotos";
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import ListStudiosCard from "../../components/Studios/ListStudiosCard";
import {toast} from "react-toastify";
import defaultAvatar from "images/avatars/default-avatar.png";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import {useAppSelector} from "../../hooks/redux-hooks";


export interface ArtistProfilePageProps {
  className?: string;
}

const ArtistProfilePage: FC<ArtistProfilePageProps> = ({ className = "" }) => {
  const nav = useHistory();
  const params:any = useParams();
  const username = params.username;
  const userId:number = useAppSelector((state)=>state.auth.user.id);

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };
  const handleCloseModal = () => setIsOpen(false);

  const [isOpen, setIsOpen] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);
  let [categories] = useState(["Portfolio", "Works At"]);
  const [artist, setArtist] = useState<any>('')
  const [styles, setStyles] = useState<any[]>([]);
  const [studios, setStudios] = useState<any[]>([]);
  const [photos, setPhotos] = useState([]);
  const [showBookBtn, setShowBookBtn] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false)

  let stylesString = '';

  for (let i = 0; i < styles.length; i++){
    stylesString += styles[i].name;
    if (i < styles.length - 1){
      stylesString += ' | '
    }
  }


  useEffect( () => {
    (async () => {
      try {
        await setRequestLoading(true)
        const artistProfilePageDetails = await fetchArtistProfilePageDetails(username, nav);

        await setArtist(artistProfilePageDetails.artist)
        await setPhotos(artistProfilePageDetails.portfolio);
        await setStyles(artistProfilePageDetails.artistStyles)
        await setStudios(artistProfilePageDetails.studios);
        await setShowBookBtn(artistProfilePageDetails.showBookBtn);

        await setRequestLoading(false)

      } catch (err) {
            toast.error("Error while trying to get artist information", {
              position: toast.POSITION.TOP_CENTER
            });
      }
    })()

  }, [])
    
  const renderSidebar = () => {
    return (
      <div className=" w-full flex flex-col items-center text-center sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-7 px-0 sm:p-6 xl:p-8">
        <Avatar
          imgUrl={artist.avatarUrl ? api_url + "/avatars/" + userId + "/thumbs/" + artist.avatarUrl : defaultAvatar}
          hasChecked = {artist.verified}
          hasCheckedClass="w-6 h-6 -top-0.5 right-2"
          sizeClass="w-28 h-28"
        />
        {/* ---- */}
        <div className="space-y-3 text-center flex flex-col items-center">
          <h2 className="text-3xl font-semibold">{artist.fullName}</h2>
          {/* {Object.keys(studios).map((key)=>(
            <Link
                to={"/studioSearch/"}
                className={`!text-base`}
                data-nc-id="CardAuthor2White"
            >{key}</Link>
            ))} */}

        </div>

        {/* ---- */}
        <SocialsList
          className="!space-x-3"
          itemClass="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
          facebook={artist.facebook}
          instagram={artist.instagram}
        />

        <p className="text-neutral-500 dark:text-neutral-400">
          {artist.bio}
        </p>

        {/* ---- */}
        <div className="border-b border-neutral-200 dark:border-neutral-700 w-14"></div>

        {/* ---- */}
        {/* <div className="border-b border-neutral-200 dark:border-neutral-700 w-14"></div> */}

        {/* ---- */}
        <div className="space-y-4">
          <div className="flex items-center space-x-4">
            <span className="text-neutral-6000 dark:text-neutral-300">
             {stylesString}
            </span>
          </div>
        </div>
        {showBookBtn ? <ButtonPrimary onClick={() => {window.location.href = "/artist/" + username + "/book"}} className="w-full">Book</ButtonPrimary> : null}

      </div>
    );
  };

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <Tab.Group>
            <Tab.List className="flex space-x-1 overflow-x-auto">
              {categories.map((item) => (
                <Tab key={item} as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${
                        selected
                          ? "bg-secondary-900 text-secondary-50 "
                          : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      } `}
                    >
                      {item}
                    </button>
                  )}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels className={'mt-3'}>
              <Tab.Panel className="">
                {photos.length > 0 ? <div>

                <div className="relative mt-4 grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
                  <div
                  className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
                  onClick={() => handleOpenModal(0)}
                  >
                  <NcImage
                  containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                  className="object-cover w-full h-full rounded-md sm:rounded-xl"
                  src={api_url + "/" + photos[0]}
                  />
                  <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
                  </div>
                {photos.filter((_, i) => i >= 1 && i < photos.length).map((item, index) => (
                  <div
                  key={index}
                  className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                  index >= 3 ? "hidden sm:block" : ""
                }`}
                  >
                  <NcImage
                  containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                  className="object-cover w-full h-full rounded-md sm:rounded-xl "
                  src={api_url + "/" + item}
                  />

                {/* OVERLAY */}
                  <div
                  className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                  onClick={() => handleOpenModal(index + 1)}
                  />
                  </div>
                  ))}

                  <div
                  className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
                  onClick={() => handleOpenModal(0)}
                  >
                  <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  >
                  <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                  />
                  </svg>
                  <span className="ml-2 text-neutral-800 text-sm font-medium">
                  Show all tattoos
                  </span>
                  </div>
                  </div>
                {/* MODAL PHOTOS */}
                  <ModalPhotos
                  imgs={photos}
                  isOpen={isOpen}
                  onClose={handleCloseModal}
                  initFocus={openFocusIndex}
                  uniqueClassName="nc-StudioProfilePage-modalPhotos"
                  />
                  </div> : <div className="ml-2 mt-2">No tattoos</div>}


              </Tab.Panel>
              <Tab.Panel className="">
                {studios.length > 0 ?
                     <ListStudiosCard studios={studios} />
               : <div className="ml-2 mt-2">No Studios</div>}
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-ArtistProfilePage ${className}`} data-nc-id="ArtistProfilePage">
        {requestLoading ? <LoadingSpinner /> : null}
      <Helmet>
        <title>Tattoohub || Booking your next tattoo</title>
      </Helmet>
      <main className="container mt-12 mb-24 lg:mb-32 flex flex-col lg:flex-row">
        <div className="block flex-grow mb-24 lg:mb-0">
          <div className="lg:sticky lg:top-24">{renderSidebar()}</div>
        </div>
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pl-10 flex-shrink-0">
          {renderSection1()}
        </div>
      </main>
    </div>
  );
};

export default ArtistProfilePage;
