import React, { useState } from "react";
import StatusBadge from "./StatusBadge";
import moment from "moment-timezone";
import ModalPhotos from "../StudioProfilePage/ModalPhotos";
import {api_url, defaultGalleryImage} from "../../contains/contants";
import SectionTattooContestHeader from "components/SectionHero/SectionTattooContestHeader"

const ContestMainInfo = (props: {
    id: number,
    name: string,
    status: string,
    startDate: number,
    endDate: number,
    imageUrl: string
}) => {

    const [isOpen, setIsOpen] = useState(false);
    const [openFocusIndex, setOpenFocusIndex] = useState(0);

    const handleOpenModal = (index: number) => {
        setIsOpen(true);
        setOpenFocusIndex(index);
    };
    const handleCloseModal = () => setIsOpen(false);

    return (
        <div>
            <>
           
                <header className="container 2xl:px-14 rounded-md sm:rounded-xl">
                    <div className="relative grid grid-cols-1 h-200 gap-1 sm:gap-2">
                    <SectionTattooContestHeader text="sdsd" image={props.imageUrl ? api_url + "/tattooContest/" + props.id + "/" + props.imageUrl : defaultGalleryImage}/>
                    </div>
                </header>
                
                {/* MODAL PHOTOS */}
                <ModalPhotos
                    imgs={[props.imageUrl ? "/tattooContest/" + props.id + "/" + props.imageUrl : defaultGalleryImage.replace(api_url, "")]}
                    isOpen={isOpen}
                    onClose={handleCloseModal}
                    initFocus={openFocusIndex}
                    uniqueClassName="nc-ListingCarDetailPage__modalPhotos"
                />
            </>
            <div className="flex items-center listingSection__wrap !space-y-30 container mt-5">
                {/* 1 */}
                <div className="flex justify-end items-center">
                    <StatusBadge status={props.status} />
                </div>

                {/* 2 */}
                <h4 className="text-neutral-6000 dark:text-neutral-300">
                    {/* {props.name} */}
                    <p>
                    Welcome to the {props.name}! Dive into a world of stunning tattoos and vote for your favorite masterpiece. Your vote helps crown the champion in this celebration of ink and artistry. Quick, easy, and impactful – let your voice be heard.
                     </p>
                </h4>

                {/* 5 */}
                <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

                {/* 6 */}
                <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
                  
                    <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
                    <p> Start - End date</p>
                        <i className="las la-calendar text-2xl"></i>
                        <span className="">{moment(props.startDate).format("DD MMM YYYY") + " - " + moment(props.endDate).format("DD MMM YYYY")}</span>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default ContestMainInfo;
