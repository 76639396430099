import React, {useState} from "react";
import {Link, useHistory} from "react-router-dom";
import "../../../styles/inviteScreens.css"
import Logo from "shared/Logo/Logo";
import ButtonPrimary from "../../../shared/Button/ButtonPrimary";
import {ErrorMessage, Form, Formik} from "formik";
import Input from "../../../shared/Input/Input";
import * as Yup from "yup";
import {login} from "../../../store/auth-actions";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux-hooks";
import {toast} from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import {captchaSiteKey} from "../../../contains/contants";
import LoadingSpinner from "../../../components/Loading/LoadingSpinner";

const InvitesLogin = () => {
    const dispatch=useAppDispatch();
    const navigate = useHistory()
    const validationSchema = Yup.object().shape({
        email: Yup.string().email().required("Email is required!"),
        password: Yup.string().required("Password is required!"),
    });
    const inviteToken = useAppSelector((state)=>state.invite.invitation.inviteToken);
    const studioUsername = useAppSelector((state)=>state.invite.studioUsername);
    const [requestLoading, setRequestLoading] = useState(false)


    const initialValues = {
        email: "",
        password: "",
    };

    const [captchaVisible, setCaptchaVisible] = useState(false);
    const [captchaChecked, setCaptchaChecked] = useState(false);

    const handleLogin = async (formValue: { email: string; password: string }) : Promise<void> => {
        if (captchaVisible && !captchaChecked){
            toast.warning("Please confirm captcha", {
                position: toast.POSITION.TOP_CENTER
            });
        }else{
            await setRequestLoading(true)
            let req: any = await dispatch(login(formValue.email, formValue.password, navigate, "/invites/" + studioUsername+ "/" + inviteToken));
            if (req && req.numberOfFails && req.numberOfFails > 3){
                await setCaptchaVisible(true)
            }
            await setRequestLoading(false)
        }

    };

    const studioName = useAppSelector((state)=>state.invite.studioName) || "Studio";

    return (
        <div className={"alignContentMiddleHorizontal"}>
            <div className={"half-width containerInvites"}>
                <div className={"alignContentMiddleHorizontal"}>
                    <Logo maxSize={"max-h-16"} />
                </div>
                <div className={"alignContentMiddleHorizontal"}>
                    <h1 className={"titleStyle"}>{studioName} invites you to join</h1>
                </div>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleLogin}

                >
                    {({ values, setFieldValue, handleChange, errors, touched }) => (
                        <Form className="grid grid-cols-1 gap-6">
                            <label className="block">
                                <span className="text-neutral-800 dark:text-neutral-200">
                                  Email address
                                </span>
                                <Input
                                    type="email"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    placeholder="example@example.com"
                                    className="mt-1"
                                />
                                <ErrorMessage name="email" component="div" className="text-red-500 text-xs italic"/>
                            </label>
                            <label className="block">
                                <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                                  Password
                                      <div onClick={() => {navigate.push("/forgot-pass", {email: values.email})}} className="text-sm hover:cursor-pointer">
                                        Forgot password?
                                      </div>
                                </span>
                                <Input
                                    value={values.password}
                                    type="password"
                                    className="mt-1"
                                    onChange={handleChange}
                                    name="password"/>
                                <ErrorMessage name="password" component="div" className="text-red-500 text-xs italic"/>
                            </label>
                            <ButtonPrimary type="submit" >Continue</ButtonPrimary>
                        </Form>
                    )}
                </Formik>
                {captchaVisible ? <div style={{
                    display: "flex",
                    placeContent: "center"
                }}><ReCAPTCHA
                    sitekey={captchaSiteKey}
                    onChange={() => {setCaptchaChecked(true)}}
                /></div> : null}
                {/* ==== */}
                <span className="block text-center text-neutral-700 dark:text-neutral-300">
                     New user? {` `}
                    <Link to="/invites-signup">Create an account</Link>
                </span>
                {requestLoading ? <LoadingSpinner /> : null}
            </div>
        </div>
    );
};

export default InvitesLogin;
