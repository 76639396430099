import {axiosReq} from "../store";

export async function fetchArtistProfilePageDetails(artistUsername: number, nav: any) {

    let artist;
    let studios;
    let portfolio;
    let artistStyles;
    let showBookBtn;
    try {
        let req = await axiosReq.get('/api/get-artist-profile-page?username=' + artistUsername);
        console.log(req);
        if (req.data.response === 'success'){
            artist = req.data.artist.artistData;
            studios = req.data.artist.studios;
            portfolio = req.data.artist.portfolio;
            artistStyles = req.data.artist.artistStyles;
            showBookBtn = req.data.artist.showBookBtn;
        }else if(req.data.response === 'noArtist'){
            nav.push('/page404')
        }

    }catch (e){
        console.log(e);
        nav.push('/page404')
    }

    return {artist, portfolio, studios, artistStyles, showBookBtn};
}
