import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import NcImage from "shared/NcImage/NcImage";
import {api_url} from "../../contains/contants";
import defaultAvatar from "../../images/avatars/default-avatar.png";

interface Artist {
  id: string | number,
  fullName: string,
  avatarUrl: string,
  location: string,
  bgImage?: string;
  username: string;
  href: string;
}

export interface CardAuthorBox2Props {
  className?: string;
  artist: Artist;
}

const CardArtistBox: FC<CardAuthorBox2Props> = ({
  className = "",
  artist,
}) => {

  const { id, fullName, href = "/artists/" + artist.username, avatarUrl, bgImage, location } = artist;
  return (
    <NavLink
      to={href}
      className={`nc-CardAuthorBox2 flex flex-col overflow-hidden [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] ${className}`}
      data-nc-id="CardAuthorBox2"
    >
      <div className="relative flex-shrink-0 ">
        <div>
          <NcImage
            containerClassName="flex aspect-w-7 aspect-h-5 sm:aspect-h-6 w-full h-0"
            src={bgImage ? api_url + '/' + bgImage : undefined}
          />
        </div>
        <div className="absolute top-3 inset-x-3 flex">
          <div className=" py-1 px-4 bg-neutral-100 dark:bg-neutral-800 rounded-full flex items-center justify-center leading-none text-xs font-medium">
            {/* {convertNumbThousand(count)}{" "} */}
            {/* <ArrowRightIcon className="w-5 h-5 text-yellow-600 ml-3" /> */}
          </div>
        </div>
      </div>

      <div className="-mt-8 m-8 text-center">
        <Avatar
          containerClassName="ring-2 ring-white"
          sizeClass="w-16 h-16 text-2xl"
          radius="rounded-full"
          imgUrl={avatarUrl ? api_url + avatarUrl : defaultAvatar}
          userName={fullName}
        />
        <div className="mt-3">
          <h2 className={`text-base font-medium`}>
            <span className="line-clamp-1">{fullName}</span>
          </h2>
          <span
            className={`block mt-1 text-sm text-neutral-500 dark:text-neutral-400`}
          >

            {location ? <div className="flex mt-1 items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1.5}
                      d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                  />
                  <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1.5}
                      d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                  />
              </svg>
              <span className="">{location}</span>
          </div> : null}
          </span>
        </div>
      </div>
    </NavLink>
  );
};

export default CardArtistBox;
