import {axiosReq} from "../store";

export default{
    async sendEmail(emailAddress: string) {

        try {
            let req = await axiosReq.post('/api/forgot-pass',{
                emailAddress: emailAddress,
            });


            return {response: req.data.response};

        }catch (e){
            return {response: "error"};
        }
    },
    async changePassword(password: string, confirmPassword: string, token: string) {


        try {
            let req = await axiosReq.post('/api/change-forgot-pass',{
                password: password,
                confirmPassword: confirmPassword,
                token: token
            });


            return {response: req.data.response};

        }catch (e){
            return {response: "error"};
        }
    },

}
