import React, { useState, useEffect } from "react";
import contests from "../../services/contests";
import NcImage from "../../shared/NcImage/NcImage";
import {api_url} from "../../contains/contants";
import ModalPhotos from "../StudioProfilePage/ModalPhotos";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";


const StatusFinished = (props: {id: number}) => {
    const [requiredLoading, setRequestLoading] = useState(false)
    const [winnerImages, setWinnerImages] = useState<any>([]);
    const [rankings, setRankings] = useState<any>({});
    useEffect(() => {
        (async () => {
            await setRequestLoading(true);
            let req:any = await contests.getDataFinishedContest(props.id);
            await setRankings(req.rankings);
            await setWinnerImages(req.winnerImages);
            await setRequestLoading(false);
        })()
    }, []);


    const [isOpen, setIsOpen] = useState(false);
    const [openFocusIndex, setOpenFocusIndex] = useState(0);

    const handleOpenModal = (index: number) => {
        setIsOpen(true);
        setOpenFocusIndex(index);
    };

    const handleCloseModal = () => setIsOpen(false);



    return (
        <main className="container relative z-10 mt-11 flex flex-col lg:flex-row ">
            {requiredLoading ? <LoadingSpinner /> : null}
            {/* CONTENT */}
            <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:pr-10 lg:space-y-10">
                {winnerImages ? <div className={"flex"}>
                    {winnerImages.map((item: any, index: number) => {
                        return (
                            <div className={"mr-4 ml-4"} key={item.artistUsername}>
                                <header className="rounded-md sm:rounded-xl" >
                                    <div>
                                        <div
                                            className="col-span-2 row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
                                            onClick={() => handleOpenModal(index)}
                                        >
                                            <NcImage
                                                style={{
                                                    width: "100%",
                                                    height: "300px"
                                                }}
                                                className="object-cover rounded-md sm:rounded-xl "
                                                src={api_url + "/contestTattoos/" + props.id + "/thumbs/" + item.imageUrl}
                                            />
                                            <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
                                        </div>
                                    </div>
                                    <div className={"flex justify-center mt-3 mb-3"}>
                                        <i className="las la-user text-2xl"></i>
                                        <span>Votes: {item.votes}</span>
                                    </div>
                                </header>
                                {/* MODAL PHOTOS */}


                            </div>
                        )
                    })}
                    <ModalPhotos
                        imgs={winnerImages.map((item: any) => {return "/contestTattoos/" + props.id + "/" + item.imageUrl})}
                        isOpen={isOpen}
                        onClose={handleCloseModal}
                        initFocus={openFocusIndex}
                        uniqueClassName="nc-ListingCarDetailPage__modalPhotos"
                    />
                </div> : null}
            </div>

            {/* SIDEBAR */}
            <div className="block flex-grow mt-14 lg:mt-0">
                {winnerImages.length > 0 ?  <div className="listingSection__wrap lg:shadow-xl">
                    <span className="text-2xl font-semibold block">
                      Winners
                    </span>
                    <div className="mt-8 flex">
                        <div className="ml-4 space-y-14 text-sm">
                            {winnerImages[0] ? <div className="flex space-y-2">
                                <i className="las la-trophy text-3xl text-yellow-300"></i>
                                <span className=" font-semibold ml-2 text-xl">
                                    #1 {winnerImages[0].artistUsername + " - " + winnerImages[0].votes + " votes"}
                                </span>
                            </div> : null}

                            {winnerImages[1] ? <div className="flex space-y-2">
                                <i className="las la-medal text-3xl text-gray-500"></i>
                                <span className=" font-semibold ml-2 text-xl">
                                    #2 {winnerImages[1].artistUsername + " - " + winnerImages[1].votes + " votes"}
                                </span>
                            </div> : null}

                            {winnerImages[2] ? <div className="flex space-y-2">
                                <i className="las la-medal text-3xl text-amber-900"></i>
                                <span className=" font-semibold ml-2 text-xl">
                                    #3 {winnerImages[2].artistUsername + " - " + winnerImages[2].votes + " votes"}
                                </span>
                            </div> : null}


                            {rankings ? <div>
                                {Object.keys(rankings).map((key:any, index:number) => (
                                    <div className="flex space-y-2" key={index}>
                                        <span className=" w-full font-semibold ml-2 text-xl">
                                            #{index + 4} {rankings[key].username + " - " + rankings[key].votes + " votes"}
                                        </span>
                                    </div>

                                ))}
                            </div> : null}
                        </div>
                    </div>
                </div> : null}

            </div>
        </main>


    );
};

export default StatusFinished;
