import {setArtist, setIsTattooArtist} from './auth-slice'
import { AnyAction } from '@reduxjs/toolkit'
import { ThunkAction } from '@reduxjs/toolkit'
import {RootState} from './index'
import {Artist, User} from "../models/auth";
import {createArtist as createArtistRequest} from "../services/becomeArtist";
import {BecomeArtistModel} from "../models/becomeArtist";
import {toast} from "react-toastify";
import {setStepCompleted} from "./become-artist-slice";
import {becomeArtistStepsLength} from "../contains/contants";
import {becomeArtistFlowCleanState} from "./become-artist-slice";
export const createArtist=(images: any[], navigation:any):ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async(dispatch,getState)=>{
        let becomeArtistData:BecomeArtistModel = getState().becomeArtist;
        let user:User = getState().auth.user;
        let token:string = getState().auth.token;
        const response:{data: Artist, response: string, showCreateStudioBtn?:boolean} = await createArtistRequest(images, becomeArtistData, user.id.toString(), token);

        if (response.response === "success"){
            dispatch(setArtist(response.data));
            dispatch(setIsTattooArtist(true));
            dispatch(setStepCompleted(becomeArtistStepsLength))
            toast.success("Artist profile created successfully", {
                position: toast.POSITION.TOP_CENTER
            });
            navigation.push('/become-artist-preview', {
                styles: response.data.styles,
                username: response.data.username,
                bio: response.data.bio,
                portfolio: response.data.portfolio,
                showCreateStudioBtn: response.showCreateStudioBtn,
            });
        }else if(response.response === "profileAlreadyExist"){
            toast.error("Profile is already created.", {
                position: toast.POSITION.TOP_CENTER
            });
        }else{
            toast.error("Error while trying to creat artist profile", {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }
}
export const becomeArtistCleanState=():ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async(dispatch,getState)=>{
        dispatch(becomeArtistFlowCleanState())
    }
}
