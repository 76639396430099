import {axiosReq} from "../store";

export async function artistsSearch (city: string, country: string, styles: string) {

    let artists = [];

    try {
        let req = await axiosReq.get('/api/artistsSearch?city=' + city + '&country=' + country + '&styles=' + styles);

        artists = req.data.artists;


    }catch (e){
        console.log(e)
    }

    return {artists};
}

