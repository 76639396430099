import {CreateStudio, TelCode, Location, LocalSettings} from "../models/createStudio";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {bookingHours} from "../contains/contants";
import moment from "moment-timezone";
let zones = moment.tz.names();

let timeZones = zones.map(function (obj){
    return {
        value: obj,
        label: obj + " (GMT " + moment.tz(obj).format('Z') + ")"
    }
})

const initialState = {
    plan: '',
    studioName: '',
    username: '',
    location: {},
    phone: '',
    telCode: {},
    email: '',
    facebook: '',
    instagram: '',
    webSiteURL: '',
    timeFormat: {value: "12", label: "12-hour clock (AM/PM)" },
    language: {value: "English", label: "English" },
    currency: { value: "USD", label: "America (United States) Dollars – USD" },
    timeZone: timeZones.filter(function (obj){return obj.value === moment.tz.guess()})[0],
    bookingHours: bookingHours,
    stepCompleted: 0,
} as CreateStudio;
export const becomeArtistSlice=createSlice({
    name:'createStudio',
    initialState,
    reducers:{
        setPlan: (state,action:PayloadAction<string>) =>{
            state.plan = action.payload;
        },
        setStudioName: (state,action:PayloadAction<string>) =>{
            state.studioName = action.payload;
        },
        setUsername: (state,action:PayloadAction<string>) =>{
            state.username = action.payload;
        },
        setPhone: (state,action:PayloadAction<string>) =>{
            state.phone = action.payload;
        },
        setLocation: (state,action:PayloadAction<Location>) =>{
            state.location = action.payload;
        },
        setTelCode: (state,action:PayloadAction<TelCode>) =>{
            state.telCode = action.payload;
        },
        setEmail: (state,action:PayloadAction<string>) =>{
            state.email = action.payload;
        },
        setWebSiteURL: (state,action:PayloadAction<string>) =>{
            state.webSiteURL = action.payload;
        },
        setFacebook: (state,action:PayloadAction<string>) =>{
            state.facebook = action.payload;
        },
        setInstagram: (state,action:PayloadAction<string>) =>{
            state.instagram = action.payload;
        },
        setTimeFormat: (state,action:PayloadAction<LocalSettings>) =>{
            state.timeFormat = action.payload;
        },
        setLanguage: (state,action:PayloadAction<LocalSettings>) =>{
            state.language = action.payload;
        },
        setCurrency: (state,action:PayloadAction<LocalSettings>) =>{
            state.currency = action.payload;
        },
        setTimeZone: (state,action:PayloadAction<LocalSettings>) =>{
            state.timeZone = action.payload;
        },
        setBookingHours: (state,action:PayloadAction<any>) =>{
            state.bookingHours = action.payload;
        },
        setStepCompleted: (state,action:PayloadAction<number>) =>{
            state.stepCompleted = action.payload;
        },
        createStudioFlowCleanState: (state,action:PayloadAction<void>) =>{
            state.plan = initialState.plan;
            state.studioName = initialState.studioName;
            state.username = initialState.username;
            state.location = initialState.location;
            state.phone = initialState.phone;
            state.telCode = initialState.telCode;
            state.email = initialState.email;
            state.facebook = initialState.facebook;
            state.instagram = initialState.instagram;
            state.webSiteURL = initialState.webSiteURL;
            state.timeFormat = initialState.timeFormat;
            state.language = initialState.language;
            state.currency = initialState.currency;
            state.timeZone = initialState.timeZone;
            state.bookingHours = initialState.bookingHours;
            state.stepCompleted = initialState.stepCompleted;
        },
    }
})
export const {setPlan, createStudioFlowCleanState, setStudioName, setUsername, setLocation, setPhone, setTelCode, setEmail, setWebSiteURL, setFacebook, setInstagram, setTimeFormat, setTimeZone, setLanguage, setCurrency, setBookingHours, setStepCompleted} = becomeArtistSlice.actions
export default becomeArtistSlice.reducer;
