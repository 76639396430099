import React, {useEffect} from "react";
import Header from "components/Header/Header";
import HeaderBusiness from "shared/Header/HeaderBusiness";
import { useAppSelector } from "../hooks/redux-hooks";

const SiteHeader = () => {
  //const initialValueisLoggedIn: boolean = false;
  //const [isLoggedIn, setIsLoggedIn] = useState(initialValueisLoggedIn);
  const isLoggedIn = useAppSelector((state)=>state.auth.isLoggedIn);

  useEffect(() => {
    // var user = localStorage.getItem("user");
    // if(user !== null){
    //   setIsLoggedIn(true);
    // }

  }, [isLoggedIn])

  return isLoggedIn === true ? (
    < Header/>
  ) : (
    <HeaderBusiness />
  );
};

export default SiteHeader;
