import React, {FC, useEffect, useState} from "react";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, useHistory  } from "react-router-dom";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useAppDispatch,useAppSelector } from "../../hooks/redux-hooks";
import {register} from '../../store/auth-actions';
import GoogleLoginBtn from "../../components/Google/GoogleLogin";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";

export interface PageSignUpProps {
  className?: string;
}
const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const navigate = useHistory();
  const dispatch=useAppDispatch();
  const isLoggedIn = useAppSelector((state)=>state.auth.isLoggedIn);

  useEffect(() => {
    if(isLoggedIn === true){
      navigate.push("/dashboard")
    }
  }, []);

  const [requestLoading, setRequestLoading] = useState(false)

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required("Email address is required!"),
    password: Yup.string().min(8, "New password must be at least 8 characters").required("Password is required!"),
    name: Yup.string().required("Name is required!"),
    confirmPassword: Yup.string().min(8, "New password must be at least 8 characters")
    .required("Confirm password is required!")
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });

  const handleRegister = async (formValue: { name: string, email: string; password: string, confirmPassword: string }) : Promise<void> => {
    await setRequestLoading(true)
    await dispatch(register(formValue.name, formValue.email, formValue.password, formValue.confirmPassword, navigate, "/dashboard"));
    await setRequestLoading(false)
  };

  const initialValues = {
    name: "",
    email: "",
    password: "",
    confirmPassword: ""
  };
  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Sign up || Tattoohub</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Signup
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">
          <GoogleLoginBtn />

          {/* <div className="grid gap-3">*/}
          {/*  {loginSocials.map((item, index) => (*/}
          {/*    <a*/}
          {/*      key={index}*/}
          {/*      href={item.href}*/}
          {/*      className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"*/}
          {/*    >*/}
          {/*      <img*/}
          {/*        className="flex-shrink-0"*/}
          {/*        src={item.icon}*/}
          {/*        alt={item.name}*/}
          {/*      />*/}
          {/*      <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">*/}
          {/*        {item.name}*/}
          {/*      </h3>*/}
          {/*    </a>*/}
          {/*  ))}*/}
          {/*</div>*/}
          {/* OR */}
          {/* <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div> */}
          {/* FORM */}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleRegister}
          >
          {({ values, setFieldValue, handleChange, errors, touched }) => (
          <Form className="grid grid-cols-1 gap-6">
              <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Name
              </span>
              <Input
              type="text"
                name="name"
                value={values.name}
                onChange={handleChange}
              className="mt-1" />
              <ErrorMessage name="name" component="div" className="text-red-500 text-xs italic"/>
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                placeholder="example@example.com"
                className="mt-1"
              />
              <ErrorMessage name="email" component="div" className="text-red-500 text-xs italic"/>
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
              </span>
              <Input
              type="password"
              name="password"
              value={values.password}
              onChange={handleChange}
              className="mt-1" />
              <ErrorMessage name="password" component="div" className="text-red-500 text-xs italic"/>
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Confirm Password
              </span>
              <Input
              type="password"
              name="confirmPassword"
              value={values.confirmPassword}
              onChange={handleChange}
              className="mt-1" />
               <ErrorMessage name="confirmPassword" component="div" className="text-red-500 text-xs italic"/>
            </label>
            <ButtonPrimary type="submit">Continue</ButtonPrimary>
            </Form>
             )}
          </Formik>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Already have an account? {` `}
            <Link to="/login">Sign in</Link>
          </span>
        </div>
      </div>
      {requestLoading ? <LoadingSpinner /> : null}
    </div>
  );
};

export default PageSignUp;
