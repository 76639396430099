import {configureStore, combineReducers} from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { encryptTransform  }  from 'redux-persist-transform-encrypt';
import { persistReducer, persistStore} from 'redux-persist';
import authSlice from './auth-slice'
import studioSlice from './studio-slice'
import becomeArtistSlice from './become-artist-slice'
import createStudio from './create-studio-slice'
import inviteSlice from "./invite-slice";
import axios, {AxiosInstance} from 'axios';
import {api_url, privateRoutes} from "../contains/contants";

const instance: AxiosInstance = axios.create({
    baseURL: api_url,
    headers: {
        'Content-Type': 'application/json',
    },
});

instance.interceptors.request.use(
    (config:any) => {
        let splitUrl:string[] = config.url.split("/");
        let url = splitUrl[splitUrl.length - 1];
        if (url.includes("?")) {
            url = url.split("?")[0];
        }

        // Check if the request URL is one of the private routes
        if (privateRoutes.includes("/" + url)) {
            // Add headers for requests not on excluded routes
            config.headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${config.params.token}`,
            }
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
const encryptor = encryptTransform ({
    secretKey: 'Oz9Qm5svbo%ozSLpri@^tfFA30idHu7cHAn%$IgNYMpFo0rmcePaTDT4oP%$gJ8zRfo&j8tW8$N*$uF!Dt9o%KMny2hLj%zX7vt'
  });

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
    transforms: [encryptor]
  }

  const rootReducer = combineReducers({
    auth: authSlice,
    studio: studioSlice,
    becomeArtist: becomeArtistSlice,
    createStudio: createStudio,
    invite: inviteSlice
  })

  const persistedReducer = persistReducer(persistConfig, rootReducer)

const store=configureStore(
    {
        reducer:persistedReducer,
        devTools: process.env.NODE_ENV !== 'production',
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                immutableCheck: false, //TODO:
                serializableCheck: false
            // serializableCheck: {
            //     ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            // },
    }),
    }
)


export const axiosReq:AxiosInstance = instance;
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch =typeof store.dispatch
export const persistor = persistStore(store)
export default store;
