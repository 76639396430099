import React from "react";
import {useHistory} from "react-router-dom";
import Plans from "../../components/Plans/Plans";
import {useAppDispatch, useAppSelector} from "../../hooks/redux-hooks";
import {setPlan, setStepCompleted} from "../../store/create-studio-slice";

const CreateStudioSelectPlan = () => {

    const navigation = useHistory();
    const dispatch = useAppDispatch();
    const currentStep = 1;
    const stepCompleted = useAppSelector((state)=>state.createStudio.stepCompleted);

    return (
        <div style={{
            margin: 50
        }}>
            <Plans onSelect={async(plan: string) => {
                await dispatch(setPlan(plan));
                if (stepCompleted < currentStep){
                    await dispatch(setStepCompleted(currentStep))
                }
                navigation.push("/create-studio-listing-1")
            }} />
        </div>

    );
};

export default CreateStudioSelectPlan;
