import {axiosReq} from "../store";
import {Artist} from "../models/auth";
import {BecomeArtistModel} from "../models/becomeArtist";

export async function createArtist(images:any[], becomeArtistData: BecomeArtistModel, userId: string, token: string) {
    const artistModel: Artist = {
        id: -1,
        username: "",
        bio: "",
        styles:[],
        portfolio: [],
        avatarUrl: "",
    }
    try {

        let formData = new FormData();
        formData.append('userId', userId);
        formData.append('username', becomeArtistData.username);
        formData.append('bio', becomeArtistData.bio);
        formData.append('styles', JSON.stringify(becomeArtistData.styles));
        formData.append('facebook', becomeArtistData.facebook)
        formData.append('instagram',becomeArtistData.instagram)

        for (let i = 0; i < images.length; i++){
            formData.append('images', images[i])
        }

        let req = await axiosReq.post('/api/create-artist',formData, {params:{token: token}});

        if(req.data.response === 'success'){
            artistModel.id = req.data.artistId;
            artistModel.username = becomeArtistData.username;
            artistModel.bio = becomeArtistData.bio;
            artistModel.styles = becomeArtistData.styles;
            artistModel.portfolio = req.data.portfolio;
        }
        return {data: artistModel, response: req.data.response};

    }catch (e){
        return {data: artistModel, response: "error"};
    }

}

export async function checkIfUsernameExist (username: any, token: string) {
    try {

        let req = await axiosReq.post('/api/is-artist-username-exist', {
          username: username
        }, {params: {token: token}});

        return {response: 'success', isExist: req.data.isExist}

    }catch (e){
        return {response: 'error'}
    }
}
