import React, { FC } from "react";
import Heading from "components/Heading/Heading";

export interface Statistic {
  id: string;
  heading: string;
  subHeading: string;
  subHeading2: string;
}

const FOUNDER_DEMO: Statistic[] = [
  {
    id: "1",
    heading: "Grand Prize Winner",
    subHeading:
    "Your winning tattoo will take center stage on our social media account, introducing your artistry to our vast community of over 370k followers.",
      subHeading2:
      "Revel in a 3-month subscription to our premium Studio Pro plan, valued at $59.95/month. Experience the pinnacle of studio management with our best features.",
  },
  {
    id: "2",
    heading: "First Runner-Up",
    subHeading: "Your exceptional work will be spotlighted on our social media platform, showcasing your talent to our 370k+ dedicated followers.",
    subHeading2:
    "Enjoy a 2-month subscription to our Studio Pro plan. Elevate your studio's operations with our top-tier features",
  },
  {
    id: "3",
    heading: "Second Runner-Up",
    subHeading:
      "Get recognized as we highlight your impressive tattoo on our social media account, connecting you with a vast audience of tattoo enthusiasts",
      subHeading2:
      "Dive into a 1-month subscription of our Artist Pro plan, valued at $9.95/month. Streamline your bookings and enhance your client management skills.",
  },
];

export interface SectionStatisticProps {
  className?: string;
}

const SectionAwards: FC<SectionStatisticProps> = ({ className = "" }) => {
  return (
    <div className={`nc-SectionStatistic relative ${className}`}>
      <Heading
        desc=" Ink Excellence, Earn Exceptional Prizes"
      >
        🏅 Prizes & Recognition
      </Heading>
      <div className="grid md:grid-cols-2 gap-6 lg:grid-cols-3 xl:gap-8">
        {FOUNDER_DEMO.map((item) => (
          <div
            key={item.id}
            className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800"
          >
            <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
              {item.heading}
            </h3>
            <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
            ⭐{item.subHeading}
            </span>
            <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
            ⭐{item.subHeading2}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionAwards;
