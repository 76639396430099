import { SocialType } from "shared/SocialsShare/SocialsShare";
import React, { FC } from "react";

export interface SocialsListProps {
  className?: string;
  itemClass?: string;
  socials?: SocialType[];
  facebook?: string;
  instagram?: string;
}

// const socialsDemo: SocialType[] = [
//   { name: "Facebook", icon: "lab la-facebook-square", href: "#" },
//   { name: "Instagram", icon: "lab la-instagram", href: "#" },
// ];

const SocialsList: FC<SocialsListProps> = ({
  className = "",
  itemClass = "block",
  facebook,
  instagram
}) => {
  return (
    <nav
      className={`nc-SocialsList flex space-x-2.5 text-2xl text-neutral-6000 dark:text-neutral-300 ${className}`}
      data-nc-id="SocialsList"
    >
      {instagram ? <a
          key="1"
          className={`${itemClass}`}
          href={instagram}
          target="_blank"
          rel="noopener noreferrer"
          title="Instagram"
        >
          <i className="lab la-instagram"></i>
        </a> : null }
        {facebook ? <a
          key="2"
          className={`${itemClass}`}
          href={facebook}
          target="_blank"
          rel="noopener noreferrer"
          title="Facebook"
        >
          <i className="lab la-facebook-square"></i>
        </a>: null }
      {/* {socials.map((item, i) => (

      ))} */}
    </nav>
  );
};

export default SocialsList;
