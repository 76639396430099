import { TaxonomyType } from "data/types";
import realism from "../images/styles/realism.jpg";
import blackAndGray from "../images/styles/blackandgrey.jpg";
import blackwork from "../images/styles/blackwork.jpg";
import chicano from "../images/styles/chicano.jpg";
import dotwork from "../images/styles/dotwork.jpg";
import fineline from "../images/styles/fine-line.jpg";
import geometrix from "../images/styles/geometrix.jpg";
import horror from "../images/styles/horror.jpg";
import illustrative from "../images/styles/illustrative.jpeg";
import japanese from "../images/styles/japanese.jpg";
import lettering from "../images/styles/lettering.jpeg";
import neotraditional from "../images/styles/neo-traditional.jpg";
import newschool from "../images/styles/newschool.jpg";
import oldschool from "../images/styles/oldschool.jpg";
import ornamental from "../images/styles/ornamental.jpeg";
import surrealism from "../images/styles/surrealism.jpg";
import trashpolka from "../images/styles/trashpolka.jpg";
import tribal from "../images/styles/tribal.jpg";
import watercolor from "../images/styles/watercolor.jpg";

const stylesList: TaxonomyType[] = [
  {
    id: "16",
    href: "/listing-stay",
    name: "Realism",
    taxonomy: "category",
    count: 2118,
    thumbnail: realism
  },
  {
    id: "1",
    href: "/listing-stay",
    name: "Black & Gray",
    taxonomy: "category",
    count: 36612,
    thumbnail: blackAndGray,
  },
  {
    id: "10",
    href: "/listing-stay",
    name: "Japanese",
    taxonomy: "category",
    count: 188288,
    thumbnail: japanese,
  },
  {
    id: "13",
    href: "/listing-stay",
    name: "New School",
    taxonomy: "category",
    count: 188288,
    thumbnail: newschool,
  },
  {
    id: "14",
    href: "/listing-stay",
    name: "Old School",
    taxonomy: "category",
    count: 188288,
    thumbnail: oldschool,
  },
  {
    id: "2",
    href: "/listing-stay",
    name: "Blackwork",
    taxonomy: "category",
    count: 188288,
    thumbnail: blackwork,
  },
  {
    id: "3",
    href: "/listing-stay",
    name: "Chicano",
    taxonomy: "category",
    count: 188288,
    thumbnail: chicano,
  },
  {
    id: "4",
    href: "/listing-stay",
    name: "Dotwork",
    taxonomy: "category",
    count: 188288,
    thumbnail: dotwork,
  },
  {
    id: "5",
    href: "/listing-stay",
    name: "Fine-line",
    taxonomy: "category",
    count: 188288,
    thumbnail: fineline,
  },
  {
    id: "6",
    href: "/listing-stay",
    name: "Geometric",
    taxonomy: "category",
    count: 188288,
    thumbnail: geometrix,
  },
  {
    id: "8",
    href: "/listing-stay",
    name: "Horror",
    taxonomy: "category",
    count: 188288,
    thumbnail: horror,
  },
  {
    id: "9",
    href: "/listing-stay",
    name: "Illustrative",
    taxonomy: "category",
    count: 188288,
    thumbnail: illustrative,
  },
  {
    id: "11",
    href: "/listing-stay",
    name: "Lettering",
    taxonomy: "category",
    count: 188288,
    thumbnail: lettering,
  },
  {
    id: "12",
    href: "/listing-stay",
    name: "Neo-Traditional",
    taxonomy: "category",
    count: 188288,
    thumbnail: neotraditional,
  },
  {
    id: "15",
    href: "/listing-stay",
    name: "Ornamental",
    taxonomy: "category",
    count: 188288,
    thumbnail: ornamental,
  },
  {
    id: "17",
    href: "/listing-stay",
    name: "Surrealism",
    taxonomy: "category",
    count: 188288,
    thumbnail: surrealism,
  },
  {
    id: "18",
    href: "/listing-stay",
    name: "Trash Polka",
    taxonomy: "category",
    count: 188288,
    thumbnail: trashpolka,
  },
  {
    id: "19",
    href: "/listing-stay",
    name: "Tribal",
    taxonomy: "category",
    count: 188288,
    thumbnail: tribal,
  },
  {
    id: "20",
    href: "/listing-stay",
    name: "Watercolor",
    taxonomy: "category",
    count: 188288,
    thumbnail: watercolor,
  },
];

const avatarColors = [
  "#ffdd00",
  "#fbb034",
  "#ff4c4c",
  "#c1d82f",
  "#f48924",
  "#7ac143",
  "#30c39e",
  "#06BCAE",
  "#0695BC",
  "#037ef3",
  "#146eb4",
  "#8e43e7",
  "#ea1d5d",
  "#fc636b",
  "#ff6319",
  "#e01f3d",
  "#a0ac48",
  "#00d1b2",
  "#472f92",
  "#388ed1",
  "#a6192e",
  "#4a8594",
  "#7B9FAB",
  "#1393BD",
  "#5E13BD",
  "#E208A7",
];

// const debug = false;
const api_url = process.env.REACT_APP_API_URL || 'https://tattoohubapp.com';
const googleMapsAPI = 'AIzaSyDtNA4DuhoQa6GiwDhCEdHkrfYl7qrojBI';

const styles = [
  {
    id: "0",
    href: "/listing-stay",
    name: "All",
    taxonomy: "category",
    count: 17288,
    thumbnail: "https://images.unsplash.com/photo-1570961999607-df226979f156?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8YWxsfGVufDB8fDB8fA%3D%3D&w=1000&q=80"
  },
  {
    id: "1",
    href: "/listing-stay",
    name: "Black & Gray",
    taxonomy: "category",
    count: 2118,
    thumbnail:
      "https://images.pexels.com/photos/2351649/pexels-photo-2351649.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "2",
    href: "/listing-stay",
    name: "Blackwork",
    taxonomy: "category",
    count: 36612,
    thumbnail:
      "https://images.pexels.com/photos/962464/pexels-photo-962464.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "3",
    href: "/listing-stay",
    name: "Chicano",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/248837/pexels-photo-248837.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "4",
    href: "/listing-stay",
    name: "Dotwork",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/3613236/pexels-photo-3613236.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  },
  {
    id: "5",
    href: "/listing-stay",
    name: "Fine - line",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/3613236/pexels-photo-3613236.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  },
];

let domain = '.tattoohubs.com';
let facebookURL = "https://facebook.com/";
let instagramURL = 'https://instagram.com/';

let currency = [
  { value: "USD", label: "America (United States) Dollars – USD" },
  { value: "EUR", label: "Euro – EUR" },
  { value: "AFN", label: "Afghanistan Afghanis – AFN" },
  { value: "ALL", label: "Albania Leke – ALL" },
  { value: "DZD", label: "Algeria Dinars – DZD" },
  { value: "ARS", label: "Argentina Pesos – ARS" },
  { value: "AUD", label: "Australia Dollars – AUD" },
  { value: "ATS", label: "Austria Schillings – ATS" },
  { value: "BSD", label: "Bahamas Dollars – BSD" },
  { value: "BHD", label: "Bahrain Dinars – BHD" },
  { value: "BDT", label: "Bangladesh Taka – BDT" },
  { value: "BBD", label: "Barbados Dollars – BBD" },
  { value: "BEF", label: "Belgium Francs – BEF" },
  { value: "BMD", label: "Bermuda Dollars – BMD" },
  { value: "BRL", label: "Brazil Reais – BRL" },
  { value: "BGN", label: "Bulgaria Leva – BGN" },
  { value: "CAD", label: "Canada Dollars – CAD" },
  { value: "XOF", label: "CFA BCEAO Francs – XOF" },
  { value: "XAF", label: "CFA BEAC Francs – XAF" },
  { value: "CLP", label: "Chile Pesos – CLP" },
  { value: "CNY", label: "China Yuan Renminbi – CNY" },
  { value: "COP", label: "Colombia Pesos – COP" },
  { value: "XPF", label: "CFP Francs – XPF" },
  { value: "CRC", label: "Costa Rica Colones – CRC" },
  { value: "HRK", label: "Croatia Kuna – HRK" },
  { value: "CYP", label: "Cyprus Pounds – CYP" },
  { value: "CZK", label: "Czech Republic Koruny – CZK" },
  { value: "DKK", label: "Denmark Kroner – DKK" },
  { value: "DEM", label: "Deutsche (Germany) Marks – DEM" },
  { value: "DOP", label: "Dominican Republic Pesos – DOP" },
  { value: "NLG", label: "Dutch (Netherlands) Guilders – NLG" },
  { value: "XCD", label: "Eastern Caribbean Dollars – XCD" },
  { value: "EGP", label: "Egypt Pounds – EGP" },
  { value: "EEK", label: "Estonia Krooni – EEK" },
  { value: "FJD", label: "Fiji Dollars – FJD" },
  { value: "FIM", label: "Finland Markkaa – FIM" },
  { value: "FRF*", label: "France Francs – FRF" },
  { value: "XAU", label: "Gold Ounces – XAU" },
  { value: "GRD", label: "Greece Drachmae – GRD" },
  { value: "GTQ", label: "Guatemalan Quetzal – GTQ" },
  { value: "HKD", label: "Hong Kong Dollars – HKD" },
  { value: "HUF", label: "Hungary Forint – HUF" },
  { value: "ISK", label: "Iceland Kronur – ISK" },
  { value: "XDR", label: "IMF Special Drawing Right – XDR" },
  { value: "INR", label: "India Rupees – INR" },
  { value: "IDR", label: "Indonesia Rupiahs – IDR" },
  { value: "IRR", label: "Iran Rials – IRR" },
  { value: "IQD", label: "Iraq Dinars – IQD" },
  { value: "IEP", label: "Ireland Pounds – IEP*" },
  { value: "ILS", label: "Israel New Shekels – ILS" },
  { value: "ITL", label: "Italy Lire – ITL*" },
  { value: "JMD", label: "Jamaica D" },
  { value: "JPY", label: "Japan Yen – JPY" },
  { value: "JOD", label: "Jordan Dinars – JOD" },
  { value: "KES", label: "Kenya Shillings – KES" },
  { value: "KRW", label: "Korea (South) Won – KRW" },
  { value: "KWD", label: "Kuwait Dinars – KWD" },
  { value: "LBP", label: "Lebanon Pounds – LBP" },
  { value: "LUF", label: "Luxembourg Francs – LUF" },
  { value: "MYR", label: "Malaysia Ringgits – MYR" },
  { value: "MTL", label: "Malta Liri – MTL" },
  { value: "MUR", label: "Mauritius Rupees – MUR" },
  { value: "MXN", label: "Mexico Pesos – MXN" },
  { value: "MAD", label: "Morocco Dirhams – MAD" },
  { value: "NZD", label: "New Zealand Dollars – NZD" },
  { value: "NOK", label: "Norway Kroner – NOK" },
  { value: "OMR", label: "Oman Rials – OMR" },
  { value: "PKR", label: "Pakistan Rupees – PKR" },
  { value: "XPD", label: "Palladium Ounces – XPD" },
  { value: "PEN", label: "Peru Nuevos Soles – PEN" },
  { value: "PHP", label: "Philippines Pesos – PHP" },
  { value: "XPT", label: "Platinum Ounces – XPT" },
  { value: "PLN", label: "Poland Zlotych – PLN" },
  { value: "PTE", label: "Portugal Escudos – PTE" },
  { value: "QAR", label: "Qatar Riyals – QAR" },
  { value: "RON", label: "Romania New Lei – RON" },
  { value: "ROL", label: "Romania Lei – ROL" },
  { value: "RUB", label: "Russia Rubles – RUB" },
  { value: "SAR", label: "Saudi Arabia Riyals – SAR" },
  { value: "XAG", label: "Silver Ounces – XAG" },
  { value: "SGD", label: "Singapore Dollars – SGD" },
  { value: "SKK", label: "Slovakia Koruny – SKK" },
  { value: "SIT", label: "Slovenia Tolars – SIT" },
  { value: "ZAR", label: "South Africa Rand – ZAR" },
  { value: "ESP", label: "Spain Pesetas – ESP" },
  { value: "LKR", label: "Sri Lanka Rupees – LKR" },
  { value: "SDD", label: "Sudan Dinars – SDD" },
  { value: "SEK", label: "Sweden Kronor – SEK" },
  { value: "CHF", label: "Switzerland Francs – CHF" },
  { value: "TWD", label: "Taiwan New Dollars – TWD" },
  { value: "THB", label: "Thailand Baht – THB" },
  { value: "TTD", label: "Trinidad and Tobago Dollars – TTD" },
  { value: "TND", label: "Tunisia Dinars – TND" },
  { value: "TRY", label: "Turkey New Lira – TRY" },
  { value: "AED", label: "United Arab Emirates Dirhams – AED" },
  { value: "GBP", label: "United Kingdom Pounds – GBP" },
  { value: "VEB", label: "Venezuela Bolivares – VEB" },
  { value: "VND", label: "Vietnam Dong – VND" },
  { value: "ZMK", label: "Zambia Kwacha – ZMK" },
];

let bookingHours = {
  monday: [
    { from: '10:00', to: '19:00' },
  ],
  tuesday: [
    { from: '10:00', to: '19:00' },
  ],
  wednesday: [
    { from: '10:00', to: '19:00' },
  ],
  thursday: [
    { from: '10:00', to: '19:00' },
  ],
  friday: [
    { from: '10:00', to: '19:00' },
  ],
  saturday: [],
  sunday: [],
}

let defaultGalleryImage = api_url + '/booking/img/background.jpg';
let becomeArtistStepsLength = 5;
let crateStudioStepsLength = 8;
let imageSizeLimit = 15;

let captchaSiteKey = "6LfLkAopAAAAAFGAP_3Qzen1cWMPUcQQWTzyMtCE";
//let captchaSecretKey = "6LfLkAopAAAAACt8IRxDwF1eXqGDSS-sumHvJzLb";
let googleLoginKey = "73992622697-kft59u6rn26dsrtv42kp33g12chf1bj9.apps.googleusercontent.com";

let emailStatusRoutes:string[] = [
    "/account",
    "/account-password",
    "/account-studios",
    "/account-artist-portfolio",
    "/account-artist-profile",
    "/account-artist-invites",
    "/dashboard",
    "/subscription",
    "/become-artist-listing-1",
    "/become-artist-listing-2",
    "/become-artist-listing-3",
    "/become-artist-listing-4",
    "/become-artist-listing-preview",
    "/create-studio-select-plan",
    "/create-studio-listing-1",
    "/create-studio-listing-2",
    "/create-studio-listing-3",
    "/create-studio-listing-4",
    "/create-studio-listing-5",
    "/create-studio-listing-6",
    "/create-studio-listing-preview",
    "/list-tattoo-contests",
]

const privateRoutes:string[] = [
    "/updatePassword",
    "/create-artist",
    "/create-studio",
    "/delete-portfolio-image",
    "/upload-portfolio-images",
    "/upload-artist-avatar",
    "/is-artist-username-exist",
    "/is-studio-username-exist",
    "/is-studio-email-exist",
    "/accept-invitation",
    "/confirm-email",
    "/send-confirm-email",
    "/fetch-artist-styles",
    "/edit-artist-styles",
    "/fetch-current-user-studios",
    "/get-artist-account-info",
    "/edit-artist-account-info",
    "/upload-contest-image",
    "/delete-contest-image",
    "/edit-user-profile"
]

let GA4PropertyId = 'G-VHNGY8KFP1';
let googleMapKey = "AIzaSyDp4njbVN8b29jPpyAHxhdEWvY5h2yHe2s"
export {emailStatusRoutes, privateRoutes, googleMapKey, avatarColors, api_url, googleMapsAPI, styles, domain, facebookURL, instagramURL, currency, bookingHours, defaultGalleryImage, becomeArtistStepsLength, crateStudioStepsLength, imageSizeLimit, stylesList, captchaSiteKey, googleLoginKey, GA4PropertyId };

