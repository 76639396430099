import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import {defaultGalleryImage} from "../../contains/contants";

export interface ArtistPreviewProps {
    className?: string;
    username: string;
    bio: string;
    styles: string[];
    gallery: string[];
    size?: "default" | "small";
}

const StayCard: FC<ArtistPreviewProps> = ({
                                         username,
                                         bio,
                                         styles,
                                         gallery,
                                         size = "default",
                                         className = "",
                                     }) => {


    const renderSliderGallery = () => {
        return (
            <div className="relative w-full">
                <GallerySlider
                    uniqueID={`StayCard_${gallery.length}`}
                    ratioClass="aspect-w-4 aspect-h-3 "
                    galleryImgs={gallery.length > 0 ? gallery : [defaultGalleryImage]}
                />
            </div>
        );
    };

    const renderContent = () => {
        return (
            <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
                <div className="space-y-2">
                    <div className="flex items-center space-x-2">
                        <h2
                            className={` font-medium capitalize ${
                                size === "default" ? "text-lg" : "text-base"
                            }`}
                        >
                            <span className="line-clamp-1">{username}</span>
                        </h2>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div
            className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
            data-nc-id="StayCard"
        >
            <div>
                {renderSliderGallery()}
                {renderContent()}
            </div>
        </div>
    );
};

export default StayCard;
