import React, { FC,  } from "react";
import {api_url, defaultGalleryImage} from "../../contains/contants";
import GallerySlider from "../GallerySlider/GallerySlider";
import moment from "moment-timezone";
import {Link} from "react-router-dom";
import StatusBadge from "../../containers/Contests/StatusBadge";
interface Contest {
    id: string | number,
    name: string,
    startDate: number,
    endDate: number,
    imageUrl: string
}

export interface ContestsList {
    contests: Contest[];
}


const AccountStudiosCard: FC<ContestsList> = ({contests}) => {

    const renderContent = (item: any) => {




        return (
            <div  className={"p-4 space-y-4"}>
                <div className="space-y-2">
                    <div className="flex items-center space-x-2">
                        <h2 className={"text-lg" }>
                            <span className="line-clamp-1">{item.name}</span>
                        </h2>
                    </div>
                    <div>
                        <span className="line-clamp-1">{moment(item.startDate).format("DD MMM YYYY") + " - " + moment(item.endDate).format("DD MMM YYYY")}</span>
                    </div>
                    <div>
                       <StatusBadge status={item.status} />
                    </div>
                </div>
                <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
            </div>
        );
    };

    const renderSliderGallery = (item: any) => {
        return (
            <div className="relative w-full">
                <GallerySlider
                    uniqueID={`StayCard_${item.id}`}
                    ratioClass="aspect-w-4 aspect-h-3 "
                    galleryImgs={item.imageUrl ? [api_url + '/tattooContest/' + item.id + '/' + item.imageUrl] : [defaultGalleryImage]}
                />
            </div>
        );
    };

    return (
        <div  className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-4 md:gap-8 ">
            {contests.map((item: any) => {
                return(
                    <div
                        className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow`}
                        data-nc-id="StayCard"
                        key={item.id}
                    >
                        
                        <div style={{cursor: 'pointer'}}>
                        <Link to={"/contest-details/" + item.id}>
                            {renderSliderGallery(item)}
                            </Link>
                        </div>
                        <div style={{cursor: 'pointer'}} >
                        <Link to={"/contest-details/" + item.id}>
                                {renderContent(item)}
                                </Link>
                        </div>
                       
                    </div>
                )
            })}

        </div>

    );
};

export default AccountStudiosCard;


