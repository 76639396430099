import React from "react";
import {useHistory, useLocation} from "react-router-dom";
import CommonLayout from "../PageAddListing1/CommonLayout";
import ButtonSecondary from "../../shared/Button/ButtonSecondary";
import ButtonPrimary from "../../shared/Button/ButtonPrimary";
import ArtistPreview from "../../components/Artists/ArtistPreview";
import {becomeArtistStepsLength} from "../../contains/contants";

const BecomeArtistPreview = () => {

    const nav = useHistory();
    const location = useLocation();
    const currentStep = 5;
    const state: any = location.state;

    if (!state || !state.username){
        nav.push('/dashboard')
    }

    return (
        <CommonLayout
            index={"0" + currentStep}
            steps={'0' + becomeArtistStepsLength}
        >
            <div>
                <h2 className="text-2xl font-semibold">Congratulations 🎉</h2>
                <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    Excellent, congratulations on creating your artist profile
                </span>
            </div>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
            {state.showCreateStudioBtn ? <div>
                <h3 className="text-xl font-semibold mt-3">Continue with creating studio</h3>
                <ButtonPrimary className="mt-3" onClick={() => nav.push('create-studio-select-plan')}>Create Studio</ButtonPrimary>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
            </div> : null}
            {/* FORM */}
            <div>
                {/* <h3 className="text-lg font-semibold">This is your artist profile</h3> */}
                <div className="max-w-xs">
                    <ArtistPreview
                        className="mt-8"
                        styles={state.styles}
                        username={state.username}
                        bio={state.bio}
                        gallery={state.portfolio.map((item:any) => {return item.path})}
                    />
                </div>
                <div className="flex items-center space-x-5 mt-8">
                    <ButtonSecondary href="/account-artist-profile">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={1.5}
                                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                            />
                        </svg>
                        <span className="ml-3">Edit</span>
                    </ButtonSecondary>

                    <ButtonPrimary onClick={() => {
                        nav.push('/artists/' + state.username)
                    }}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={1.5}
                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={1.5}
                                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                            />
                        </svg>
                        <span className="ml-3">Preview</span>
                    </ButtonPrimary>
                </div>
            </div>

        </CommonLayout>
    );
};

export default BecomeArtistPreview;
