import {AnyAction, ThunkAction} from "@reduxjs/toolkit";
import {RootState} from "./index";
import {Invitation} from "../models/invite";
import inviteService from "../services/inviteService";
import {clearInviteState, setInvitation, setStudioName, setStudioStaffExist, setStudioUsername, setAnotherStudioProfile} from "./invite-slice";
import {toast} from "react-toastify";
import {api_url} from "../contains/contants";
export const getInviteStudioData=(username: string, token:string, userId: number | undefined):ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async(dispatch,getState)=>{

        const response:{response: string, invitation?: Invitation, studioName?: string, studioUsername?: string} = await inviteService.getStudioInfo(username, token, userId);

        if (response.response === "success"){
            dispatch(setInvitation(response.invitation!));
            dispatch(setStudioName(response.studioName!));
            dispatch(setStudioUsername(response.studioUsername!));
            dispatch(setStudioStaffExist(false));
            dispatch(setAnotherStudioProfile(false));
        }else if(response.response === "anotherStudioProfile"){
            dispatch(setAnotherStudioProfile(true));
            dispatch(setStudioStaffExist(false));
        }else if(response.response === "studioStaffExist"){
            dispatch(setAnotherStudioProfile(false));
            dispatch(setStudioStaffExist(true));
        }else{
            toast.error("Registration error", {
                position: toast.POSITION.TOP_CENTER
            });
            dispatch(clearInviteState(true));
        }
    }
}
export const acceptInvitation=(userId: number, token:string, navigate: any):ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async(dispatch,getState)=>{
        const t = getState().auth.token;
        const response = await inviteService.acceptInvitationRequest(userId, token, t);


        if (response.response === "success"){
            if (response.redirectToArtistFlow){
                navigate.push('/become-artist-listing-1')
            }else{
                window.location.href=api_url + "/studio/createMiddleware?t=" + t +"&redirect=/account/plans";
            }
        }else{
            toast.error("Registration error", {
                position: toast.POSITION.TOP_CENTER
            });
        }

        dispatch(clearInviteState(true))

    }
}
