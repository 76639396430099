import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import CommonLayout from "../PageAddListing1/CommonLayout";
import Select from 'react-select'
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Navigation from "../../components/Listing/NavBtn";
import {crateStudioStepsLength} from "../../contains/contants";
import Switch from "react-switch";
import { MinusIcon, PlusIcon } from "@heroicons/react/solid";
import { useAppDispatch,useAppSelector } from "../../hooks/redux-hooks";
import {setBookingHours, setStepCompleted} from "../../store/create-studio-slice";

import moment from "moment-timezone";
const CreateStudioListing5 = () => {

    const navigate = useHistory();
    const dispatch = useAppDispatch();
    const currentStep = 6;
    const stepCompleted = useAppSelector((state)=>state.createStudio.stepCompleted);

    useEffect(() => {
        if (stepCompleted < currentStep - 1){
            navigate.push('/dashboard')
        }
    }, [])

    const initialValues = {
        bookingHours: useAppSelector((state)=>state.createStudio.bookingHours)
    }


    const checkWorkingHours = (data: any, testContext: any, timeFormat = 'HH:mm', ) => {

        if (data && data.length > 0){
            if (data[0].from >= data[0].to || !moment(data[0].from, timeFormat, true).isValid() ||  !moment(data[0].to, timeFormat, true).isValid()) {
                return testContext.createError({message: 'Invalid time range at row ' + 1})
            }
            for (var k = 1; k < data.length; k++) {
                if (data[k-1].to >= data[k].from || data[k].from >= data[k].to || !moment(data[k].from, timeFormat, true).isValid() || !moment(data[k].to, timeFormat, true).isValid()) {
                    return testContext.createError({message: 'Invalid time range at row ' + (k + 1)})
                }
            }
        }
        return true
    }

    const validationSchema = Yup.object().shape({
        bookingHours: Yup.object().shape({
            monday: Yup.array().test('check-monday', 'Monday error', (val, testContext) => {
                return checkWorkingHours(val, testContext)
            }),
            tuesday: Yup.array().test('check-monday', 'Tuesday error', (val, testContext) => {
                return checkWorkingHours(val, testContext)
            }),
            wednesday: Yup.array().test('check-monday', 'Wednesday', (val, testContext) => {
                return checkWorkingHours(val, testContext)
            }),
            thursday: Yup.array().test('check-monday', 'Thursday error', (val, testContext) => {
                return checkWorkingHours(val, testContext)
            }),
            friday: Yup.array().test('check-monday', 'Friday error', (val, testContext) => {
                return checkWorkingHours(val, testContext)
            }),
            saturday: Yup.array().test('check-monday', 'Saturday error', (val, testContext) => {
                return checkWorkingHours(val, testContext)
            }),
            sunday: Yup.array().test('check-monday', 'Sunday error', (val, testContext) => {
                return checkWorkingHours(val, testContext)
            }),

        })
    });


    const handleLogin = async (formValue: {bookingHours: any}) : Promise<void> => {

        await dispatch(setBookingHours(formValue.bookingHours));
        if (stepCompleted < currentStep){
            await dispatch(setStepCompleted(currentStep));
        }
        navigate.push("/create-studio-listing-6");

    };

    let is12Hours = useAppSelector((state)=>state.createStudio.timeFormat).value;

    let tf = is12Hours === '12' ? 'hh:mm A' : 'HH:mm';

    let daysOfWeek:string[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
    let hours = [
        {value: '06:00', label: moment('06:00', ['HH:mm']).format(tf)},
        {value: '06:30', label: moment('06:30', ['HH:mm']).format(tf)},
        {value: '07:00', label: moment('07:00', ['HH:mm']).format(tf)},
        {value: '07:30', label: moment('07:30', ['HH:mm']).format(tf)},
        {value: '08:00', label: moment('08:00', ['HH:mm']).format(tf)},
        {value: '08:30', label: moment('08:30', ['HH:mm']).format(tf)},
        {value: '09:00', label: moment('09:00', ['HH:mm']).format(tf)},
        {value: '09:30', label: moment('09:30', ['HH:mm']).format(tf)},
        {value: '10:00', label: moment('10:00', ['HH:mm']).format(tf)},
        {value: '10:30', label: moment('10:30', ['HH:mm']).format(tf)},
        {value: '11:00', label: moment('11:00', ['HH:mm']).format(tf)},
        {value: '11:30', label: moment('11:30', ['HH:mm']).format(tf)},
        {value: '12:00', label: moment('12:00', ['HH:mm']).format(tf)},
        {value: '12:30', label: moment('12:30', ['HH:mm']).format(tf)},
        {value: '13:00', label: moment('13:00', ['HH:mm']).format(tf)},
        {value: '13:30', label: moment('13:30', ['HH:mm']).format(tf)},
        {value: '14:00', label: moment('14:00', ['HH:mm']).format(tf)},
        {value: '14:30', label: moment('14:30', ['HH:mm']).format(tf)},
        {value: '15:00', label: moment('15:00', ['HH:mm']).format(tf)},
        {value: '15:30', label: moment('15:30', ['HH:mm']).format(tf)},
        {value: '16:00', label: moment('16:00', ['HH:mm']).format(tf)},
        {value: '16:30', label: moment('16:30', ['HH:mm']).format(tf)},
        {value: '17:00', label: moment('17:00', ['HH:mm']).format(tf)},
        {value: '17:30', label: moment('17:30', ['HH:mm']).format(tf)},
        {value: '18:00', label: moment('18:00', ['HH:mm']).format(tf)},
        {value: '18:30', label: moment('18:30', ['HH:mm']).format(tf)},
        {value: '19:00', label: moment('19:00', ['HH:mm']).format(tf)},
        {value: '19:30', label: moment('19:30', ['HH:mm']).format(tf)},
        {value: '20:00', label: moment('20:00', ['HH:mm']).format(tf)},
        {value: '20:30', label: moment('20:30', ['HH:mm']).format(tf)},
        {value: '21:00', label: moment('21:00', ['HH:mm']).format(tf)},
        {value: '21:30', label: moment('21:30', ['HH:mm']).format(tf)},
    ]

    let defaultRange = {from: '10:00', to: '19:00'};


    let generateTable = (bookingHours:any, setFunction: any) => {
        let table:any[] = [];

        for (let i:number = 0; i < daysOfWeek.length; i++){

            let day = daysOfWeek[i];

            let dropDowns:any = [];

            for (let d = 0; d < bookingHours[day as keyof typeof bookingHours].length; d++){

                let data:any = bookingHours[day as keyof typeof bookingHours][d];

                dropDowns = [...dropDowns,  <div key={day+'Range'+d}>
                    <div style={{display: 'flex', marginTop: 10}} >
                        <div style={{flex: 10, marginRight: 10}}>
                            <Select
                                options={hours}
                                name={'hours'}
                                value={{
                                    value: data.from,
                                    label: moment(data.from, ['HH:mm']).format(tf)
                                }}
                                onChange={(res:any) => {
                                    let data:any = {...bookingHours};
                                    let updatedDay = [...data[day]];
                                    updatedDay[d] = { ...updatedDay[d], from: res.value };
                                    data[day] = updatedDay;
                                    setFunction('bookingHours', data);
                                }}
                                placeholder={'Select Hour...'}
                            />
                        </div>
                        <div style={{flex: 10, marginLeft: 10}}>
                            <Select
                                options={hours}
                                name={'hours'}
                                value={{
                                    value: data.to,
                                    label: moment(data.to, ['HH:mm']).format(tf)
                                }}
                                onChange={(res:any) => {
                                    let data:any = {...bookingHours};
                                    let updatedDay = [...data[day]];
                                    updatedDay[d] = { ...updatedDay[d], to: res.value };
                                    data[day] = updatedDay;
                                    setFunction('bookingHours', data);
                                }}
                                placeholder={'Select Hour...'}
                            />
                        </div>
                        <div style={{flex: 1, textAlign: "center"}}>
                            {d === 0 ? <PlusIcon
                                style={{
                                    position: 'relative',
                                    left: '45%',
                                    top: '20%'
                                }}
                                onClick={() => {
                                    let data:any = {...bookingHours};

                                let rangeData = [...data[day]];
                                rangeData = [...rangeData, defaultRange];
                                data[day] = rangeData;

                                    setFunction('bookingHours', data)
                                }}
                                className="w-4 h-4" /> : <MinusIcon style={{
                                position: 'relative',
                                left: '45%',
                                top: '20%'
                            }} onClick={() => {
                                let data:any = bookingHours;

                                let rangeData = data[day];
                                rangeData.splice(d, 1)
                                data[day] = rangeData

                                setFunction('bookingHours', data)
                            }} className="w-4 h-4" /> }


                        </div>
                    </div>
                </div>]

            }

            table.push(
                <div key={daysOfWeek[i]}>
                    <label className="block">
                        <div style={{display: 'flex'}}>
                            <div style={{flex: 3}}>
                                <span className="text-neutral-800 dark:text-neutral-200">{ day.charAt(0).toUpperCase() + day.slice(1)}</span>
                            </div>
                            <div style={{flex: 1, textAlign: 'right'}}>
                                <Switch onChange={(val) => {
                                    let data:any = {...bookingHours};

                                    if (val){
                                        data[day] = [defaultRange];
                                    }else{
                                        data[day] = []
                                    }

                                    setFunction('bookingHours', data)

                                }} checked={bookingHours[day as keyof typeof bookingHours].length > 0}/>
                            </div>
                        </div>
                    </label>
                    {dropDowns}
                    <ErrorMessage name={'bookingHours.' + daysOfWeek[i]} component="div" className="text-red-500 text-xs italic"/>
                </div>


            )
        }

        return table
    }


    return (
        <CommonLayout
            index={"0" + currentStep}
            steps={'0' + crateStudioStepsLength}
        >
            <>
                <h2 className="text-2xl font-semibold">Studio Working Time</h2>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

                {/* FORM */}
                <div className="space-y-8">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleLogin}
                    >
                        {({ values, setFieldValue, handleChange, errors, touched }) => (
                            <Form className="grid grid-cols-1 gap-6">
                                {generateTable(values.bookingHours, setFieldValue)}
                                <Navigation prev={"/create-studio-listing-4"}  />

                            </Form>

                        )}
                    </Formik>

                </div>
            </>
        </CommonLayout>
    );
};

export default CreateStudioListing5;
