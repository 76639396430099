import React from "react";
const StatusBadge = (props: {status: string}) => {
    let badgeStyle:any = {
        padding: "3px 10px 3px 10px",
        borderRadius: "10px",
        width: "fit-content",
        color: "white"
    };

    let bg = "";

    if (props.status === "active"){
        bg = "bg-green-500";
    }else if (props.status === "upcoming"){
        bg = "bg-primary-500";
        badgeStyle.backgroundColor = "blue";
    }else{
        bg = "bg-red-500";
    }

    return (
        <span style={badgeStyle} className={ bg + " line-clamp-1"}>{props.status}</span>

    );
};

export default StatusBadge;
