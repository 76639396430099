import {defaultGalleryImage} from "../contains/contants";
import {toast} from "react-toastify";
import {AnyAction, ThunkAction} from "@reduxjs/toolkit";
import {RootState} from "./index";
import {createStudioRequest} from "../services/createStudio";
import {CreateStudio} from "../models/createStudio";
import {createStudioFlowCleanState} from "./create-studio-slice";
export const createStudio=(images: any[], navigation:any):ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async(dispatch,getState)=>{
        let createStudioData:CreateStudio = getState().createStudio;
        const userId:number = getState().auth.user.id;
        const token:string = getState().auth.token;
        const response:{success: boolean, images: string[]} = await createStudioRequest(images, createStudioData, userId, token);
        if (response.success){
            toast.success("Studio profile created successfully", {
                position: toast.POSITION.TOP_CENTER
            });

            navigation.push('create-studio-preview', {
                username: createStudioData.username,
                studioName: createStudioData.studioName,
                address: createStudioData.location.address,
                gallery: response.images.length > 0 ? response.images : [defaultGalleryImage]
            });
            await dispatch(createStudioFlowCleanState())
        }else{
            toast.error("Error while trying to creat studio profile", {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }
}
export const createStudioCleanState=():ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async(dispatch,getState)=>{
        dispatch(createStudioFlowCleanState())
    }
}
