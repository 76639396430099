import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import CommonLayout from "../PageAddListing1/CommonLayout";
import Select from 'react-select'
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import {crateStudioStepsLength, currency} from "../../contains/contants";
import moment from "moment-timezone";
import Navigation from "../../components/Listing/NavBtn";
import { useAppDispatch,useAppSelector } from "../../hooks/redux-hooks";
import {setTimeFormat, setTimeZone, setCurrency, setLanguage, setStepCompleted} from "../../store/create-studio-slice";
import {LocalSettings} from "../../models/createStudio";

const CreateStudioListing4 = () => {
    const navigate = useHistory();
    const dispatch = useAppDispatch();
    const currentStep = 5;
    const stepCompleted = useAppSelector((state)=>state.createStudio.stepCompleted);

    useEffect(() => {
        if (stepCompleted < currentStep - 1){
            navigate.push('/dashboard')
        }

    }, [])

    let zones = moment.tz.names();

    let timeZones = zones.map(function (obj){
        return {
            value: obj,
            label: obj + " (GMT " + moment.tz(obj).format('Z') + ")"
        }
    })

    const initialValues = {
        timeFormat: useAppSelector((state)=>state.createStudio.timeFormat),
        language: useAppSelector((state)=>state.createStudio.language),
        currency: useAppSelector((state)=>state.createStudio.currency),
        timeZone: useAppSelector((state)=>state.createStudio.timeZone),
    }

    const validationSchema = Yup.object().shape({
        timeFormat: Yup.object().nullable().required('Time Format is required'),
        language: Yup.object().nullable().required('Language is required'),
        currency: Yup.object().nullable().required('Currency is required'),
        timeZone: Yup.object().nullable().required('Time Zone is required')
    });

    const handleLogin = async (formValue: { timeFormat: LocalSettings; language: LocalSettings, currency: LocalSettings, timeZone: LocalSettings}) : Promise<void> => {
        await dispatch(setTimeFormat(formValue.timeFormat));
        await dispatch(setLanguage(formValue.language));
        await dispatch(setTimeZone(formValue.timeZone));
        await dispatch(setCurrency(formValue.currency));
        if (stepCompleted < currentStep){
            await dispatch(setStepCompleted(currentStep));
        }
        navigate.push("/create-studio-listing-5");
    };

    const optionsTimeFormat = [
        { value: '12', label: '12-hour clock (AM/PM)' },
        { value: '24', label: '24-hour clock' },
    ]

    const optionsLanguages = [
        { value: 'English', label: 'English' },
    ]

    return (
        <CommonLayout
            index={"0" + currentStep}
            steps={'0' + crateStudioStepsLength}
        >
            <>
                <h2 className="text-2xl font-semibold">Studio Social Medias</h2>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

                {/* FORM */}
                <div className="space-y-8">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleLogin}
                    >
                        {({ values, setFieldValue, handleChange, errors, touched }) => (
                            <Form className="grid grid-cols-1 gap-6">
                                <label className="block">
                                    <span className="text-neutral-800 dark:text-neutral-200">Time Format</span>

                                    <Select
                                        options={optionsTimeFormat}
                                        name={'timeFormat'}
                                        value={values.timeFormat}
                                        onChange={(res) => {
                                            setFieldValue('timeFormat', res )
                                        }}
                                        placeholder={'Select Time Format...'}
                                    />
                                    <ErrorMessage name="timeFormat" component="div" className="text-red-500 text-xs italic"/>
                                </label>
                                <label className="block">
                                    <span className="text-neutral-800 dark:text-neutral-200">Language</span>

                                    <Select
                                        options={optionsLanguages}
                                        name={'language'}
                                        value={values.language}
                                        onChange={(res) => {
                                            setFieldValue('language', res )
                                        }}
                                        placeholder={'Select Language...'}
                                    />
                                    <ErrorMessage name="language" component="div" className="text-red-500 text-xs italic"/>
                                </label>
                                <label className="block">
                                    <span className="text-neutral-800 dark:text-neutral-200">Currency</span>

                                    <Select
                                        options={currency}
                                        name={'currency'}
                                        value={values.currency}
                                        onChange={(res) => {
                                            setFieldValue('currency', res )
                                        }}
                                        placeholder={'Select Currency...'}
                                    />
                                    <ErrorMessage name="currency" component="div" className="text-red-500 text-xs italic"/>
                                </label>
                                <label className="block">
                                    <span className="text-neutral-800 dark:text-neutral-200">Time Zone</span>
                                    <Select
                                        options={timeZones}
                                        name={'timeZone'}
                                        value={values.timeZone}
                                        onChange={(res) => {
                                            setFieldValue('timeZone', res )
                                        }}
                                        placeholder={'Select Time Zone...'}
                                    />
                                    <ErrorMessage name="timeZone" component="div" className="text-red-500 text-xs italic"/>
                                </label>
                                <Navigation prev={"/create-studio-listing-3"}  />

                            </Form>

                        )}
                    </Formik>

                </div>
            </>
        </CommonLayout>
    );
};

export default CreateStudioListing4;
