import { FC } from "react";

export interface FAQProps {
  className?: string;
  title: string;
  description: string;
}

const FAQ: FC<FAQProps> = ({ className = "", title = "Title", description = "Description" }) => {
  

  return (
            <div className={`nc-Card3 relative flex flex-col-reverse sm:flex-row sm:items-center rounded-[40px] group`} data-nc-id="Card3" >
              <div className="flex flex-col flex-grow">
                <div className="space-y-5 mb-4">
                  <div>
                    <h2
                      className={`nc-card-title block font-semibold text-neutral-900 dark:text-neutral-100 text-xl`}
                    >
                      <h3 className="line-clamp-2">
                        {title}
                       
                      </h3>
                    </h2>
                    <div className="sm:mt-2">
                      <span className="text-neutral-500 dark:text-neutral-400 text-base">
                        {description}
                      </span>
                    </div>
                  </div>

                </div>
              </div>

            </div>
  );
};

export default FAQ;
