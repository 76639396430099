import React, {FC, useEffect, useMemo} from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import NextPrev from "shared/NextPrev/NextPrev";
import useNcId from "hooks/useNcId";
import {api_url, defaultGalleryImage} from "../../contains/contants";
import {Link} from "react-router-dom";
import GallerySlider from "../GallerySlider/GallerySlider";
import moment from "moment";
import StatusBadge from "../../containers/Contests/StatusBadge";
import NcImage from "../../shared/NcImage/NcImage";

export interface ContestsSliderProps {
    className?: string;
    itemClassName?: string;
    heading?: string;
    subHeading?: string;
    contests?: any[];
    categoryCardType?: "card3" | "card4" | "card5";
    itemPerRow?: 4 | 5;
    sliderStyle?: "style1" | "style2";
    uniqueClassName?: string;
}



const ContestsSlider: FC<ContestsSliderProps> = ({
                                                               heading = "",
                                                               subHeading = "",
                                                               className = "",
                                                               itemClassName = "",
                                                               contests = [],
                                                               itemPerRow = 4,
                                                               sliderStyle = "style1",
                                                               uniqueClassName= "",
                                                           }) => {
    const UNIQUE_CLASS =
        "SectionSliderNewCategories__" + uniqueClassName + useNcId();

    let MY_GLIDEJS = useMemo(() => {
        return new Glide(`.${UNIQUE_CLASS}`, {
            perView: itemPerRow,
            gap: 32,
            bound: true,
            breakpoints: {
                1280: {
                    perView: itemPerRow - 1,
                },
                1024: {
                    gap: 20,
                    perView: itemPerRow - 2,
                },
                768: {
                    gap: 20,
                    perView: itemPerRow - 3,
                },
                640: {
                    gap: 20,
                    perView: itemPerRow - 3,
                },
                500: {
                    gap: 20,
                    perView: 1.3,
                },
            },
        });
    }, [UNIQUE_CLASS]);

    useEffect(() => {
        setTimeout(() => {
            MY_GLIDEJS.mount();
        }, 100);
    }, [MY_GLIDEJS, UNIQUE_CLASS]);

    return (
        <div className={`nc-SectionSliderNewCategories ${className}`}>
            <div className={`${UNIQUE_CLASS} flow-root`}>
                <Heading
                    desc={subHeading}
                    hasNextPrev={sliderStyle === "style1"}
                    isCenter={sliderStyle === "style2"}
                >
                    {heading}
                </Heading>
                <div className="glide__track" data-glide-el="track">
                    <ul className="glide__slides">
                        {contests.map((item: any) => {
                            return(
                                <div
                                    className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow`}
                                    data-nc-id="StayCard"
                                    key={item.id}
                                    style={{cursor: 'pointer'}} 
                                >
                                    <Link to={"/contest-details/" + item.id}>
                                    <div>
                                        <div className="relative w-full">
                                            <div>
                                                <NcImage src={item.imageUrl ? api_url + '/tattooContest/' + item.id + '/' + item.imageUrl : defaultGalleryImage} />
                                            </div>

                                        </div>
                                    </div>
                                    <div >
                                        
                                            <div  className={"p-4 space-y-4"}>
                                                <div className="space-y-2">
                                                    <div className="flex items-center space-x-2">
                                                        <h2 className={"text-lg" }>
                                                            <span className="line-clamp-1">{item.name}</span>
                                                        </h2>
                                                    </div>
                                                    <div>
                                                        <span className="line-clamp-1">{moment(item.startDate).format("DD MMM YYYY") + " - " + moment(item.endDate).format("DD MMM YYYY")}</span>
                                                    </div>
                                                    <div>
                                                        <StatusBadge status={item.status} />
                                                    </div>
                                                </div>
                                                <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
                                            </div>
                                       

                                    </div>

                                    </Link>
                                </div>
                            )
                        })}
                    </ul>
                </div>

                {sliderStyle === "style2" && (
                    <NextPrev className="justify-center mt-16" />
                )}
            </div>
        </div>
    );
};

export default ContestsSlider;
