import React, {FC, useEffect, useState} from "react";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link, useHistory  } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from 'react-toastify';
import { useAppDispatch,useAppSelector } from "../../hooks/redux-hooks";
import {login} from '../../store/auth-actions';
import ReCAPTCHA from "react-google-recaptcha"
import {captchaSiteKey} from "../../contains/contants";
import GoogleLoginBtn from "../../components/Google/GoogleLogin";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
export interface PageLoginProps {
  className?: string;
}

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  const dispatch=useAppDispatch();
  const isLoggedIn = useAppSelector((state)=>state.auth.isLoggedIn);

  useEffect(() => {
    if(isLoggedIn){
      navigate.push("/dashboard")
    }
  }, [isLoggedIn]);

  const [requestLoading, setRequestLoading] = useState(false);

  const navigate = useHistory();
  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required!"),
    password: Yup.string().required("Password is required!"),
  });

  const initialValues = {
    email: "",
    password: "",
  };

  const [captchaVisible, setCaptchaVisible] = useState(false);
  const [captchaChecked, setCaptchaChecked] = useState(false);

    const handleLogin = async (formValue: { email: string; password: string }) : Promise<void> => {
      if (captchaVisible && !captchaChecked){
        toast.warning("Please confirm captcha", {
          position: toast.POSITION.TOP_CENTER
        });
      }else{
        await setRequestLoading(true)
        let req: any = await dispatch(login(formValue.email, formValue.password, navigate, "/dashboard"));
        if (req && req.numberOfFails && req.numberOfFails > 3){
          await setCaptchaVisible(true)
        }
        await setRequestLoading(false)
      }
  };



  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login || Tattoohub</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Login
        </h2>
        <div className="max-w-md mx-auto space-y-6">
         <GoogleLoginBtn />
          {/* OR */}
          {/* <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div> */}
          {/* FORM */}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleLogin}
          >
          {({ values, setFieldValue, handleChange, errors, touched }) => (
          <Form className="grid grid-cols-1 gap-6">
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                placeholder="example@example.com"
                className="mt-1"
              />
               <ErrorMessage name="email" component="div" className="text-red-500 text-xs italic"/>
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
                <div onClick={() => {navigate.push("/forgot-pass", {email: values.email})}} className="text-sm hover:cursor-pointer">
                  Forgot password?
                </div>
              </span>
              <Input
               value={values.password}
              type="password"
              className="mt-1"
              onChange={handleChange}
              name="password"/>
               <ErrorMessage name="password" component="div" className="text-red-500 text-xs italic"/>
            </label>
            <ButtonPrimary type="submit" >Continue</ButtonPrimary>
            </Form>
          )}
          </Formik>
          {captchaVisible ? <div style={{
            display: "flex",
            placeContent: "center"
          }}><ReCAPTCHA
              sitekey={captchaSiteKey}
              onChange={() => {setCaptchaChecked(true)}}
          /></div>  : null}
          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            New user? {` `}
            <Link to="/signup">Create an account</Link>
          </span>
        </div>
      </div>
      {requestLoading ? <LoadingSpinner /> : null}
    </div>
  );
};

export default PageLogin;
