import React, {useState, useEffect, Fragment} from "react";
import CommonLayout from "../AccountPage/CommonLayout";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../hooks/redux-hooks";
import {toast} from "react-toastify";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import contests from "../../services/contests";
import TattooContestsCard from "../../components/Contests/TattooContestsCard";
import {Tab} from "@headlessui/react";
const ListTattooContests = () => {
    const navigate = useHistory();
    const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);

    const [contestsArray, setContestsArray] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    let [categories] = useState( ["Active", "Upcoming", "Finished"]);

    useEffect(() => {
        if (isLoggedIn === false) {
            navigate.push("/login")
        }

        (async () => {
            await setIsLoading(true)
            let req = await contests.fetchTattooContests();

            if (req.response === "success"){
                setContestsArray(req.contests)
            }else{
                toast.error("An error occurred. Please try again", {
                    position: toast.POSITION.TOP_CENTER
                });
            }
            await setIsLoading(false)

        })()

    }, [])

    return (
        <div>
            <CommonLayout>
                {isLoading ? <LoadingSpinner /> : null}
                {contestsArray === null ? null :
                    <div className="space-y-6 sm:space-y-8">
                        <div>
                            <h2 className="text-3xl font-semibold">Studios</h2>
                        </div>
                        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

                        <Tab.Group>
                            <Tab.List className="flex space-x-1 overflow-x-auto">
                                {categories.map((item) => (
                                    <Tab key={item} as={Fragment}>
                                        {({ selected }) => (
                                            <button
                                                className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${
                                                    selected
                                                        ? "bg-secondary-900 text-secondary-50 "
                                                        : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                                                } `}
                                            >
                                                {item}
                                            </button>
                                        )}
                                    </Tab>
                                ))}
                            </Tab.List>
                            <Tab.Panels>
                                <Tab.Panel className="mt-8">
                                    <TattooContestsCard contests={contestsArray.filter((item: any) => {return item.status === "active"})} />
                                </Tab.Panel>
                                <Tab.Panel className="mt-8">
                                    <TattooContestsCard contests={contestsArray.filter((item: any) => {return item.status === "upcoming"})} />
                                </Tab.Panel>
                                <Tab.Panel className="mt-8">
                                    <TattooContestsCard contests={contestsArray.filter((item: any) => {return item.status === "finished"})} />
                                </Tab.Panel>
                            </Tab.Panels>
                        </Tab.Group>
                    </div>
                }
            </CommonLayout>
        </div>
    );
};

export default ListTattooContests;
