import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import React, {FC, useEffect, useState} from "react";
import { Helmet } from "react-helmet";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import imagePng from "../../images/back-right.png";
import contests from "../../services/contests";
import ContestSlider from "../../components/Contests/ContestSlider";
export interface ListContestsPublicPageProps {
    className?: string;
}

const ListContestsPublicPage: FC<ListContestsPublicPageProps> = ({className = ""}) => {

    const [contestsArray, setContestsArray] = useState([]);
    const [requestLoading, setRequestLoading] = useState(false)

    useEffect(() => {
        (async () => {
           await setRequestLoading(true);
           let req = await contests.getPublicPageContests();
           await setContestsArray(req.contests)
           await setRequestLoading(false);
        })()

    }, []);

    const renderContests = (contests: any) => {
        return (
            <div>
                <div className="container relative overflow-hidden mt-10">
                    <div className="relative py-16 mb-24 ">
                        <BackgroundSection />
                        <div className={"relative"}>
                            <ContestSlider heading={"Active Contests"} subHeading="Live Contests: Make Your Voice Heard!" contests={contests.filter((item:any) => {return item.status === "active"})} />
                        </div>
                    </div>
                </div>
                <div className="container relative overflow-hidden">
                    <div className="relative py-16 mb-24 ">
                        <ContestSlider heading={"Upcoming Contests"} subHeading="Upcoming Contests: Get Ready to Dive In!" contests={contests.filter((item:any) => {return item.status === "upcoming"})} />
                    </div>
                </div>
                <div className="container relative overflow-hidden">
                    <div className="relative py-16 mb-24 ">
                        <BackgroundSection />
                        <div className={"relative"}>
                            <ContestSlider heading={"Finished Contests"} subHeading="Past Contests: Celebrating the Winners!" contests={contests.filter((item:any) => {return item.status === "finished"})} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div
            className={`nc-ListingRealEstatePage relative overflow-hidden ${className}`}
            data-nc-id="ListingRealEstatePage"
        >
            {requestLoading ? <LoadingSpinner /> : null}
            <Helmet>
                <title>Tattoohub || Tattoo Contests</title>
            </Helmet>

            <div className={`nc-SectionHeroArchivePage flex flex-col relative ml-2 mr-2 ${className}`}>
                <div className="flex flex-col lg:flex-row lg:items-centerl lg:flex-row lg:items-center lg:ml-20 pl-10 lg:mr-20 pr-10">
                    <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-6 lg:space-y-10 pb-14 lg:pb-64 xl:pb-80 xl:pr-14 lg:mr-10 xl:mr-0">
                        <h2 className="font-medium text-4xl md:text-5xl xl:text-7xl leading-[110%]">
                        Elevate Tattoo Art: Your Vote Matters!
                        </h2>
                        <span className="text-base md:text-lg text-neutral-500 dark:text-neutral-400">
                        Explore unique designs from top artists and support your favorite. Shape tattoo trends with every vote!
                            </span>
                    </div>
                    <div className="flex-grow">
                        <img className="w-full" src={imagePng} alt="hero" />
                    </div>
                </div>
            </div>

            {contestsArray.length > 0 ? renderContests(contestsArray) : null}
        </div>

    );
};

export default ListContestsPublicPage;
