import NcImage from "../../shared/NcImage/NcImage";
import React, { FC } from "react";
import {api_url} from "../../contains/contants";
import { useHistory } from "react-router-dom";

interface Contest {
    id: string | number,
    contestName: string,
    tattooFileName: string,
    artistName: string,
    contestNumber: string | number
    contestStart: string,
    contestEnd: string,
}

export interface TattoosOfTheWeek {
    tattoosOfTheWeek: Contest[];
}

const TattooContestCard: FC<TattoosOfTheWeek> = ({tattoosOfTheWeek}) => {
    let history = useHistory();
    return (
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 md:gap-8 ">
            {tattoosOfTheWeek.map((item: any) => {
                return(
                    <div
                    key={item.id}
                    >
                        <div className={`flex-shrink-0 relative w-full aspect-w-5 aspect-h-4 sm:aspect-h-7 h-0 rounded-2xl overflow-hidden group`} >
                            <NcImage
                                src={api_url + '/tattooContest/' + item.id + '/' + item.tattooFileName}
                                className="object-cover w-full h-full rounded-2xl"
                            />
                            <span  className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity"></span>
                        </div>
                        <div className="mt-4 truncate">
                            <h2  style={{cursor: item.artist ? 'pointer' : 'auto'}} onClick={() => {
                                if (item.artist){
                                    history.push('/artists/' + item.artist.username)
                                }
                            }} className={`text-base hover:pointer sm:text-lg text-neutral-900 dark:text-neutral-100 font-medium truncate`}>
                                {item.artistName}
                            </h2>
                            {/* <span className={`block mt-2 text-sm text-neutral-6000 dark:text-neutral-400`}>
                                {item.contestName + ' ' + item.contestNumber}
                            </span>
                            <span className={`block mt-2 text-sm text-neutral-6000 dark:text-neutral-400`}>
                                {item.contestStart + ' - ' + item.contestEnd}
                            </span> */}
                        </div>
                    </div>
                )
            })}
        </div>

    );
};

export default TattooContestCard;