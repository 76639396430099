import React, {FC, useEffect, useState} from "react";
import {  useHistory  } from "react-router-dom";
import CommonLayout from "../PageAddListing1/CommonLayout";
import {createArtist} from "../../store/become-artist-actions";
import ModalPhotos from "../StudioProfilePage/ModalPhotos";
import NcImage from "../../shared/NcImage/NcImage";
import {Form, Formik} from "formik";
import Navigation from "../../components/Listing/NavBtn";
import * as Yup from "yup";
import RemoveButton from "../../components/Btns/RemoveButton";
import { useAppDispatch,useAppSelector } from "../../hooks/redux-hooks";
import {becomeArtistStepsLength, imageSizeLimit} from "../../contains/contants";
import {toast} from "react-toastify";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
export interface BecomeArtistListing4Props {
    className?: string;
}
const BecomeArtistListing4: FC<BecomeArtistListing4Props> = ({ className = "" }) => {
    const navigate = useHistory();
    const dispatch = useAppDispatch();
    const currentStep = 4;
    const isTattooArtist = useAppSelector((state)=>state.auth.isTattooArtist);
    const stepCompleted = useAppSelector((state)=>state.becomeArtist.stepCompleted);

    useEffect(() => {
        if (isTattooArtist || stepCompleted < currentStep - 1){
            navigate.push('/dashboard')
        }
    }, [])

    const [loading, setLoading] = useState(false)
    const handleCloseModal = () => setIsOpen(false);
    const [isOpen, setIsOpen] = useState(false);
    const [openFocusIndex, setOpenFocusIndex] = useState(0);
    const handleOpenModal = (index: number) => {
        setIsOpen(true);
        setOpenFocusIndex(index);
    };

    const initialValues:any = {
        images: [],
    }


    const validationSchema = Yup.object().shape({});

    const handleLogin = async (formValue: { images: any;}) : Promise<void> => {
        await setLoading(true)
        await dispatch(createArtist(formValue.images, navigate));
        await setLoading(false)
    };

    const removeButton = (imageId:string, setFunction:any, imagesArray:any) => {
        return (
            <RemoveButton
                onClick={() => {
                    let images = imagesArray.filter(function (obj:any){return obj.id !== imageId});
                    setFunction('images', images)
                }}

                className="bg-white absolute z-50 right-3 top-3 shadow-lg rounded-xl w-5 h-5"
            />
        )
    }

    return (
        <CommonLayout
            index={"0" + currentStep}
            steps={'0' + becomeArtistStepsLength}
        >
            {loading ?  <LoadingSpinner /> : null}
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleLogin}
            >
                {({ values, setFieldValue, handleChange, errors, touched }) => (
                    <Form className="grid grid-cols-1 gap-6">
                        <div >
                            <div className="mt-5 mb-5">
                                <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-md">
                                    <div className="space-y-1 text-center">
                                        <svg
                                            className="mx-auto h-12 w-12 text-neutral-400"
                                            stroke="currentColor"
                                            fill="none"
                                            viewBox="0 0 48 48"
                                            aria-hidden="true"
                                        >
                                            <path
                                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            ></path>
                                        </svg>
                                        <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                                            <label
                                                htmlFor="file-upload"
                                                className="relative cursor-pointer  rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                                            >
                                                <span>Upload a file</span>
                                                <input
                                                    id="file-upload"
                                                    name="file-upload"
                                                    type="file"
                                                    accept="image/*"
                                                    multiple={true}
                                                    className="sr-only"
                                                    onChange={async (file:any) => {
                                                        let uploadImages:any[] = values.images;
                                                        let notUploaded = 0;
                                                        for (let i = 0; i < file.target.files.length; i++){
                                                            if (file.target.files[i].size < imageSizeLimit * 1024 * 1024) {
                                                                file.target.files[i].id = file.target.files[i].name;
                                                                uploadImages.push(file.target.files[i]);
                                                            }else{
                                                                notUploaded++;
                                                            }
                                                        }

                                                        if (notUploaded > 0){
                                                            toast.warn("Warning: image size should be less than " + imageSizeLimit + "MB. Not uploaded photos: " + notUploaded, {
                                                                position: toast.POSITION.TOP_CENTER
                                                            });
                                                        }
                                                        setFieldValue('images', uploadImages)
                                                    }}
                                                />
                                            </label>
                                            <p className="pl-1">or drag and drop</p>
                                        </div>
                                        <p className="text-xs text-neutral-500 dark:text-neutral-400">
                                            PNG, JPG, GIF up to 15MB
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {values.images.length > 0 ? <div>
                            <header className="container 2xl:px-14 rounded-md sm:rounded-xl">
                                <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
                                    <div
                                        className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"

                                    >
                                        <div onClick={() => handleOpenModal(0)}>
                                            <NcImage
                                                containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                                                className="object-cover w-full h-full rounded-md sm:rounded-xl"
                                                src={URL.createObjectURL(values.images[0])}
                                            />
                                        </div>
                                        <div>
                                            {removeButton(values.images[0].id, setFieldValue, values.images)}
                                        </div>
                                        <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
                                    </div>
                                    {values.images.filter((_:any, i:any) => i >= 1 && i < values.images.length).map((item:any, index:any) => (
                                        <div
                                            key={index}
                                            className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                                                index >= 3 ? "hidden sm:block" : ""
                                            }`}
                                        >
                                            <NcImage
                                                containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                                                className="object-cover w-full h-full rounded-md sm:rounded-xl "
                                                src={URL.createObjectURL(item)}
                                            />

                                            {/* OVERLAY */}
                                            <div
                                                className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                                                onClick={() => handleOpenModal(index + 1)}
                                            />
                                            <div>
                                                {removeButton(item.id, setFieldValue, values.images)}
                                            </div>
                                        </div>
                                    ))}

                                    <div
                                        className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
                                        onClick={() => handleOpenModal(0)}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-5 w-5"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={1.5}
                                                d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                                            />
                                        </svg>
                                        <span className="ml-2 text-neutral-800 text-sm font-medium">
                Show all photos
              </span>
                                    </div>
                                </div>
                            </header>

                            <ModalPhotos
                                imgs={values.images.map(function (obj:any){return URL.createObjectURL(obj)})}
                                isOpen={isOpen}
                                onClose={handleCloseModal}
                                initFocus={openFocusIndex}
                                uniqueClassName="nc-StudioProfilePage-modalPhotos"
                            />
                        </div> : <span>No Images uploaded</span>}
                        <Navigation prev={"/become-artist-listing-3"} nextBtnText={'Create'} />

                    </Form>

                )}
            </Formik>
        </CommonLayout>
    );
};

export default BecomeArtistListing4;
