import {axiosReq} from "../store";

export async function fetchLandingPageData () {

  let tattoosOfTheWeek = [];
  let studios = [];
  let artists = [];

  try {
    let req = await axiosReq.get('/api/get-landing-page-data');

    tattoosOfTheWeek = req.data.landingData.tattoosOfTheWeek;
    studios = req.data.landingData.studios;
    artists = req.data.landingData.artists;


  }catch (e){
    console.log(e)
  }

  return {tattoosOfTheWeek, studios, artists};
}

export async function browseArtistsByStyle(styleId: number) {

  let artists = []
  try {
    let req = await axiosReq.get('/api/browse-by-style?styleId=' + styleId);

    artists = req.data.artists;

  }catch (e){
    console.log(e)
  }
  return {artists}
}
