import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import React, {FC, useEffect, useState} from "react";
import { Helmet } from "react-helmet";
import LocationInput from "../../components/Location/LocationInput";
import Heading from "../../components/Heading/Heading";
import ListStudiosCard from "../../components/Studios/ListStudiosCard";
import {studiosFilter} from "../../services/studiosFilter";
import StylesSlider from "../../components/BrowseByStyle/StylesSlider";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";

export interface ListingStayPageProps {
    className?: string;
}

const ListingStayPage: FC<ListingStayPageProps> = ({ className = "" }) => {
    const [studios, setStudios] = useState([]);
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [styles, setStyles] = useState([]);
    const [studiosLoading, setStudiosLoading] = useState(false)
    useEffect(() => {
        (async () => {
            await studiosDataFN('', '', JSON.stringify(styles));

        })()
    }, [])

    const studiosDataFN = async (city: string, country: string, styles: string) => {
        await setStudiosLoading(true)

        try {
            const studiosData = await studiosFilter(city, country, styles);

            setStudios(studiosData.studios)

        } catch (err) {
            console.log(err);
        }
        await setStudiosLoading(false)

        return [];
    }

    return (
        <div
            className={`nc-ListingStayPage relative overflow-hidden ${className}`}
            data-nc-id="ListingStayPage"
        >
            <Helmet>
                <title>Tattoohub || Book your next tattoo</title>
            </Helmet>
            <BgGlassmorphism />

            <div className="container relative overflow-hidden">
                <LocationInput
                    onSelectFN={async (city: string, country: string) => {
                        setCity(city);
                        setCountry(country)
                        await studiosDataFN(city, country, JSON.stringify(styles))
                    }}
                    clearFunction={async () => {
                        await studiosDataFN('', '', JSON.stringify(styles))
                    }}
                />

                <div className="relative py-16 mb-24">
                    <BackgroundSection />
                    <StylesSlider
                        selectedIndex={styles}
                        onClickFunction={async (styles: any) => {
                            setStyles(styles)
                            await studiosDataFN(city, country, JSON.stringify(styles))
                        }}
                        heading="Browse by Style"
                        subHeading="Find studios by your preferred style"
                        categoryCardType="card5"
                        itemPerRow={5}
                        uniqueClassName="LadingPage_s3"
                    />
                </div>

                {/* SECTION */}
                <div className="relative py-16">
                    <BackgroundSection />
                    <div>
                        <Heading>Find your studio</Heading>
                        <ListStudiosCard studios={studios} />
                    </div>
                    {/*<SectionGridFeaturePlaces />*/}
                </div>
                {/*<SectionGridFilterCard className="pb-24 lg:pb-32" />*/}

                {/* SECTION 1 */}


                {/* SECTION */}
                {/* <SectionSubscribe2 className="py-24 lg:py-32" /> */}

                {/* SECTION */}
                {/* <div className="relative py-16 mb-24 lg:mb-32">
                    <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
                    <SectionGridAuthorBox />
                </div> */}
            </div>
            {studiosLoading ? <LoadingSpinner /> : null}
        </div>
    );
};

export default ListingStayPage;
