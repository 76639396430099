import SectionHero from "components/SectionHero/SectionHero";
import {FC} from "react";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionVideos from "./SectionVideos";
import { Helmet } from "react-helmet";
import { useEffect, useState } from 'react';
import {fetchLandingPageData} from "../../services/landingPage";
import TattooContestCard from "../../components/Tattoos/TattoosOfTheWeekCard";
import Heading from "../../components/Heading/Heading";
import SectionGridArtistBox from "../../components/Artists/SectionGridArtistBox";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import HIW1img from "images/HIW2-1.png";
import HIW2img from "images/HIW2-2.png";
import HIW3img from "images/HIW2-3.png";
import HIW1imgDark from "images/HIW2-1-dark.png";
import HIW2imgDark from "images/HIW2-2-dark.png";
import HIW3imgDark from "images/HIW2-3-dark.png";

export interface LandingPageProps {

}

const LandingPage: FC<LandingPageProps> = () => {

  const [tattoosOfTheWeek, setTattoosOfTheWeek] = useState<any>([])
  const [studios, setStudios] = useState([])
  const [artists, setArtists] = useState<any>([]);

  useEffect(() => {
    landingPageData();
   }, [])

  const landingPageData = async () => {
    try {
      const landingPage = await fetchLandingPageData();

      setTattoosOfTheWeek(landingPage.tattoosOfTheWeek)
      setStudios(landingPage.studios)
      setArtists(landingPage.artists)

    } catch (err) {
      console.log(err);
    }

    return [];
  }

  // const browseByStyle = async (styleId: number) => {
  //   try {
  //     const artists = await browseArtistsByStyle(styleId);
  //     setArtists(artists.artists);
  //   }catch (e){
  //     console.log(e)
  //   }
  // }



  return (
    <div className="nc-LadingPage relative overflow-hidden">
      <Helmet>
        <title>Tattoohub | Book your next tattoo</title>
      </Helmet>
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      <div className="container relative space-y-24 mb-24 lg:space-y-32 lg:mb-32">
        {/* SECTION HERO */}
        <SectionHero className="pt-10 lg:pt-20 pb-16" />



          {/* SECTION TATTOOS OF THE WEEK */}
        <div>
          {/* <Heading>Tattoos of the week</Heading> */}
          <Heading
          desc=""
          hasNextPrev={true}
          isCenter={false}
         >
          Tattoos of the week
        </Heading>
          <TattooContestCard tattoosOfTheWeek={tattoosOfTheWeek} />
        </div>

        {/* SECTION TATTOOS OF THE WEEK END*/}

        {/* <Link to={'/artists-search'} className="flex flex-col md:flex-row"> */}
          {/* <StylesSlider
              selectedIndex={['0']}
              onClickFunction={(styles: any) => {

              }}
              heading="Browse by Style"
              subHeading="Find artists by your preferred style"
              categoryCardType="card5"
              itemPerRow={5}
              uniqueClassName="LadingPage_s3"
          /> */}
        {/* </Link> */}

         {/* SECTION */}
         <div className="relative py-16 mb-24 lg:mb-32">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionGridArtistBox artists={artists} showMeMore={true}/>
        </div>

         {/* SECTION  HOW IT WORKS*/}
         <SectionHowItWork
          data={[
            {
              id: 1,
              img: HIW1img,
              imgDark: HIW1imgDark,
              title: "Pick your artist",
              desc: "From the number of options our app will provide, you can select any artist that you like to explore.",
            },
            {
              id: 2,
              img: HIW2img,
              imgDark: HIW2imgDark,
              title: "Describe your tattoo",
              desc: "Provide a details for your tattoo",
            },
            {
              id: 3,
              img: HIW3img,
              imgDark: HIW3imgDark,
              title: "Book appointment",
              desc: "Book your appointment directly through Tattoohub at a time that works for you.",
            },
          ]}
        />


        {/* SECTION HOW IT WORKS END*/}

        {/* SECTION ARTISTS*/}
        {/* <div className="relative py-16">
            <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
            <ListArtistBox artists={artists} />
            {artistLoading ? <LoadingSpinner /> : null}
        </div> */}

        {/* SECTION */}

         {/* SECTION */}
         <SectionVideos />

        {/* SECTION */}

          {/* SECTION FIND STUDIO */}
          {/* <div className="relative py-16">
          <BackgroundSection />
          <div>
           <HeaderFilter
              tabActive={"New York"}
              //subHeading="test"
              tabs = {[]}
              heading="Featured studios"
              onClickTab={() => {navigate.push('/search-studios')}}
            />
            <ListStudiosCard studios={studios} />
            <div className="flex mt-16 justify-center items-center">
              <ButtonPrimary onClick={() => {
                navigate.push('/search-studios')
              }} >Show me more</ButtonPrimary>
          </div>
          </div>
        </div> */}

         {/* SECTION FIND STUDIO END*/}

        {/* <SectionGridCategoryBox /> */}

        {/* SECTION */}
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionBecomeAnAuthor />
        </div> */}


        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="LadingPage_" />
        </div> */}
      </div>
    </div>
  );
}

export default LandingPage;
