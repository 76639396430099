import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import StatusUpcoming from "../../containers/Contests/StatusUpcoming";
import StatusActive from "../../containers/Contests/StatusActive";
import contests from "../../services/contests";
import ContestMainInfo from "../../containers/Contests/ContestMainInfo";
import LoadingSpinner from "../Loading/LoadingSpinner";
import StatusFinished from "../../containers/Contests/StatusFinished";

const ContestDetails = () => {
    const nav = useHistory();
    const params:any = useParams();
    const id:number = params.id;

    const [requestLoading, setRequestLoading] = useState(false);
    const [contest, setContest] = useState<any>(null)

    useEffect(() => {
        (async () => {
            await setRequestLoading(true)
            let req:any = await contests.getContestMainData(id, nav);
            await setContest(req.contest)
            await setRequestLoading(false)
        })()
    }, [])
    return (
        <div>
            {requestLoading ? <LoadingSpinner /> : null}
            {contest ? <div>
                <ContestMainInfo id={contest.id} imageUrl={contest.imageUrl} name={contest.name} status={contest.status} startDate={contest.startDate} endDate={contest.endDate} />
                {contest.status === "upcoming" ? <StatusUpcoming contest={contest}/> : null}
                {contest.status === "active" ? <StatusActive id={id}/> : null}
                {contest.status === "finished" ? <StatusFinished id={id} /> : null}
            </div> : null}

        </div>
    );
};

export default ContestDetails;
