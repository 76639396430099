import React, { FC } from "react";
import {authWithGoogle} from "../../store/auth-actions";
import {toast} from "react-toastify";
import {useAppDispatch} from "../../hooks/redux-hooks";
import {useHistory} from "react-router-dom";
import {GoogleLogin} from "@react-oauth/google";

export interface GoogleLoginProps {
    className?: string;
}

const GoogleLoginBtn: FC<GoogleLoginProps> = ({ className = "" }) => {
    const dispatch=useAppDispatch();
    const navigate = useHistory();

    return (
        <GoogleLogin
            useOneTap={false}
            containerProps={{
                style: {
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center'
                }
            }}
            onSuccess={res => {
                (async () => {
                    if (res && res.credential){
                        await dispatch(authWithGoogle(res.credential, navigate, "/dashboard"));
                    }else{
                        toast.error("An error occurred. Please try again", {
                            position: toast.POSITION.TOP_CENTER
                        });
                    }
                })()

            }}
            onError={() => {
                toast.error("An error occurred. Please try again", {
                    position: toast.POSITION.TOP_CENTER
                });
            }}
        />
    );
};

export default GoogleLoginBtn;
