import React, { FC } from "react";
import {api_url} from "../../contains/contants";
import GallerySlider from "../GallerySlider/GallerySlider";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useAppSelector } from "../../hooks/redux-hooks";

interface Studio {
    id: string | number,
    studioName: string,
    address: string,
    studioId: string | number,
    redirectUrl: string
}

export interface StudiosList {
    studios: Studio[];
}

const useRedirectToApp = () => {
    const token = useAppSelector((state) => state.auth.token);
    const redirectToApp = () => {
      window.location.href =
        api_url + "/studio/createMiddleware?t=" + token + "&redirect=/app/dashboard";
    };
    return redirectToApp;
  };

const AccountStudiosCard: FC<StudiosList> = ({studios}) => {
    const redirectToApp = useRedirectToApp();

    const renderContent = (item: any) => {

        return (
            <div  className={"p-4 space-y-4"}>
                <div className="space-y-2">
                    <div className="flex items-center space-x-2">
                        <h2 className={"text-lg" }>
                            <span className="line-clamp-1">{item.studioName}</span>
                        </h2>
                    </div>
                    <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={1.5}
                                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                />
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={1.5}
                                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                            </svg>
                        <span className="">{item.address}</span>
                    </div>
                </div>
                    <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
                    <div className="flex justify-between items-center">
                    <a href={item.redirectUrl}>
                    <ButtonPrimary onClick={redirectToApp}>Go to Studio Dashboard</ButtonPrimary>
                    </a>
                </div>
            </div>
        );
    };

    const renderSliderGallery = (item: any) => {
        return (
            <div className="relative w-full">
                <GallerySlider
                    uniqueID={`StayCard_${item.id}`}
                    ratioClass="aspect-w-4 aspect-h-3 "
                    galleryImgs={item.images}
                />
            </div>
        );
    };

    return (
        <div  className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-4 md:gap-8 ">
            {studios.map((item: any) => {
                return(
                    <div
                        className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow`}
                        data-nc-id="StayCard"
                        key={item.id}
                    >
                        <div>
                            {renderSliderGallery(item)}
                        </div>
                        <div style={{cursor: 'pointer'}} >
                            {renderContent(item)}
                        </div>


                    </div>
                )
            })}
        </div>

    );
};

export default AccountStudiosCard;


