import React, {useEffect} from "react";
import {  useHistory  } from "react-router-dom";
import CommonLayout from "../PageAddListing1/CommonLayout";
import Input from "../../shared/Input/Input";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import {crateStudioStepsLength, facebookURL, instagramURL} from "../../contains/contants";
import Navigation from "../../components/Listing/NavBtn";
import { useAppDispatch,useAppSelector } from "../../hooks/redux-hooks";
import {setWebSiteURL, setFacebook, setInstagram, setStepCompleted} from "../../store/create-studio-slice";

const CreateStudioListing3 = () => {
    const navigate = useHistory();
    const dispatch = useAppDispatch();
    const currentStep = 4;
    const stepCompleted = useAppSelector((state)=>state.createStudio.stepCompleted);

    useEffect(() => {
        if (stepCompleted < currentStep - 1){
            navigate.push('/dashboard')
        }
    }, [])

    const initialValues = {
        websiteURL:  useAppSelector((state)=>state.createStudio.webSiteURL),
        facebook:   useAppSelector((state)=>state.createStudio.facebook),
        instagram:  useAppSelector((state)=>state.createStudio.instagram),
    }

    const validationSchema = Yup.object().shape({
        websiteURL: Yup.string(),
        facebook: Yup.string(),
        instagram: Yup.string()
    });

    const handleLogin = async (formValue: { websiteURL: string; facebook: string; instagram: string}) : Promise<void> => {
        await dispatch(setWebSiteURL(formValue.websiteURL));
        await dispatch(setFacebook(formValue.facebook));
        await dispatch(setInstagram(formValue.instagram));
        if (stepCompleted < currentStep){
            await dispatch(setStepCompleted(currentStep))
        }
        navigate.push("/create-studio-listing-4")
    };

    return (
        <CommonLayout
            index={"0" + currentStep}
            steps={'0' + crateStudioStepsLength}
        >
            <>
                <h2 className="text-2xl font-semibold">Studio Contacts</h2>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

                {/* FORM */}
                <div className="space-y-8">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleLogin}
                    >
                        {({ values, setFieldValue, handleChange, errors, touched }) => (
                            <Form className="grid grid-cols-1 gap-6">
                                <label className="block">
                                    <span className="text-neutral-800 dark:text-neutral-200">Website URL</span>
                                    <Input
                                        type="text"
                                        name="websiteURL"
                                        value={values.websiteURL}
                                        onChange={handleChange}
                                        placeholder="Website URL"
                                        className="mt-1"
                                    />
                                    <ErrorMessage name="websiteURL" component="div" className="text-red-500 text-xs italic"/>
                                </label>
                                <label className="block">
                                    <span className="text-neutral-800 dark:text-neutral-200">Facebook</span>
                                    <Input
                                        type="text"
                                        name="facebook"
                                        value={facebookURL + values.facebook}
                                        onChange={(val) => {
                                            if  (val.target.value.indexOf(facebookURL) === 0){
                                                setFieldValue('facebook', val.target.value.replace(facebookURL, ''))
                                            }
                                        }}
                                        placeholder="Facebook"
                                        className="mt-1"
                                    />
                                    <ErrorMessage name="facebook" component="div" className="text-red-500 text-xs italic"/>
                                </label>
                                <label className="block">
                                    <span className="text-neutral-800 dark:text-neutral-200">Instagram</span>
                                    <Input
                                        type="text"
                                        name="instagram"
                                        value={instagramURL + values.instagram}
                                        onChange={(val) => {
                                            if  (val.target.value.indexOf(instagramURL) === 0){
                                                setFieldValue('instagram', val.target.value.replace(instagramURL, ''))
                                            }
                                        }}
                                        placeholder="Instagram"
                                        className="mt-1"
                                    />
                                    <ErrorMessage name="instagram" component="div" className="text-red-500 text-xs italic"/>
                                </label>
                                <Navigation prev={"/create-studio-listing-2"} />

                            </Form>

                        )}
                    </Formik>

                </div>
            </>
        </CommonLayout>
    );
};

export default CreateStudioListing3;
