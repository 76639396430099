import Heading from "components/Heading/Heading";
import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import CardArtistBox from "./CardArtistBox";
import {useHistory} from "react-router-dom";

interface Artist {
  id: string | number,
  fullName: string,
  avatarUrl: string,
  location: string,
  bgImage?: string;
  username: string;
  href: string;
}

export interface SectionGridAuthorBoxProps {
  artists: Artist[];
  showMeMore: boolean
}

const SectionGridAuthorBox: FC<SectionGridAuthorBoxProps> = ({artists, showMeMore},
) => {
  const navigate = useHistory();
  return (
    <div
      className={`nc-SectionGridAuthorBox relative`}
      data-nc-id="SectionGridAuthorBox"
    >
      <Heading desc="" isCenter={false}>
        Verified artists
      </Heading>
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 md:gap-8 ">
        {artists.map((artist, index) =>
          <CardArtistBox key={artist.id} artist={artist} />
        )}
      </div>

      {showMeMore === true &&
      <div className="mt-16 flex items-center justify-center space-x-5">
        <ButtonPrimary onClick={() => {
                navigate.push('/artists-search')
              }} >Show me more</ButtonPrimary>
      </div>
    }
    </div>
  );
};

export default SectionGridAuthorBox;
