import React, {FC, useState} from "react";
import {Helmet} from "react-helmet";
import {ErrorMessage, Form, Formik} from "formik";
import Input from "../../shared/Input/Input";
import {useHistory, useParams} from "react-router-dom";
import ButtonPrimary from "../../shared/Button/ButtonPrimary";
import * as Yup from "yup";
import forgotPass from "../../services/forgotPass";
import {toast} from "react-toastify";
import CustomError from "../../components/Error/CustomError";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";

export interface ForgotChangeForgotPassProps {
    className?: string;
}

const ChangeForgotPass: FC<ForgotChangeForgotPassProps> = ({ className = "" }) => {
    const navigate = useHistory();
    const params:any = useParams();
    const token = params.token;

    const [requestLoading, setRequestLoading] = useState(false);
    const [tokenExpired, setTokenExpired] = useState(false);
    const [passwordsNotMatch, setPasswordsNotMatch] = useState(false);
    const [reqError, setReqError] = useState(false);

    const validationSchema = Yup.object().shape({
        password: Yup.string().min(8, "New password must be at least 8 characters").required("Password is required!"),
        confirmPassword: Yup.string().min(8, "New password must be at least 8 characters")
            .required("Confirm password is required!")
            .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    });

    const initialValues = {
        password: "",
        confirmPassword: ""
    };

    const handleLogin = async (formValue: {password: string, confirmPassword: string }) : Promise<void> => {
        await setRequestLoading(true);
        await setTokenExpired(false);
        await setPasswordsNotMatch(false);
        await setReqError(false);

        let request = await forgotPass.changePassword(formValue.password, formValue.confirmPassword, token);
        if (request.response === "success"){
            toast.success("Password changed successfully", {
                position: toast.POSITION.TOP_CENTER
            });
            navigate.push("/login")
        }else if(request.response === "tokenExpired"){
            await setTokenExpired(true);
        }else if(request.response === "passwordsNotMatch"){
            await setPasswordsNotMatch(true);
        }else{
            await setReqError(true)
        }
        await setRequestLoading(false);
    };
    return (
        <div>
            <Helmet>
                <title>Enter new password || Tattoohub</title>
            </Helmet>
            <div className="container mb-24 lg:mb-32">
                <h2 className="my-10 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                    Enter your new password
                </h2>
                <div className="max-w-md mx-auto space-y-6">

                    {/* FORM */}
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleLogin}
                    >
                        {({ values, setFieldValue, handleChange, errors, touched }) => (
                            <Form className="grid grid-cols-1 gap-6">
                                <label className="block">
                                    <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                                      Password
                                    </span>
                                    <Input
                                        type="password"
                                        name="password"
                                        value={values.password}
                                        onChange={handleChange}
                                        className="mt-1" />
                                    <ErrorMessage name="password" component="div" className="text-red-500 text-xs italic"/>
                                </label>
                                <label className="block">
                                    <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                                      Confirm Password
                                    </span>
                                    <Input
                                        type="password"
                                        name="confirmPassword"
                                        value={values.confirmPassword}
                                        onChange={handleChange}
                                        className="mt-1" />
                                    <ErrorMessage name="confirmPassword" component="div" className="text-red-500 text-xs italic"/>
                                </label>
                                <ButtonPrimary type="submit" >Change password</ButtonPrimary>
                                {tokenExpired ? <CustomError text={"Is seems that this password recovery email is expired"} /> : null}
                                {passwordsNotMatch ? <CustomError text={"Is seems that passwords not match"} /> : null}
                                {reqError ? <CustomError text={"An error occurred while processing your request, Please refresh and try again"} /> : null}
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
            {requestLoading ? <LoadingSpinner /> : null}
        </div>
    );
};

export default ChangeForgotPass;
