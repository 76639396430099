import React, { useEffect } from "react";
import SectionAwards from "components/ForTattooers/SectionAwards";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import FAQ from "components/ForTattooers/FAQ"
import { Helmet } from "react-helmet";
import SectionTattooContest from "components/SectionHero/SectionTattooContest";
import HIW1img from "images/HIW2-1.png";
import HIW2img from "images/HIW2-2.png";
import HIW3img from "images/HIW2-3.png";
import HIW2imgDark from "images/HIW2-2-dark.png";
import HIW3imgDark from "images/HIW2-3-dark.png";
import Heading from "shared/Heading/Heading";

function TattooContest() {
  useEffect(() => {
    const $body = document.querySelector("body");
    if ($body) {
      $body.className = "theme-cyan-blueGrey";
    }
    return () => {
      if ($body) {
        $body.className = "";
      }
    };
  }, []);


  return (
    <div className="nc-PageHome2 relative overflow-hidden">
      <Helmet>
        <title>Tattoo Contests For Tattooers</title>
      </Helmet>
      {/* GLASSMOPHIN */}
      {/* <BgGlassmorphism /> */}

      <div className="container relative space-y-24 mb-24 lg:space-y-32 lg:mb-32">
        {/* <SectionHero2 className="lg:mt-2" /> */}
        <div className="container px-1 sm:px-4 mb-24 ">
          <SectionTattooContest className="" />
        </div>

        {/* SECTION 1 */}
        {/* <div className="ncSectionLogos grid grid-cols-3 lg:grid-cols-5 gap-5 sm:gap-16">
          <div className="flex items-end justify-center">
            <img className="block dark:hidden" src={logo1} alt="logo1" />
            <img className="hidden dark:block" src={logo1Dark} alt="logo1" />
          </div>
          <div className="flex items-end justify-center">
            <img className="block dark:hidden" src={logo4} alt="logo4" />
            <img className="hidden dark:block" src={logo4Dark} alt="logo4" />
          </div>
          <div className="flex items-end justify-center">
            <img className="block dark:hidden" src={logo2} alt="logo2" />
            <img className="hidden dark:block" src={logo2Dark} alt="logo2" />
          </div>
          <div className="flex items-end justify-center">
            <img className="block dark:hidden" src={logo3} alt="logo3" />
            <img className="hidden dark:block" src={logo3Dark} alt="logo3" />
          </div>

          <div className="flex items-end justify-center">
            <img className="block dark:hidden" src={logo5} alt="logo5" />
            <img className="hidden dark:block" src={logo5Dark} alt="logo5" />
          </div>
        </div> */}

          {/* SECTION2 */}
          <SectionAwards />

         {/* SECTION */}
         <SectionHowItWork
          data={[
            {
              id: 1,
              img: HIW1img,
              imgDark: "null",
              title: "Sign up and set up your artist profile.",
              desc: "",
            },
            {
              id: 2,
              img: HIW2img,
              imgDark: HIW2imgDark,
              title: "Upload your best tattoo image to the contest",
              desc: "",
            },
            {
              id: 3,
              img: HIW3img,
              imgDark: HIW3imgDark,
              title: "Hold tight and await the contest results",
              desc: "",
            },
          ]}
        />



      

         {/* SECTION2 */}
         <div className={`nc-SectionLatestPosts relative`}>
      <div className="flex flex-col lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 xl:pr-14">
          <Heading desc="">Frequently Asked Questions (FAQs)</Heading>
          <div className={`grid gap-6 md:gap-8 grid-cols-1`}>
          <FAQ title="How do I enter the contest?" description="Simply register on our platform, set up your artist profile, and upload your chosen tattoo image to the contest."/>
          <FAQ title="Is there an entry fee to participate?" description="No, participation in the contest is free of charge."/>
          <FAQ title="Can I submit multiple tattoo images?" description="No, each artist can submit up to one tattoo image. "/>
          <FAQ title="What are the image requirements for submission?" description="Images should be in JPEG or PNG format, with a minimum resolution of 300 dpi. The maximum file size allowed is 5MB."/>
          <FAQ title="How will I know if I've won?" description="Winners will be announced on our website and contacted directly via the email provided during registration."/>
          
          </div>
          <div className="flex flex-col mt-12 md:mt-20 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">

          </div>
        </div>
      </div>
    </div>
      
         {/* <SectionOurFeatures type="type1" rightImg={rightImgPng} /> */}

        {/* SECTION 1 */}
        {/* <SectionSliderNewCategories
          categories={DEMO_CATS_2}
          categoryCardType="card4"
          itemPerRow={4}
          heading="Suggestions for discovery"
          subHeading="Popular places to stay that Chisfis recommends for you"
          uniqueClassName="PageHome2_s1"
        /> */}

        {/* SECTION */}
        {/* <div className="relative py-16">
          <BackgroundSection className="bg-neutral-100 dark:bg-black dark:bg-opacity-20 " />
          <SectionGridAuthorBox boxCard="box2" />
        </div> */}

        {/* SECTION 1 */}
        {/* <SectionSliderNewCategories
          heading="Explore by types of stays"
          subHeading="Explore houses based on 10 types of stays"
          categoryCardType="card5"
          itemPerRow={5}
          uniqueClassName="PageHome2_s2"
        /> */}

        {/* SECTION */}
        {/* <SectionSubscribe2 /> */}
      </div>
    </div>
  );
}

export default TattooContest;
