import React, { FC, Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import CommentListing from "components/CommentListing/CommentListing";
import StartRating from "components/StartRating/StartRating";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import NcImage from "shared/NcImage/NcImage";
import ModalPhotos from "./ModalPhotos";
import {fetchStudioPageDetails} from "../../services/studioProfilePage";
import {api_url, googleMapKey} from "../../contains/contants";
import { useHistory } from "react-router-dom";
import { useParams } from 'react-router-dom';
import {toast} from "react-toastify";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import { GoogleMap, useJsApiLoader,} from '@react-google-maps/api';
import {MarkerF} from '@react-google-maps/api'
import defaultAvatar from "images/avatars/default-avatar.png";

export interface StudioProfilePageProps {
  className?: string;
  isPreviewMode?: boolean;
}


const StudioProfilePage: FC<StudioProfilePageProps> = ({
  className = "",
  isPreviewMode,
}) => {
  let history = useHistory();

  let params:any = useParams();
  const usernameQuery = params.username;

  const [requestLoading, setRequestLoading] = useState(false)

  useEffect(() => {
    (async () => {
      try {
        await setRequestLoading(true)
        const studioPageDetails = await fetchStudioPageDetails(usernameQuery, history);

        await setPhotos(studioPageDetails.images);
        await setStudioRating(studioPageDetails.studioRating);
        await setReviews(studioPageDetails.reviews);
        await setServices(studioPageDetails.services);
        await setStudioStaff(studioPageDetails.studioStaff);
        await setStudioName(studioPageDetails.businessInfo.name);
        await setAddress(studioPageDetails.businessInfo.address);
        if (studioPageDetails.businessInfo.latitude && studioPageDetails.businessInfo.longitude){
          await setLat(parseFloat(studioPageDetails.businessInfo.latitude));
          await setLng(parseFloat(studioPageDetails.businessInfo.longitude));
        }
        await setStyles(studioPageDetails.styles);
        await setBookingHours(studioPageDetails.bookingHours);
        await setUsername(studioPageDetails.businessInfo.username);

        await setRequestLoading(false)

      } catch (err) {
        toast.error("Error while trying to get studio information", {
          position: toast.POSITION.TOP_CENTER
        });
      }
    })()
  }, [])

  const [isOpen, setIsOpen] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);

  let daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

  const [photos, setPhotos] = useState([]);
  const [studioRating, setStudioRating] = useState<number | undefined>(undefined);
  const [reviews, setReviews] = useState([]);
  const [services, setServices] = useState([]);
  const [studioStaff, setStudioStaff] = useState<any >([])
  const [studioName, setStudioName] = useState('');
  const [address, setAddress] = useState('');
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const [styles, setStyles] = useState([]);
  const [bookingHours, setBookingHours] = useState({});
  const [username, setUsername] = useState<string>('')


  let [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);


  function closeModalAmenities() {
    setIsOpenModalAmenities(false);
  }

  function openModalAmenities() {
    setIsOpenModalAmenities(true);
  }

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  const handleCloseModal = () => setIsOpen(false);

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">{studioName}</h2>

        <div className="flex items-center space-x-4">
          <StartRating point={studioRating} reviewCount={reviews.length}/>
          <span>·</span>
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1"> {address}</span>
          </span>
        </div>

        {/* <div className="flex items-center">
          <Avatar imgUrl={ownerAvatar ? api_url + '/staffAvatars/' + ownerId +'/thumbs/' + ownerAvatar : undefined} hasChecked sizeClass="h-10 w-10" radius="rounded-full" />
          <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
            Hosted by{" "}
            <span className="text-neutral-900 dark:text-neutral-200 font-medium">
              {ownerName}
            </span>
          </span>
        </div> */}

        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex items-center space-x-3 ">
            <i className=" las la-user-cog text-2xl "></i>
            <span className="">
              {studioStaff.length} <span className="hidden sm:inline-block">Members</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-cog text-2xl"></i>
            <span className=" ">
              {services.length} <span className="hidden sm:inline-block">Services</span>
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderSection3 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Styles </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            About the studio's styles and services
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          {styles.filter((_: any, i: any) => i < 12).map((item: any) => (
            <div key={item.name} className="flex items-center space-x-3">
              <i className={`text-3xl las ${item.icon}`}></i>
              <span className=" ">{item.name}</span>
            </div>
          ))}
        </div>

        {/* ----- */}
        <div className="w-14 border-b border-neutral-200"></div>
        <div>
          <ButtonSecondary onClick={openModalAmenities}>
            View all styles
          </ButtonSecondary>
        </div>
        {renderMotalAmenities()}
      </div>
    );
  };

  const renderMotalAmenities = () => {
    return (
      <Transition appear show={isOpenModalAmenities} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalAmenities}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full">
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Styles
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalAmenities} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                    {styles.filter((_, i) => i < 1212).map((item: any) => (
                      <div
                        key={item.name}
                        className="flex items-center py-6 space-x-8"
                      >
                        <i
                          className={`text-4xl text-neutral-6000 las ${item.icon}`}
                        ></i>
                        <span>{item.name}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const renderSection4 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Services</h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}
        <div className="flow-root">
          <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 -mb-4">
            {services.map((item: any, index) => {
              let className = "p-4  flex justify-between items-center space-x-4 rounded-lg";
              if (index % 2 === 0){
                className += " bg-neutral-100 dark:bg-neutral-800"
              }
              return(
                  <div key={item.id} className={className}>
                    <span>{item.name}</span>
                    <span>{item.formattedPrice}</span>
                  </div>
              )
            })}

          </div>
        </div>
      </div>
    );
  };

  const renderSection5 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Members</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3">

          {studioStaff.map((item: any, index: number) => {
            return(
                <div key={item.id} className=" w-full flex flex-col hover:shadow-xl items-center text-center sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-7 px-0 sm:p-6 xl:p-8">
                  <Avatar
                      imgUrl={item.artist && item.artist.avatarUrl ? api_url + item.artist.avatarUrl : defaultAvatar}
                      hasChecked
                      hasCheckedClass="w-6 h-6 -top-0.5 right-2"
                      sizeClass="w-28 h-28"
                  />
                  <div className="space-y-3 text-center flex flex-col items-center">
                    <h2 className="text-xl font-semibold">{item.userId.fullName}</h2>
                  </div>
                  <div className="space-y-4">
                    <div className="flex items-center space-x-4">
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6 text-neutral-400"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                      >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={1.5}
                            d="M18.0003,20.9998 C16.3453,20.9998 15.0003,19.6538 15.0003,17.9998 C15.0003,16.3458 16.3453,14.9998 18.0003,14.9998 C19.6543,14.9998 21.0003,16.3458 21.0003,17.9998 C21.0003,19.6538 19.6543,20.9998 18.0003,20.9998 L18.0003,20.9998 Z M24.0003,17.9998 L21.0003,17.9998 L24.0003,17.9998 Z M20.1213,20.1218 L22.2423,22.2428 L20.1213,20.1218 Z M18.0003,23.9998 L18.0003,20.9998 L18.0003,23.9998 Z M13.7573,22.2428 L15.8783,20.1208 L13.7573,22.2428 Z M12.0003,17.9998 L15.0003,17.9998 L12.0003,17.9998 Z M15.8783,15.8788 L13.7573,13.7578 L15.8783,15.8788 Z M18.0003,14.9998 L18.0003,11.9998 L18.0003,14.9998 Z M20.1213,15.8788 L22.2423,13.7578 L20.1213,15.8788 Z M12.5,12.5 C11.2660678,11.4458897 9.77508483,11 8,11 C4.13400675,11 1,13.0294373 1,18 L1,23 L11,23 M8,11 C10.7614237,11 13,8.76142375 13,6 C13,3.23857625 10.7614237,1 8,1 C5.23857625,1 3,3.23857625 3,6 C3,8.76142375 5.23857625,11 8,11 Z"
                        />
                      </svg>
                      <span className="text-neutral-6000 dark:text-neutral-300">
                        {item.roleId.name}
            </span>
                    </div>
                  </div>
                  {item.artist.username ? <ButtonPrimary  onClick={() => {
                    history.push('/artists/' + item.artist.username)
                  }}>
                    <span>View Profile</span>
                  </ButtonPrimary> : null}


                </div>
            )
          })}

        </div>
      </div>
    );
  };

  const renderSection6 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Reviews</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* comment */}
        <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
          {reviews.map((item: any, index) => {
            return(
                <CommentListing key={item.id} data={{
                  name: item.customer.fullName,
                  date: item.date,
                  comment: item.text,
                  starPoint: item.rating
                }} className="py-8"  />
            )
          })}
        </div>
      </div>
    );
  };

  const containerStyle = {
    width: '700px',
    height: '400px'
  };

  const center = {
    lat: lat,
    lng: lng
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: googleMapKey,
  })

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map: any) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    map.setZoom(15);

    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null)
  }, [])

  const renderSection7 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Location</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {address}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* MAP */}
        <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
          <div className="rounded-xl overflow-hidden">
            {isLoaded ? (
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                onLoad={onLoad}
                onUnmount={onUnmount}
            >
              <MarkerF position={center} />
            </GoogleMap>
            ) : <></>}
          </div>
        </div>
      </div>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="listingSectionSidebar__wrap shadow-xl">

        <div>
          <h4 className="text-lg font-semibold">Studio working time</h4>
          {daysOfWeek.map((item,index) => {
            let className = 'flex space-x-10 justify-between p-3 dark:bg-neutral-800 rounded-lg';
            if (index % 2 === 0){
              className += ' bg-neutral-100'
            }

            let time = [];
            if (!(item.toLowerCase() in bookingHours)){
              time.push(<span key={item + '-label'}>Closed</span>)
            }else{
              const key = Object.keys(bookingHours)[index] as keyof typeof bookingHours;
              let singleDay: any = bookingHours[key];
              for (let i = 0; i < singleDay.length; i++){
                time.push(<div key={item + 'label' + i} className="p-1">{singleDay[i].from} - {singleDay[i].to}</div>)
              }
            }
            return (
                    <div key={item + '-wrapper'} className="mt-3 text-neutral-500 dark:text-neutral-400 max-w-md text-sm sm:text-base">
                      <div  className={className}>
                        <span className="p-1">{item}</span>
                        <span>{time}</span>
                      </div>
                    </div>
            )
          })}

        </div>
        <ButtonPrimary onClick={() => window.location.href = "/studios/" + username + "/book"}>
          {/*<a target="_blank" rel="noreferrer" href={''}>Book</a>*/}
          Book
        </ButtonPrimary>
      </div>
    );
  };

  return (
    <div
      className={`nc-StudioProfilePage  ${className}`}
      data-nc-id="StudioProfilePage"
    >
      {requestLoading ? <LoadingSpinner /> : null}
      {/* SINGLE HEADER */}
      <>
        <header className="container 2xl:px-14 rounded-md sm:rounded-xl">
          <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
            <div
              className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
              onClick={() => handleOpenModal(0)}
            >
              <NcImage
                containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                className="object-cover w-full h-full rounded-md sm:rounded-xl"
                src={api_url + "/" + photos[0]}
              />
              <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            </div>
            {photos.filter((_, i) => i >= 1 && i < photos.length).map((item, index) => (
              <div
                key={index}
                className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                  index >= 3 ? "hidden sm:block" : ""
                }`}
              >
                <NcImage
                  containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                  className="object-cover w-full h-full rounded-md sm:rounded-xl "
                  src={item ? api_url + "/" + item : ""}
                />

                {/* OVERLAY */}
                <div
                  className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                  onClick={() => handleOpenModal(index + 1)}
                />
              </div>
            ))}

            <div
              className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
              onClick={() => handleOpenModal(0)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                />
              </svg>
              <span className="ml-2 text-neutral-800 text-sm font-medium">
                Show all photos
              </span>
            </div>
          </div>
        </header>
        {/* MODAL PHOTOS */}
        <ModalPhotos
          imgs={photos}
          isOpen={isOpen}
          onClose={handleCloseModal}
          initFocus={openFocusIndex}
          uniqueClassName="nc-StudioProfilePage-modalPhotos"
        />
      </>

      {/* MAIn */}
      <main className="container relative z-10 mt-11 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
          {renderSection1()}
          {renderSection4()}
          {renderSection5()}
          {renderSection3()}
          {renderSection6()}
          {renderSection7()}
        </div>

        {/* SIDEBAR */}
        <div className="block flex-grow mt-14 lg:mt-0">
          <div className="sticky top-24">{renderSidebar()}</div>
        </div>
      </main>

      {/* STICKY FOOTER MOBILE */}
      {/*{!isPreviewMode && (*/}
      {/*  <div className="block lg:hidden fixed bottom-0 inset-x-0 py-4 bg-white text-neutral-900 border-t border-neutral-200 z-20">*/}
      {/*    <div className="container flex items-center justify-between">*/}
      {/*      <span className="text-2xl font-semibold">*/}
      {/*        $311*/}
      {/*        <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">*/}
      {/*          /night*/}
      {/*        </span>*/}
      {/*      </span>*/}

      {/*      <ButtonPrimary href="##">Reserve</ButtonPrimary>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*)}*/}

    </div>
  );
};

export default StudioProfilePage;
