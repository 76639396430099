import Label from "components/Label/Label";
import React, {useEffect, useState} from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import {updatePassword} from "../../services/userService";
import { toast } from 'react-toastify';
import {  useHistory  } from "react-router-dom";
import { useAppSelector } from "../../hooks/redux-hooks";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";

const AccountPass = () => {
  const navigate = useHistory();
  const isLoggedIn = useAppSelector((state)=>state.auth.isLoggedIn);
  const currentUser = useAppSelector((state)=>state.auth.user);
  const token = useAppSelector((state)=>state.auth.token);
  const [requestLoading, setRequestLoading] = useState(false);

  useEffect(() => {
    if(isLoggedIn === false){
      navigate.push("/login")
    }
  }, []);

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().min(8, "Current password must be at least 8 characters").required("Current password is required!"),
    newPassword: Yup.string().min(8, "New password must be at least 8 characters").required("New password is required!"),
    confirmNewPassword: Yup.string().min(8, "New password must be at least 8 characters").required("Confirm password is required!")
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
  });

  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: ""
  }

  const handleUpdatePassword = async (formValue: { oldPassword: string; newPassword: string, confirmNewPassword: string }) : Promise<void> => {
    if(formValue.newPassword !== formValue.confirmNewPassword){
      toast.error("Passwords do not match", {
        position: toast.POSITION.TOP_CENTER
      });
      return;
    }

    await setRequestLoading(true)
    var request = await updatePassword(formValue.oldPassword, formValue.newPassword, currentUser.id, token);
    if(request === true){
      toast.success("Your password was successfully changed", {
        position: toast.POSITION.TOP_CENTER
      });
    }else{
      toast.error("Your password was not changed", {
        position: toast.POSITION.TOP_CENTER
      });
    }
    await setRequestLoading(false)
  };

  return (
    <div>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Update your password</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className=" max-w-xl space-y-6">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, {resetForm, }) => {
              handleUpdatePassword(values);
              resetForm();
            }}
          >
          {({ values, setFieldValue, handleChange, errors, touched }) => (
              <Form className="grid grid-cols-1 gap-6">
            <div>
              <Label>Current password</Label>
              <Input
                type="password"
                className="mt-1.5"
                name="oldPassword"
                value={values.oldPassword}
                onChange={handleChange}/>
                 <ErrorMessage name="oldPassword" component="div" className="text-red-500 text-xs italic"/>
            </div>

            <div>
              <Label>New password</Label>
              <Input type="password"
                className="mt-1.5"
                name="newPassword"
                value={values.newPassword}
                onChange={handleChange}
              />
              <ErrorMessage name="newPassword" component="div" className="text-red-500 text-xs italic"/>
            </div>
            <div>
              <Label>Confirm password</Label>
              <Input type="password"
              className="mt-1.5"
              name="confirmNewPassword"
              value={values.confirmNewPassword}
              onChange={handleChange}
              />
               <ErrorMessage name="confirmNewPassword" component="div" className="text-red-500 text-xs italic"/>
            </div>
            <div className="pt-2">
              <ButtonPrimary type="submit">Update password</ButtonPrimary>
            </div>
            </Form>
              )}
              </Formik>
          </div>
        </div>
        {requestLoading ? <LoadingSpinner /> : null}
      </CommonLayout>
    </div>
  );
};

export default AccountPass;
