import React, { useState, useEffect } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import CommonLayout from "./CommonLayout";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../hooks/redux-hooks";
import { getCurrentUserStudios } from "../../services/studioServoce";
import AccountStudiosCard from "../../components/Studios/AccountStudiosCard";
import {toast} from "react-toastify";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";

const Dashboard = () => {
  const navigate = useHistory();
  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
  const isTattooArtist = useAppSelector((state) => state.auth.isTattooArtist);
  const userId = useAppSelector((state) => state.auth.user.id);
  const token = useAppSelector((state) => state.auth.token);

  const [studios, setStudios] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isLoggedIn === false) {
      navigate.push("/login")
    }

    (async () => {
      await setIsLoading(true)
      let req = await getCurrentUserStudios(userId, token);
      if (req.response === "success"){
        setStudios(req.studios)
      }else{
        toast.error("An error occurred. Please try again", {
          position: toast.POSITION.TOP_CENTER
        });
      }
      await setIsLoading(false)

    })()

  }, [])

  return (
    <div>
      <CommonLayout>
        {isLoading ? <LoadingSpinner /> : null}
        {studios === null || studios.length
         === 0 ? null :
          <div className="space-y-6 sm:space-y-8">
            <div>
              <h2 className="text-3xl font-semibold">Studios</h2>
            </div>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

            <div>
              <AccountStudiosCard studios={studios} />
            </div>
          </div>
        }
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          {/* <h2 className="text-3xl font-semibold">Payments & payouts</h2> */}
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          {isTattooArtist ? null : <div className="max-w-2xl">
            <span className="text-xl font-semibold block">Create Artist Profile</span>
            <br />
            <span className="text-neutral-700 dark:text-neutral-300 block">
            List yourself as a tattoo artist on Tattoohub and connect with potential clients.
              <br />
            </span>
            <div className="pt-10">
              <ButtonPrimary onClick={() => {
                navigate.push('/become-artist-listing-1')
              }}>Become Artist</ButtonPrimary>
            </div>
          </div>}

          <br />
          <br />
          {studios.length > 0 ? null :
          <div className="max-w-2xl">
            <span className="text-xl font-semibold block">Create Studio Profile</span>
            <br />
            <span className="text-neutral-700 dark:text-neutral-300 block">
            Join Tattoohub as a studio and manage your bookings efficiently.
              {/* When you receive a payment for a reservation, we call that payment
              to you a "payout." Our secure payment system supports several
              payout methods, which can be set up below. Go to FAQ.
              <br />
              <br />
              To get paid, you need to set up a payout method Airbnb releases
              payouts about 24 hours after a guest’s scheduled check-in time.
              The time it takes for the funds to appear in your account depends
              on your payout method. Learn more */}
            </span>
            <div className="pt-10">
              <ButtonPrimary onClick={() => {
                navigate.push('/create-studio-select-plan')
              }}>Create Studio</ButtonPrimary>

            </div>
          </div>
          }
        </div>
      </CommonLayout>
    </div>
  );
};

export default Dashboard;
