import React, {useEffect} from "react";
import { FC } from "react";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "../../hooks/redux-hooks";
import {logout} from '../../store/auth-actions';
import {createStudioCleanState} from "../../store/create-studio-actions";
import {becomeArtistCleanState} from "../../store/become-artist-actions";

export interface PageLogoutProps {
  children?: React.ReactNode;
}

const PageLogout: FC<PageLogoutProps> = ({ children }) => {
  const dispatch=useAppDispatch();

    const navigate = useHistory();
    useEffect(() => {
      dispatch(logout());
      dispatch(becomeArtistCleanState())
      dispatch(createStudioCleanState())
      //authService.logout();
        navigate.push("/login")
      }, [])
  return (
    <div></div>
  );
};

export default PageLogout;
