import React, {useState} from "react";
import {Link, useHistory} from "react-router-dom";
import "../../../styles/inviteScreens.css"
import Logo from "shared/Logo/Logo";
import ButtonPrimary from "../../../shared/Button/ButtonPrimary";
import {ErrorMessage, Form, Formik} from "formik";
import Input from "../../../shared/Input/Input";
import * as Yup from "yup";
import {register} from "../../../store/auth-actions";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux-hooks";
import LoadingSpinner from "../../../components/Loading/LoadingSpinner";

const InvitesSignUp = () => {
    const dispatch = useAppDispatch();
    const navigate = useHistory()
    const [requestLoading, setRequestLoading] = useState(false)

    const validationSchema = Yup.object().shape({
        email: Yup.string().email().required("Email address is required!"),
        password: Yup.string().min(8, "New password must be at least 8 characters").required("Password is required!"),
        name: Yup.string().required("Name is required!"),
        confirmPassword: Yup.string().min(8, "New password must be at least 8 characters")
            .required("Confirm password is required!")
            .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    });

    const studioName = useAppSelector((state)=>state.invite.studioName) || "Studio";
    const inviteToken = useAppSelector((state)=>state.invite.invitation.inviteToken);
    const studioUsername = useAppSelector((state)=>state.invite.studioUsername);

    const handleRegister = async (formValue: { name: string, email: string; password: string, confirmPassword: string }) : Promise<void> => {
        await setRequestLoading(true)
        await dispatch(register(formValue.name, formValue.email, formValue.password, formValue.confirmPassword, navigate, "/invites/" + studioUsername+ "/" + inviteToken));
        await setRequestLoading(false)
    };

    const initialValues = {
        name: "",
        email: "",
        password: "",
        confirmPassword: ""
    };


    return (
        <div className={"alignContentMiddleHorizontal"}>
            <div className={"half-width containerInvites"}>
                <div className={"alignContentMiddleHorizontal"}>
                    <Logo maxSize={"max-h-16"} />
                </div>
                <div className={"alignContentMiddleHorizontal "}>
                    <h1 className={"titleStyle"}>{studioName} invites you to join</h1>
                </div>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleRegister}
                >
                    {({ values, setFieldValue, handleChange, errors, touched }) => (
                        <Form className="grid grid-cols-1 gap-6">
                            <label className="block">
                                <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                                  Name
                                </span>
                                <Input
                                    type="text"
                                    name="name"
                                    value={values.name}
                                    onChange={handleChange}
                                    className="mt-1" />
                                <ErrorMessage name="name" component="div" className="text-red-500 text-xs italic"/>
                            </label>
                            <label className="block">
                                <span className="text-neutral-800 dark:text-neutral-200">
                                  Email address
                                </span>
                                <Input
                                    type="email"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    placeholder="example@example.com"
                                    className="mt-1"
                                />
                                <ErrorMessage name="email" component="div" className="text-red-500 text-xs italic"/>
                            </label>
                            <label className="block">
                                <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                                  Password
                                </span>
                                <Input
                                    type="password"
                                    name="password"
                                    value={values.password}
                                    onChange={handleChange}
                                    className="mt-1" />
                                <ErrorMessage name="password" component="div" className="text-red-500 text-xs italic"/>
                            </label>
                            <label className="block">
                                <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                                  Confirm Password
                                </span>
                                <Input
                                    type="password"
                                    name="confirmPassword"
                                    value={values.confirmPassword}
                                    onChange={handleChange}
                                    className="mt-1" />
                                <ErrorMessage name="confirmPassword" component="div" className="text-red-500 text-xs italic"/>
                            </label>
                            <ButtonPrimary type="submit">Continue</ButtonPrimary>
                        </Form>
                    )}
                </Formik>

                {/* ==== */}
                <span className="block text-center text-neutral-700 dark:text-neutral-300">
                    Already have an account? {` `}
                    <Link to="/invites-login">Sign in</Link>
                </span>
                {requestLoading ? <LoadingSpinner /> : null}
            </div>
        </div>
    );
};

export default InvitesSignUp;
