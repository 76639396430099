import {Studio, StudiosList} from "../models/studio";
import { createSlice,PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    studios:[]
  } as StudiosList

export const studioSlice=createSlice({
    name:'studio',
    initialState,
    reducers:{
        setStudioInvites: (state,action:PayloadAction<Studio[]>) =>{
            state.studios=action.payload;
        },
    }
})
export const { setStudioInvites} = studioSlice.actions
export default studioSlice.reducer;