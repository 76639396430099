import React, { FC } from "react";

export interface LabelProps {
    className?: string;
    text: string;
}

const CustomError: FC<LabelProps> = ({ className = "", text }) => {
    return (
        <div className="text-red-500 text-xs italic">{text}</div>
    );
};

export default CustomError;
