import React, {useEffect} from "react";
import MyRouter from "routers/index";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useAppSelector} from "./hooks/redux-hooks";
import confirmEmail from "./services/confirmEmail";
import { GoogleOAuthProvider } from '@react-oauth/google';
import {emailStatusRoutes, googleLoginKey} from "./contains/contants";
import ButtonPrimary from "./shared/Button/ButtonPrimary";

function App() {
    const emailStatus:string = useAppSelector((state)=>state.auth.user.emailStatus) || "unconfirmed";
    const userId:number = useAppSelector((state)=>state.auth.user.id);
    const authToken:string = useAppSelector((state)=>state.auth.token);
    const currentURL:string[] = window.location.href.split("/");

    useEffect(() => {
        if (emailStatus === "unconfirmed" && emailStatusRoutes.includes("/" + currentURL[currentURL.length - 1])){
            toast.info(<div>
                <span className={"mr-5"}>Please confirm your email address</span>
                <ButtonPrimary onClick={async () => {
                    let req = await confirmEmail.sendEmail(userId, authToken);
                    if (req.response === "success"){
                        toast.success("Email confirmation resent! Please check your email!", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }else {
                        toast.error("An error occurred! Please refresh and try again!", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                }}>Resend Email Confirmation</ButtonPrimary>
            </div>, {
                position: toast.POSITION.TOP_LEFT,
                style: {
                  width: window.innerWidth - 55
                },
                autoClose: false,
            });
        }
    }, [emailStatus])
  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
        <GoogleOAuthProvider clientId={googleLoginKey}>
            <MyRouter />
        </GoogleOAuthProvider>
      <ToastContainer />
    </div>
  );
}

export default App;
