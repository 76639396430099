import {BecomeArtistModel} from "../models/becomeArtist";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
const initialState = {
    username: '',
    bio: '',
    styles: [],
    facebook: '',
    instagram: '',
    stepCompleted: 0
} as BecomeArtistModel;
export const becomeArtistSlice=createSlice({
    name:'becomeArtist',
    initialState,
    reducers:{
        setUsername: (state,action:PayloadAction<string>) =>{
            state.username = action.payload;
        },
        setBio: (state,action:PayloadAction<string>) =>{
            state.bio = action.payload;
        },
        setStyles: (state,action:PayloadAction<string[]>) =>{
            state.styles = [];
            state.styles = action.payload;
        },
        setFacebook: (state,action:PayloadAction<string>) =>{
            state.facebook = action.payload;
        },
        setInstagram: (state,action:PayloadAction<string>) =>{
            state.instagram = action.payload;
        },
        setStepCompleted: (state,action:PayloadAction<number>) =>{
            state.stepCompleted = action.payload
        },
        becomeArtistFlowCleanState: (state,action:PayloadAction<void>) =>{
            state.username = initialState.username;
            state.bio = initialState.bio;
            state.styles = initialState.styles;
            state.facebook = initialState.facebook
            state.instagram = initialState.instagram;
            state.stepCompleted = initialState.stepCompleted;
        },
    }
})
export const {setUsername, setBio, setStyles, setFacebook, setInstagram, setStepCompleted, becomeArtistFlowCleanState} = becomeArtistSlice.actions
export default becomeArtistSlice.reducer;
