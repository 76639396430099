import Label from "components/Label/Label";
import React, {FC, Fragment, useEffect, useState} from "react";
import Avatar from "shared/Avatar/Avatar";
import defaultAvatar from "images/avatars/default-avatar.png";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet";
import {Tab} from "@headlessui/react";
import NcImage from "../../shared/NcImage/NcImage";
import ModalPhotos from "../StudioProfilePage/ModalPhotos";
import RemoveButton from "../../components/Btns/RemoveButton";
import artistProfile from "services/artistProfile";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import {
  artistPortfolio,
  removeArtistPortfolioImage,
  artistAccInfo,
  editArtistAccInfo,
  artistAvatarUpload,
} from '../../store/artist-actions';
import {useAppDispatch ,useAppSelector} from "../../hooks/redux-hooks";
import {api_url, imageSizeLimit, stylesList} from "../../contains/contants";
import {toast} from "react-toastify";
import {ErrorMessage, Form, Formik} from "formik";
import Checkbox from "../../shared/Checkbox/Checkbox";
import * as Yup from "yup";

export interface ArtistPageProps {
  className?: string;
}

const ArtistPage: FC<ArtistPageProps> = ({ className = "" }) => {
  let dispatch = useAppDispatch();
  const user:any = useAppSelector((state)=>state.auth.user);
  const artist:any = useAppSelector((state)=>state.auth.artist);
  const token = useAppSelector((state)=>state.auth.token);

  let [categories] = useState(["Artist", "Portfolio", "Styles"]);

  const [portfolio, setPortfolio] = useState<any[]>(artist.portfolio.concat([]));

  const handleCloseModal = () => setIsOpen(false);
  const [isOpen, setIsOpen] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);
  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };
  const [requestLoading, setRequestLoading] = useState(false);

  const [username, setUsername] = useState(artist.username);
  const [bio, setBio] = useState(artist.bio);

  const removeButton = (imageId:string, setFunction:any, imagesArray:any, isFile: boolean) => {
    return (
        <RemoveButton
            onClick={async() => {

              if (isFile){
                let images = imagesArray.filter(function (obj:any){return obj.id !== imageId});
                setFunction(images)

              }else{
                await setRequestLoading(true)
                try {
                  let req:any = await dispatch(removeArtistPortfolioImage(imageId, token));
                  if (req){
                    await setPortfolio(artist.portfolio.filter(function (obj:any){return obj.id !== imageId}))
                  }

                }catch (e){

                }
                await setRequestLoading(false)
              }

            }}

            className="bg-white absolute z-50 right-3 top-3 shadow-lg rounded-xl w-5 h-5"
            color={isFile ? 'red' : 'black'}
        />
    )
  }
  const uploadAvatar = async (file:any) => {

    await setRequestLoading(true)
    await dispatch(artistAvatarUpload(user.id, file, token));
    await setRequestLoading(false)
  }

  const ArtistTab = () => {
    const [stylesIds, setStylesIds] = useState<string[]>([])
    useEffect(() =>{
      (async () => {
        let req = await artistProfile.fetchStyles(artist.id, token);

        if (req.response === "success"){
          await setStylesIds(req.styles)
        }else{
          toast.error("An error occurred. Please try again", {
            position: toast.POSITION.TOP_CENTER
          });
        }
      })()

    }, [])
    const stylesInitialValues = {
      styles: stylesIds,
    }

    const validationSchema = Yup.object().shape({
      styles: Yup.array().test('check-styles', 'Styles are required', (val:any) => {

        if (val.length === 0){
          return false
        }
        return true;

      })
    });

    useEffect(() => {
      (async () => {
          await setRequestLoading(true)
          let req:any = await dispatch(artistAccInfo(user.id, token));
          if (req){
            await setUsername(req.username)
            await setBio(req.bio)
          }
          await setRequestLoading(false)

      })()

    }, [])

    const handleSubmit = async (formValue: { styles: string[]}) : Promise<void> => {
      await setRequestLoading(true)
      let editStyles = await artistProfile.editStyles(artist.id, formValue.styles, token);

      if (editStyles.response === "success"){
        toast.success("Styles changed successfully", {
          position: toast.POSITION.TOP_CENTER
        });
        await setStylesIds(formValue.styles)

      }else{
        toast.error("Error while trying to delete image", {
          position: toast.POSITION.TOP_CENTER
        });
      }
      await setRequestLoading(false)
    };
    const stylesArray:{value: string, label: string}[] = stylesList.map(function (obj){return {value: obj.id.toString(), label: obj.name}})

    return(
        <div className="space-y-8">
          <Formik
              initialValues={stylesInitialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, handleChange, errors, touched }) => (
                <Form className="grid grid-cols-1 gap-6">
                  <div>
                    <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                      {stylesArray.map(function (obj){
                        return (<Checkbox
                            key={obj.value}
                            label={obj.label}
                            defaultChecked={values.styles.indexOf(obj.value) > -1}
                            name={obj.value}
                            onChange={(checked) => {
                              let array:string[] = values.styles;

                              if (!checked){
                                array = [...array];
                                array.splice(array.indexOf(obj.value), 1)
                              }else{
                                array = [...array, obj.value]
                              }

                              setFieldValue("styles", array)
                            }}
                        />)
                      })}


                    </div>
                    <ErrorMessage name="styles" component="div" className="text-red-500 text-xs italic"/>

                  </div>
                  <ButtonPrimary type={"submit"}> Save Styles </ButtonPrimary>
                </Form>

            )}
          </Formik>

        </div>
    )
  }

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Tattoohub || Book your next tattoo</title>
      </Helmet>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          <div>
            <h2 className="text-3xl font-semibold">Artist Information</h2>
          </div>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

          <div>
            <Tab.Group>
              <Tab.List className="flex space-x-1 overflow-x-auto">
                {categories.map((item) => (
                    <Tab key={item} as={Fragment}>
                      {({ selected }) => (
                          <button
                              className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${
                                  selected
                                      ? "bg-secondary-900 text-secondary-50 "
                                      : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                              } `}
                          >
                            {item}
                          </button>
                      )}
                    </Tab>
                ))}
              </Tab.List>
              <Tab.Panels>
                <Tab.Panel className="mt-8">
                  <div className="space-y-6 sm:space-y-8">
                    {/* HEADING */}
                    <h2 className="text-3xl font-semibold">Account infomation</h2>
                    <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
                    <div className="flex flex-col md:flex-row">
                      <div className="flex-shrink-0 items-start">
                        <div>
                          <div className="relative rounded-full overflow-hidden flex">
                            <Avatar sizeClass="w-32 h-32" imgUrl={artist.avatarUrl ? api_url + '/avatars/' + user.id + '/thumbs/' + artist.avatarUrl : defaultAvatar} />
                            {!artist.avatarUrl ? <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center text-neutral-50 cursor-pointer">
                              <svg
                                  width="30"
                                  height="30"
                                  viewBox="0 0 30 30"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                    d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                                    stroke="currentColor"
                                    strokeWidth={1.5}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                              </svg>

                              <span className="mt-1 text-xs">Change Image</span>
                            </div> : null}
                            <input
                                type="file"
                                accept="image/*"
                                className="absolute inset-0 opacity-0 cursor-pointer"
                                onChange={uploadAvatar}
                            />
                          </div>
                        </div>
                        <div className="text-center mt-2">
                          <ButtonPrimary>
                            Change
                            <input
                                type="file"
                                accept="image/*"
                                className="absolute inset-0 opacity-0 cursor-pointer"
                                onChange={uploadAvatar}
                            />
                          </ButtonPrimary>
                        </div>

                      </div>
                      <div className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-6">
                        {/* <div>
                <Label>Name</Label>
                <Input className="mt-1.5" defaultValue="Eden Tuan" />
              </div> */}
                        {/* ---- */}
                        {/* <div>
                <Label>Gender</Label>
                <Select className="mt-1.5">
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </Select>
              </div> */}
                        {/* ---- */}
                        <div>
                          <Label>Username</Label>
                          <Input className="mt-1.5" value={username} onChange={(val) => {setUsername(val.target.value)}} />
                        </div>
                        {/* ---- */}
                        {/* <div>
                <Label>Email</Label>
                <Input className="mt-1.5" defaultValue="example@email.com" />
              </div> */}
                        {/* ---- */}
                        {/* <div className="max-w-lg">
                <Label>Date of birth</Label>
                <Input
                  className="mt-1.5"
                  type="date"
                  defaultValue="1990-07-22"
                />
              </div> */}
                        {/* ---- */}
                        {/* <div>
                <Label>Addess</Label>
                <Input className="mt-1.5" defaultValue="New york, USA" />
              </div> */}
                        {/* ---- */}
                        {/* <div>
                <Label>Phone number</Label>
                <Input className="mt-1.5" defaultValue="003 888 232" />
              </div> */}
                        {/* ---- */}
                        <div>
                          <Label>Bio</Label>
                          <Textarea className="mt-1.5" value={bio} onChange={(val) => {setBio(val.target.value)}} />
                        </div>
                        <div className="pt-2">
                          <ButtonPrimary onClick={async () => {
                              await setRequestLoading(true);
                              await dispatch(editArtistAccInfo(user.id, bio, username, token));
                              await setRequestLoading(false)
                          }}>Update Artist info</ButtonPrimary>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Panel>
                <Tab.Panel className="mt-8">
                  <div >
                    <div className="mt-5 mb-5">
                      <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-md">
                        <div className="space-y-1 text-center">
                          <svg
                              className="mx-auto h-12 w-12 text-neutral-400"
                              stroke="currentColor"
                              fill="none"
                              viewBox="0 0 48 48"
                              aria-hidden="true"
                          >
                            <path
                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            ></path>
                          </svg>
                          <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                            <label
                                htmlFor="file-upload"
                                className="relative cursor-pointer  rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                            >
                              <span>Upload a file</span>
                              <input
                                  id="file-upload"
                                  name="file-upload"
                                  type="file"
                                  accept="image/*"
                                  className="sr-only"
                                  multiple={true}
                                  onChange={async (file:any) => {
                                    await setRequestLoading(true);
                                    try {
                                      let imagesArray = [];
                                      let notUploaded = 0;

                                      for (let i = 0; i < file.target.files.length; i++){
                                        if (file.target.files[i].size < imageSizeLimit * 1024 * 1024){  //15MB
                                          file.target.files[i].id = file.target.files[i].name
                                          imagesArray.push(file.target.files[i])
                                        }else{
                                          notUploaded++;
                                        }
                                      }

                                      if (notUploaded > 0){
                                        toast.warn("Warning: image size should be less than " + imageSizeLimit + "MB. Not uploaded photos: " + notUploaded, {
                                          position: toast.POSITION.TOP_CENTER
                                        });
                                      }
                                      let images:any = await dispatch(artistPortfolio(user.id, imagesArray, token));
                                      let currentPortfolio = portfolio;
                                      for (const index in images){
                                        currentPortfolio.push(images[index])
                                      }
                                      await setPortfolio(currentPortfolio);

                                      toast.success("Image uploaded successfully", {
                                        position: toast.POSITION.TOP_CENTER
                                      });

                                    }catch (e){
                                      console.log(e)
                                      toast.error("An error occurred. Please refresh and try again", {
                                        position: toast.POSITION.TOP_CENTER
                                      });
                                    }

                                    await setRequestLoading(false)
                                  }}
                              />
                            </label>
                            <p className="pl-1">or drag and drop</p>
                          </div>
                          <p className="text-xs text-neutral-500 dark:text-neutral-400">
                            PNG, JPG, GIF up to 15MB
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                    {portfolio && portfolio.length > 0 ? <div>

                      <header className="container 2xl:px-14 rounded-md sm:rounded-xl">
                        <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
                          <div
                              className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"

                          >
                            <NcImage
                                containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                                className="object-cover w-full h-full rounded-md sm:rounded-xl"
                                src={portfolio[0] instanceof File ? URL.createObjectURL(portfolio[0]) : portfolio[0].path}
                            />
                            <div
                                className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                                onClick={() => handleOpenModal(0)}
                            />

                            <div>
                              {removeButton(portfolio[0].id, setPortfolio, portfolio, portfolio[0] instanceof File)}
                            </div>
                          </div>
                          {portfolio.filter((_:any, i:any) => i >= 1 && i < portfolio.length).map((item:any, index:any) => (
                              <div
                                  key={index}
                                  className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                                      index >= 3 ? "hidden sm:block" : ""
                                  }`}
                              >
                                <NcImage
                                    containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                                    className="object-cover w-full h-full rounded-md sm:rounded-xl "
                                    src={item instanceof File ? URL.createObjectURL(item) : item.path}
                                />

                                {/* OVERLAY */}
                                <div
                                    className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                                    onClick={() => handleOpenModal(index + 1)}
                                />
                                <div>
                                  {removeButton(item.id, setPortfolio, portfolio, item instanceof File)}
                                </div>
                              </div>
                          ))}

                          <div
                              className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
                              onClick={() => handleOpenModal(0)}
                          >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                              <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={1.5}
                                  d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                              />
                            </svg>
                            <span className="ml-2 text-neutral-800 text-sm font-medium">
                Show all photos
              </span>
                          </div>
                        </div>
                      </header>

                      <ModalPhotos
                          imgs={portfolio.map(function (obj:any){
                            if (obj instanceof File){
                              return URL.createObjectURL(obj)
                            }else{
                              return obj.path.replace(api_url, "")
                            }

                          })}
                          isOpen={isOpen}
                          onClose={handleCloseModal}
                          initFocus={openFocusIndex}
                          uniqueClassName="nc-StudioProfilePage-modalPhotos"
                      />
                    </div> : <span>No Images uploaded</span>}
                </Tab.Panel>
                <Tab.Panel className="mt-8">
                  {ArtistTab()}
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
      </CommonLayout>
      {requestLoading ? <LoadingSpinner /> : null}

    </div>
  );
};

export default ArtistPage;
