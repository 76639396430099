import {axiosReq} from "../store";

export async function getCurrentUserStudios(userId: number, token: string) {
    try {
        let req = await axiosReq.get('/api/fetch-current-user-studios?userId=' + userId,{
            params: {token: token},
        });

        return {response: req.data.response, studios: req.data.studios};

    }catch (e){
        return {response: "error"};
    }
}

  export function showStudioTab() {
    const studiosStr = localStorage.getItem("studios");
    if (studiosStr) return true;
    return false;
  }

  export async function getStudioInvites(token:string, userId: number) {
    try {
        let req = await axiosReq.post('/api/get-studio-invites',{
          userId: userId,
        }, {params: {token: token}});

        if(req.data && req.data.studioInvites ){
             localStorage.setItem("studioInvites", JSON.stringify(req.data.studioInvites));
            return req.data.studioInvites;
        }
    }catch (e){
        console.log(e)
        return [];
    }

    return [];
}

export async function acceptOrRejectStudioInvite(inviteId: number, accept: boolean, reject: boolean, token:string) {
  try {
      let req = await axiosReq.post('/api/accept-or-reject-invite',{
        inviteId: inviteId,
        accept: accept,
        reject: reject
        }, {params: {token: token}});
        if(req.data && req.data.response === 'success'){
          return true;
        }

  }catch (e){
      console.log(e)
      return false;
  }

  return true;
}
