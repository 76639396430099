import React, {useEffect} from "react";
import { useHistory } from "react-router-dom";
import CommonLayout from "../PageAddListing1/CommonLayout";
import Input from "../../shared/Input/Input";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import {crateStudioStepsLength, domain} from "../../contains/contants";
import Navigation from "../../components/Listing/NavBtn";
import { useAppDispatch,useAppSelector } from "../../hooks/redux-hooks";
import {setStudioName, setStepCompleted, setUsername} from "../../store/create-studio-slice";
import {checkIfUsernameExist} from "../../services/createStudio";
import {toast} from "react-toastify";

const CreateStudioListing1 = () => {
    const navigate = useHistory();
    const dispatch = useAppDispatch();
    const currentStep = 2;
    const stepCompleted = useAppSelector((state)=>state.createStudio.stepCompleted);
    const token = useAppSelector((state)=>state.auth.token);

    useEffect(() => {
        if (stepCompleted < currentStep - 1){
            navigate.push('/dashboard')
        }
    }, []);


    const initialValues = {
        studioName: useAppSelector((state)=>state.createStudio.studioName),
        username: useAppSelector((state)=>state.createStudio.username)
    }

    const validationSchema = Yup.object().shape({
        studioName: Yup.string().min(3, "Name must be at least 3 characters").required("Name is required!"),
        username: Yup.string().min(3, "Username must be at least 3 characters").required("Username is required!").test('isExist', '', async (val:any, testContext:any) => {

            if  (val && val.length > 2){
                let req:any = await checkIfUsernameExist(val, token);

                if (req.response === 'success'){
                    if (req.isExist){
                        return testContext.createError({message: 'Username is already in use'})
                    }else{
                        return true
                    }
                }else{
                    toast.error("Error. Please refresh and try again", {
                        position: toast.POSITION.TOP_CENTER
                    });
                    return false
                }
            }

        }),
    });

    const handleLogin = async (formValue: { studioName: string; username: string;}) : Promise<void> => {

        await dispatch(setStudioName(formValue.studioName))
        await dispatch(setUsername(formValue.username))

        if (stepCompleted < currentStep){
            await dispatch(setStepCompleted(currentStep))
        }

        navigate.push("/create-studio-listing-2")

    };
    return (
        <CommonLayout
            index={"0" + currentStep}
            steps={'0' + crateStudioStepsLength}
        >
            <>
                <h2 className="text-2xl font-semibold">Studio Information</h2>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

                {/* FORM */}
                <div className="space-y-8">
                    <Formik
                        validateOnChange={false}
                        validateOnBlur={false}
                        validateOnMount={false}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleLogin}
                    >
                        {({ values, setFieldValue, handleChange, errors, touched }) => (
                            <Form className="grid grid-cols-1 gap-6">
                                <label className="block">
                                    <span className="text-neutral-800 dark:text-neutral-200">Name</span>
                                    <Input
                                        type="text"
                                        name="studioName"
                                        value={values.studioName}
                                        onChange={(value) => {
                                            if (!values.username.replace(domain, '') || values.studioName.replace(/[^a-zA-Z0-9.]/g, "") === values.username.replace(domain, '')){
                                                let username = value.target.value.toString().replace(/[^a-zA-Z0-9.]/g, "");
                                                setFieldValue('username', username)
                                            }
                                            handleChange(value)
                                        }}
                                        placeholder="Name"
                                        className="mt-1"
                                    />
                                    <ErrorMessage name="studioName" component="div" className="text-red-500 text-xs italic"/>
                                </label>
                                <label className="block">
                                    <span className="text-neutral-800 dark:text-neutral-200">Username</span>
                                    <Input
                                        type="text"
                                        name="username"
                                        value={values.username + domain}
                                        onChange={(value) => {
                                            let username = value.target.value.replace(/[^0-9a-zA-Z.]/g, '');

                                            if (username.indexOf(domain) === -1 || username.indexOf(domain) + domain.length < username.length){
                                                username = values.username
                                            }

                                            value.target.selectionStart = username.slice(0, username.indexOf(domain)).length
                                            value.target.selectionEnd = username.slice(0, username.indexOf(domain)).length;

                                            setFieldValue('username', username.slice(0, username.indexOf(domain)));

                                        }}
                                        placeholder="Username"
                                        className="mt-1"
                                    />
                                    <ErrorMessage name="username" component="div" className="text-red-500 text-xs italic"/>
                                </label>
                                <Navigation prev={"/create-studio-select-plan"} />

                            </Form>

                        )}
                    </Formik>

                </div>
            </>
        </CommonLayout>
    );
};

export default CreateStudioListing1;
