import {setArtistAvatar, setArtistPortfolio, setArtistBio, setArtistUsername} from './auth-slice'
import { AnyAction } from '@reduxjs/toolkit'
import { ThunkAction } from '@reduxjs/toolkit'
import {RootState} from './index'
import artistProfile from "../services/artistProfile";
import {toast} from "react-toastify";
export const artistAvatarUpload=(userId: string, file: any, token: string):ThunkAction<void, RootState,unknown,AnyAction>=>{
    return async(dispatch,getState)=>{
        const req: { response: string, avatarUrl: string } = await artistProfile.uploadAvatarImage(userId, file, token);
        if (req.response === "success"){
            await dispatch(setArtistAvatar(req.avatarUrl));

            toast.success("Avatar uploaded successfully", {
                position: toast.POSITION.TOP_CENTER
            });
        }else{
            toast.error("An error occurred. Please try again", {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }
}
export const artistPortfolio=(userId: string, file: any[], token: string):ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async(dispatch,getState)=>{
        const response: any[] = await artistProfile.uploadPortfolio(userId, file, token);
        if (response){
            let artistPortfolio = [...getState().auth.artist.portfolio];
            for (const index in response){
                artistPortfolio.push(response[index])
            }
            await dispatch(setArtistPortfolio(artistPortfolio));

            return response;
        }
    }
}
export const artistAccInfo = (userId: number, token: string):ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async(dispatch,getState)=>{
        try {
            const response: {avatarUrl: string, bio: string, username: string} = await artistProfile.getArtistAccInfo(userId, token);

            if (response){
                await dispatch(setArtistAvatar(response.avatarUrl));
                await dispatch(setArtistUsername(response.username));
                await dispatch(setArtistBio(response.bio));

                return response;
            }else{
                toast.error("An error occurred. Please refresh and try again", {
                    position: toast.POSITION.TOP_RIGHT
                });
                return  false
            }

        }catch (e){
            toast.error("An error occurred. Please refresh and try again", {
                position: toast.POSITION.TOP_RIGHT
            });
            console.log(e);
            return false
        }

    }
}
export const editArtistAccInfo = (userId: number, bio: string, username: string, token: string):ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async(dispatch,getState)=>{
        try {
            const response: {res: string} = await artistProfile.editAccountArtistInfo(userId, bio, username, token);

            if (response.res === "success"){
                await dispatch(setArtistUsername(username));
                await dispatch(setArtistBio(bio));
                toast.success("Artist account info edited successfully", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }else if(response.res === "usernameAlreadyInUse"){
                toast.warning("Username already in use. Please try again", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }else{
                toast.error("An error occurred. Please refresh and try again", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            return true;

        }catch (e){
            console.log(e);
            return false
        }

    }
}
export const removeArtistPortfolioImage=(imageId: string, token: string):ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async(dispatch,getState)=>{
        try {
            let req = await artistProfile.removeImage(imageId, token);

            if (req){
                let artistPortfolio:string[] = getState().auth.artist.portfolio;
                artistPortfolio = artistPortfolio.filter(function (obj:any){
                    return obj.id !== imageId
                })
                await dispatch(setArtistPortfolio(artistPortfolio));
            }
            return true;

        }catch (e){
            return false
        }

    }
}
