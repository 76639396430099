import React, { useState, useEffect } from "react";
import contests from "../../services/contests";
import {Link, useHistory} from "react-router-dom";
import Countdown from "react-countdown";
import {useAppSelector} from "../../hooks/redux-hooks";
import confirmEmail from "../../services/confirmEmail";
import {toast} from "react-toastify";
import {api_url, imageSizeLimit} from "../../contains/contants";
import NcImage from "../../shared/NcImage/NcImage";
import ModalPhotos from "../StudioProfilePage/ModalPhotos";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";


const StatusUpcoming = (props: {contest: any}) => {
    const userId:any = useAppSelector((state)=>state.auth.user.id);
    const artist:any = useAppSelector((state)=>state.auth.artist);
    const authToken:string = useAppSelector((state)=>state.auth.token);
    let artistId: number | string = "";
    if (artist) artistId = artist.id;
    const emailStatus:string = useAppSelector((state)=>state.auth.user.emailStatus);

    let nav = useHistory();
    const [myImage, setMyImage] = useState<any>({});
    const [requiredLoading, setRequestLoading] = useState(false)

    useEffect(() => {
        (async () => {
            await setRequestLoading(true);
            let req:any = await contests.getDataUpcomingContest(props.contest.id, artistId, nav);
            await setMyImage(req.image);
            await setRequestLoading(false);
        })()
    }, [])

    let boxStyle:any = {
        textAlign: "center",
        margin: "20px",
        minWidth: "140px",
        padding: "20px 15px",
        color: "white",
        borderRadius: "10px",
        fontSize: "22px",
    }

    const [isOpen, setIsOpen] = useState(false);
    const [openFocusIndex, setOpenFocusIndex] = useState(0);

    const handleOpenModal = (index: number) => {
        setIsOpen(true);
        setOpenFocusIndex(index);
    };

    const handleCloseModal = () => setIsOpen(false);

    const uploadSection = () => {
        if (userId && emailStatus === "confirmed" && artistId){
            if (!myImage){
                return ( <div >
                    <div className="mt-5 mb-5">
                        <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-md">
                            <div className="space-y-1 text-center">
                                <svg
                                    className="mx-auto h-12 w-12 text-neutral-400"
                                    stroke="currentColor"
                                    fill="none"
                                    viewBox="0 0 48 48"
                                    aria-hidden="true"
                                >
                                    <path
                                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    ></path>
                                </svg>
                                <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                                    <label
                                        htmlFor="file-upload"
                                        className="relative cursor-pointer  rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                                    >
                                        <span>Upload your tattoo</span>
                                        <input
                                            id="file-upload"
                                            name="file-upload"
                                            type="file"
                                            accept="image/*"
                                            className="sr-only"
                                            onChange={async (file:any) => {
                                                if (file.target.files[0].size < imageSizeLimit * 1024 * 1024) {
                                                    file.target.files[0].id = file.target.files[0].name;

                                                    await setRequestLoading(true)
                                                    let req:any = await contests.uploadContestImage(props.contest.id, artistId, [file.target.files[0]], authToken)

                                                    if(req.response === "success"){
                                                        await setMyImage(req.image)
                                                    }
                                                    await setRequestLoading(false)
                                                }else{
                                                    toast.warn("Warning: image size should be less than " + imageSizeLimit + "MB.", {
                                                        position: toast.POSITION.TOP_CENTER
                                                    })
                                                }
                                            }}
                                        />
                                    </label>
                                    <p className="pl-1">or drag and drop</p>
                                </div>
                                <p className="text-xs text-neutral-500 dark:text-neutral-400">
                                    PNG, JPG, GIF up to 15MB
                                </p>
                            </div>
                        </div>
                    </div>
                </div>)
            }else{
                let bg = "bg-primary-500";
                if (myImage.status === "approved") bg = "bg-green-500";
                return (
                    <div>
                        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold mb-3">Uploaded tattoo</h2>
                        <div className={"flex"}>
                            <>
                                <header className="container 2xl:px-14 rounded-md sm:rounded-xl">
                                    <div className="relative grid grid-cols-1 h-200 gap-1 sm:gap-2">
                                        <div
                                            className="col-span-2 row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
                                            onClick={() => handleOpenModal(0)}
                                        >
                                            <NcImage
                                                style={{
                                                    width: "100%",
                                                    height: "300px"
                                                }}
                                                className="object-cover rounded-md sm:rounded-xl "
                                                src={api_url + "/contestTattoos/" + props.contest.id + "/" + myImage.imageUrl}
                                            />
                                            <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
                                        </div>

                                    </div>
                                </header>
                                {/* MODAL PHOTOS */}
                                <ModalPhotos
                                    imgs={["/contestTattoos/" + props.contest.id + "/" + myImage.imageUrl]}
                                    isOpen={isOpen}
                                    onClose={handleCloseModal}
                                    initFocus={openFocusIndex}
                                    uniqueClassName="nc-ListingCarDetailPage__modalPhotos"
                                />
                            </>
                            <div className={"ml-10 mr-10 flex flex-col justify-center"}>
                                <div style={{borderRadius: "10px"}} className={bg +  " mb-6 p-5 text-white text-center"}>
                                    {myImage.status}
                                </div>
                                <div style={{borderRadius: "10px"}} onClick={async () => {
                                    await setRequestLoading(true);
                                    let req = await contests.deleteContestImage(myImage.id, authToken);

                                    if(req.response === "success"){
                                        await setMyImage(null)
                                    }
                                    await setRequestLoading(false);
                                }} className="bg-red-500 justify-center p-5 text-white flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
                                    <i className="las la-trash text-2xl"></i>
                                </div>
                            </div>
                        </div>


                    </div>
                )
            }

        }else if(!userId){
            return (
                <Link to={"/login"}><h2 className={"text-2xl text-center mt-10"}><span className={"text-primary-6000 hover:cursor-pointer hover:text-primary-500"}>Login in</span> to participate in the contest</h2></Link>
            )
        }else if(!artistId){
            return (
                <Link to={"/become-artist-listing-1"}><h2 className={"text-2xl text-center mt-10"}><span className={"text-primary-6000 hover:cursor-pointer hover:text-primary-500"}>Creat artist profile</span> to participate in the contest</h2></Link>
            )
        }else if(!emailStatus || emailStatus === "unconfirmed"){
            return (<h2 onClick={async () => {
                let req = await confirmEmail.sendEmail(userId, authToken);
                if (req.response === "success"){
                    toast.success("Confirmation link sent successfully. Please check your email!", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }else {
                    toast.error("An error occurred! Please refresh and try again!", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            }} className={"text-2xl text-center mt-10"}><span className={"text-primary-6000 hover:cursor-pointer hover:text-primary-500"}>Confirm your email</span> to participate in the contest</h2>)
        }
    }

    return (
        <main className="container relative z-10 mt-11 flex flex-col lg:flex-row ">
            {requiredLoading ? <LoadingSpinner /> : null}
            {/* CONTENT */}
            {props.contest ?  <div className="w-full space-y-8 lg:space-y-10">
                <h2 className="text-base md:text-lg text-neutral-500 dark:text-neutral-400 text-center">Get Ready! Tattoo Contest Starts In:</h2>
                <Countdown date={props.contest.startDate}     renderer={props => <div style={{flexWrap: "wrap", display: "flex", justifyContent: "center"}}>
                    <div className={"bg-primary-6000"} style={boxStyle}>
                        <div>{props.days}</div>
                        <div>days</div>
                    </div>
                    <div className={"bg-primary-6000"} style={boxStyle}>
                        <div>{props.hours}</div>
                        <div>hours</div>
                    </div>
                    <div className={"bg-primary-6000"} style={boxStyle}>
                        <div>{props.minutes}</div>
                        <div>minutes</div>
                    </div>
                    <div className={"bg-primary-6000"} style={boxStyle}>
                        <div>{props.seconds}</div>
                        <div>seconds</div>
                    </div>
                </div>}/>
                {uploadSection()}
            </div> : null}

        </main>
    );
};

export default StatusUpcoming;
