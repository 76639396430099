import React, {FC, useEffect, useMemo} from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import { TaxonomyType } from "data/types";
import NextPrev from "shared/NextPrev/NextPrev";
import useNcId from "hooks/useNcId";
import NcImage from "../../shared/NcImage/NcImage";
import {stylesList} from "../../contains/contants";

export interface SectionSliderNewCategoriesProps {
    selectedIndex: any;
    onClickFunction: any;
    className?: string;
    itemClassName?: string;
    heading?: string;
    subHeading?: string;
    categories?: TaxonomyType[];
    categoryCardType?: "card3" | "card4" | "card5";
    itemPerRow?: 4 | 5;
    sliderStyle?: "style1" | "style2";
    uniqueClassName: string;
}



const StylesSlider: FC<SectionSliderNewCategoriesProps> = ({
     selectedIndex = [],
     onClickFunction = () => {},
     heading = "Tattoos of the week",
     subHeading = "Descriptions for sections",
     className = "",
     itemClassName = "",
     categories = stylesList,
     itemPerRow = 5,
     sliderStyle = "style1",
     uniqueClassName,
}) => {
    const UNIQUE_CLASS =
        "SectionSliderNewCategories__" + uniqueClassName + useNcId();

    let MY_GLIDEJS = useMemo(() => {
        return new Glide(`.${UNIQUE_CLASS}`, {
            perView: itemPerRow,
            gap: 32,
            bound: true,
            breakpoints: {
                1280: {
                    perView: itemPerRow - 1,
                },
                1024: {
                    gap: 20,
                    perView: itemPerRow - 1,
                },
                768: {
                    gap: 20,
                    perView: itemPerRow - 2,
                },
                640: {
                    gap: 20,
                    perView: itemPerRow - 3,
                },
                500: {
                    gap: 20,
                    perView: 1.3,
                },
            },
        });
    }, [UNIQUE_CLASS]);

    useEffect(() => {
        setTimeout(() => {
            MY_GLIDEJS.mount();
        }, 100);
    }, [MY_GLIDEJS, UNIQUE_CLASS]);

    // const [selectedIndex, setSelectedIndex] = useState<any>(['0'])

    const renderCard = (item: TaxonomyType, index: number) => {
        const styles = {
            "alignItems": "center",
            "justifyContent": "center",
            "display": "flex",
            "backgroundColor": "rgba(0,0,0, 0.5)",
        }
       return(
           <div>
               <div
                   className={`flex-shrink-0 relative w-full aspect-w-4 aspect-h-3 h-0 rounded-2xl overflow-hidden group`}
               >
                   <NcImage
                       src={item.thumbnail}
                       className="object-cover w-full h-full rounded-2xl"
                   />
                   <span className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity"></span>
                   {selectedIndex.indexOf(item.id) > -1 ? <div style={styles}>
                       <svg style={{
                           filter: "invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(119%)"
                       }}  width="40" height="40" viewBox="0 0 24 24"><path d="M5.48 10.089l1.583-1.464c1.854.896 3.028 1.578 5.11 3.063 3.916-4.442 6.503-6.696 11.311-9.688l.516 1.186c-3.965 3.46-6.87 7.314-11.051 14.814-2.579-3.038-4.301-4.974-7.469-7.911zm14.407.557c.067.443.113.893.113 1.354 0 4.962-4.038 9-9 9s-9-4.038-9-9 4.038-9 9-9c1.971 0 3.79.644 5.274 1.723.521-.446 1.052-.881 1.6-1.303-1.884-1.511-4.271-2.42-6.874-2.42-6.075 0-11 4.925-11 11s4.925 11 11 11 11-4.925 11-11c0-1.179-.19-2.313-.534-3.378-.528.633-1.052 1.305-1.579 2.024z"/></svg>
                   </div> : null}

               </div>
               <div className="mt-4 px-3 truncate">
                   <h2
                       className={`text-base sm:text-lg text-neutral-900 dark:text-neutral-100 font-medium truncate`}
                   >
                       {item.name}
                   </h2>
               </div>
           </div>
       )
    };

    return (
        <div className={`nc-SectionSliderNewCategories ${className}`}>
            <div className={`${UNIQUE_CLASS} flow-root`}>
                <Heading
                    desc={subHeading}
                    hasNextPrev={sliderStyle === "style1"}
                    isCenter={sliderStyle === "style2"}
                >
                    {heading}
                </Heading>
                <div className="glide__track" data-glide-el="track">
                    <ul className="glide__slides">
                        {categories.map((item, index) => (
                            <li key={index} onClick={() => {
                                let array = selectedIndex;

                                if (array.indexOf(item.id) > -1){
                                    array.splice(array.indexOf(item.id), 1);
                                }else{
                                    array.push(item.id)
                                }

                                if (array.indexOf('0') > -1){
                                    array.splice(array.indexOf('0'), 1);
                                }

                                // if (item.id === '0'){
                                //     array = []
                                // }else{

                                //     if (array.indexOf(item.id) > -1){
                                //         array.splice(array.indexOf(item.id), 1);
                                //     }else{
                                //         array.push(item.id)
                                //     }

                                //     if (array.indexOf('0') > -1){
                                //         array.splice(array.indexOf('0'), 1);
                                //     }
                                // }

                                //if (array.length === 0) array = ['0']

                                //setSelectedIndex(array)
                                onClickFunction(array)


                            }} className={`glide__slide ${itemClassName}`}>
                                {renderCard(item, index)}
                            </li>
                        ))}
                    </ul>
                </div>

                {sliderStyle === "style2" && (
                    <NextPrev className="justify-center mt-16" />
                )}
            </div>
        </div>
    );
};

export default StylesSlider;
