import React, { FC, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import OtpInput from 'react-otp-input';
import {useHistory, useParams} from "react-router-dom";
import {cancelBooking, generateCancelCode} from "../../services/bookingPage";
import {toast} from "react-toastify";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";

export interface PayPageProps {
    className?: string;
}

const CancelBooking: FC<PayPageProps> = ({ className = "" }) => {
    const [otp, setOtp] = useState('');
    const [requestLoading, setRequestLoading] = useState(false)

    const [{ placeholder, inputType }] = React.useState({
        numInputs: 4,
        separator: '-',
        minLength: 0,
        maxLength: 40,
        placeholder: '',
        inputType: 'text' as const,
    });

    const navigate = useHistory();

    const params:any = useParams();
    const guid:string = params.guidString;

    return (
        <div className={`nc-PayPage ${className}`} data-nc-id="PayPage">
            {requestLoading ? <LoadingSpinner /> : null}
            <main className="container mt-11 mb-24 lg:mb-32 ">
                <div className="max-w-4xl mx-auto">
                    <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">

                        {/* ------------------------ */}
                        <div className="space-y-6">
                            <h3 className="text-2xl font-semibold">Enter cancel code</h3>
                            <div className="flex flex-col sm:flex-row sm:items-center">
                                <OtpInput
                                    inputStyle="bookingOtpInputStyle"
                                    onChange={setOtp}
                                    value={otp}
                                    placeholder={placeholder}
                                    numInputs={6}
                                    renderSeparator={<span>-</span>}
                                    renderInput={(props) => <input {...props} />}
                                    inputType={inputType}
                                    shouldAutoFocus
                                />
                            </div>
                        </div>

                        <div>
                            <ButtonSecondary onClick={async () => {
                                let req:any = await generateCancelCode(guid);

                                if (req.response === "success"){
                                    toast.success("Cancel code sent successfully", {
                                        position: toast.POSITION.TOP_CENTER
                                    });
                                }else{
                                    toast.error("An error occurred. Please refresh and try again", {
                                        position: toast.POSITION.TOP_CENTER
                                    });
                                }
                            }}>Resend</ButtonSecondary>
                            <ButtonPrimary onClick={async () => {
                                await setRequestLoading(true);
                                let req:any = await cancelBooking(guid, otp);

                                if (req.response === "success"){
                                    toast.success("Booking cancelled successfully", {
                                        position: toast.POSITION.TOP_CENTER
                                    });
                                    navigate.push("/booking/" + guid + "/details")
                                }else if(req.response === "wrongCode"){
                                    toast.error("Your code is wrong. Please try again.", {
                                        position: toast.POSITION.TOP_CENTER
                                    });
                                }else{
                                    toast.error("An error occurred. Please refresh and try again", {
                                        position: toast.POSITION.TOP_CENTER
                                    });
                                }

                                await setRequestLoading(false)


                            }}>Submit</ButtonPrimary>
                        </div>
                    </div>
                </div>
            </main>
        </div>

    );
};

export default CancelBooking;
