import Label from "components/Label/Label";
import React, {FC, useEffect, useState} from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useAppDispatch,useAppSelector } from "../../hooks/redux-hooks";
import {updateUser} from '../../store/auth-actions';
import LoadingSpinner from "../../components/Loading/LoadingSpinner";

export interface AccountPageProps {
  className?: string;
}

const AccountPage: FC<AccountPageProps> = ({ className = "" }) => {
  const navigate = useHistory();
  const dispatch=useAppDispatch();
  const currentUser = useAppSelector((state)=>state.auth.user);
  const isLoggedIn = useAppSelector((state)=>state.auth.isLoggedIn);
  const [requestLoading, setRequestLoading] = useState(false)
  useEffect(() => {
    if(isLoggedIn === false){
      navigate.push("/login")
    }
  }, [currentUser])

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required!"),
    email: Yup.string().email().required("Email address is required!")
  });

  const handleUpdateAccountInfo = async (formValue: { name: string; email: string}) : Promise<void> => {
    await setRequestLoading(true);
    await dispatch(updateUser(formValue.name, formValue.email, currentUser.id));
    await setRequestLoading(false);
  };

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Account || Tattoohub</title>
      </Helmet>
      <CommonLayout>
        {requestLoading ? <LoadingSpinner /> : null}
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Account infomation</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex flex-col md:flex-row">
            <div className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-6">
            <Formik
            initialValues={ {name: currentUser.fullName, email: currentUser.emailAddress}}
            validationSchema={validationSchema}
            onSubmit={(values, {resetForm, }) => {
              handleUpdateAccountInfo(values);
              // resetForm();
            }}
          >
          {({ values, setFieldValue, handleChange, errors, touched }) => (
              <Form className="grid grid-cols-1 gap-6">
              <div>
                <Label>Name</Label>
                <Input
                className="mt-1.5"
                 name="name"
                 value={values.name}
                 onChange={handleChange}/>
                  <ErrorMessage name="name" component="div" className="text-red-500 text-xs italic"/>
              </div>
              <div>
                <Label>Email</Label>
                <Input className="mt-1.5"
                 name="email"
                 value={values.email}
                 onChange={handleChange}/>
                  <ErrorMessage name="email" component="div" className="text-red-500 text-xs italic"/>
              </div>
              <div className="pt-2">
                <ButtonPrimary type="submit">Update info</ButtonPrimary>
              </div>
              </Form>
              )}
              </Formik>
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPage;
