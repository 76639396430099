import {axiosReq} from "../store";

export default{
    async getStudioInfo(username: string, token: string, userId: number | undefined) {
        try {
            let req = await axiosReq.get('/api/get-invite-studio-info?username=' + username + "&token=" + token + "&userId=" + userId);

            let response:any = {response: req.data.response};

            if (req.data.response === "success"){
                response.studioName = req.data.businessInfo.name;
                response.studioUsername = req.data.businessInfo.username;
                response.invitation = req.data.invitation;
            }

            return response;

        }catch (e){
            return {response: "error"};
        }
    },
    async acceptInvitationRequest(userId: number, token: string, authToken: string) {
        try {
            let req = await axiosReq.post('/api/accept-invitation',{
                userId: userId,
                token: token
            }, {params: {token: authToken}});

            return {response: req.data.response, redirectToArtistFlow: req.data.redirectToArtistFlow};

        }catch (e){
            return {response: "error"};
        }
    },
}
