import {toast} from "react-toastify";
import {axiosReq} from "../store";

export default{

    async uploadAvatarImage(userId:string, file:any, token: string) {

        let formData = new FormData();
        formData.append('userId', userId);
        formData.append('image', file.target.files[0]);

        try {
            let req:any = await axiosReq.post('/api/upload-artist-avatar', formData, {params: {token: token}});

            return {response: req.data.response, avatarUrl: req.data.avatarUrl}

        }catch (e){
            return {response: "error", avatarUrl: ""}
        }

    },

    async getArtistAccInfo(userId:number, token: string) {


        try {
            let req = await axiosReq.get('/api/get-artist-account-info?userId=' + userId, {params: {token: token}});


            if (req.data.response === 'success'){
                return req.data.artist;
            }else{
                return false
            }

        }catch (e){
            console.log(e)
            return false
        }

    },

    async editAccountArtistInfo(userId:number, bio: string, username: string, token: string) {


        try {
            let req = await axiosReq.post('/api/edit-artist-account-info', {
                userId: userId,
                bio: bio,
                username: username
            }, {params: {token: token}});


            return {res: req.data.response};

        }catch (e){
            return {res: "error"}
        }

    },

    async uploadPortfolio(userId:string, imagesArray:any, token: string) {
        let tattoos:string[] = [];
        try {

            let formData = new FormData();
            formData.append('userId', userId);
            for (let i = 0; i < imagesArray.length; i++){
                formData.append('images', imagesArray[i])
            }

            let req = await axiosReq.post('/api/upload-portfolio-images',formData, {params: {token: token}})
            if(req.data.response === 'success'){
                tattoos = req.data.tattoos
                return tattoos;
            }
        }catch (e){
            return tattoos;
        }

        return tattoos;
    },

    async removeImage(imageId:string, token:string) {
        try {

            let req = await axiosReq.post('/api/delete-portfolio-image', {
                imageId: imageId
            }, {params: {token: token}});

            if(req.data.response === 'success'){
                toast.success("Image deleted successfully", {
                    position: toast.POSITION.TOP_CENTER
                });
                return true;
            }
        }catch (e){
            toast.error("Error while trying to delete image", {
                position: toast.POSITION.TOP_CENTER
            });
            return false;
        }

        return false;
    },

    async fetchStyles(artistId: number, token: string){
        try {
            let req = await axiosReq.get('/api/fetch-artist-styles?artistId=' + artistId,
                {params: {token: token}}
            );


            return {response: req.data.response, styles: req.data.stylesIds};

        }catch (e){
            console.log(e)
            return {response: "error"};
        }
    },
    async editStyles(artistId: number, styles: string[], token: string){
        try {
            let req = await axiosReq.post('/api/edit-artist-styles',{
                artistId: artistId,
                styles: styles
            }, {params: {token: token}});


            return {response: req.data.response};

        }catch (e){
            return {response: "error"};
        }
    }



}
