import {setStudioInvites} from './studio-slice'
import { AnyAction } from '@reduxjs/toolkit'
import { ThunkAction } from '@reduxjs/toolkit'
import {RootState} from './index'
import {Studio} from "../models/studio";
import {getStudioInvites} from "../services/studioServoce";

//export const authActions=authSlice.actions

export const fetchStudioInvites=():ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async(dispatch,getState)=>{ 
            let token = getState().auth.token; 
            let userId = getState().auth.user.id; 
            const response:Studio[]=await getStudioInvites(token, userId);
            dispatch(setStudioInvites(response))
    }
}