import React, { useEffect } from "react";
import CommonLayout from "./CommonLayout";
import StudiosInvitesCard from "../../components/Studios/StudiosInvitesCard";
import { useHistory  } from "react-router-dom";
import { useAppDispatch,useAppSelector } from "../../hooks/redux-hooks";
import {fetchStudioInvites} from '../../store/studio-actions';

const ArtistInvites = () => {
  const studios=useAppSelector((state)=>state.studio.studios);
  const isLoggedIn = useAppSelector((state)=>state.auth.isLoggedIn);
  const dispatch=useAppDispatch();
  const navigate = useHistory();

  useEffect(() => {
    dispatch(fetchStudioInvites());

    if(isLoggedIn === false){
      navigate.push("/login")
    }

    // const fetchData = async () => {
    //   let studioInvites = await getStudioInvites();
    //   setStudios(studioInvites);
    //   return studioInvites;
    // }

    //fetchData();
   }, [])

  const renderSection1 = () => {
    return (
      <div className="space-y-6 sm:space-y-8">
        <div>
          <h2 className="text-3xl font-semibold">Studio Invites</h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div>
        <StudiosInvitesCard studios={studios} />
        {studios.length === 0 &&
        <p>
          You have {studios.length} pending invites.
        </p>
        }
        </div>
      </div>
    );
  };

  return (
    <div>
      <CommonLayout>{renderSection1()}</CommonLayout>
    </div>
  );
};

export default ArtistInvites;
